import { useTheme } from '@emotion/react';
import { useLocation } from '@reach/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CookiesConsent from '@/components/cookie-consent';
import ModalAddMailing from '@/components/custom-modals/modal-add-mailling';
import AddMailingButtonModal from '@/components/custom-modals/modal-add-mailing-list';
import ModalCheckout from '@/components/custom-modals/modal-checkout';
import ModalCreditBalance from '@/components/custom-modals/modal-credit-balance';
import WarningMessageNodataModal from '@/components/custom-modals/modal-message-nodata';
import WarningThresholdLimitModal from '@/components/custom-modals/modal-onboarding/modal-warning-threshold-limit';
import ModalSmartTrigger from '@/components/custom-modals/modal-smart-trigger';
import ModalWebsite from '@/components/custom-modals/modal-website';
import SideBarMenu from '@/components/home/sidebar-menu';
import UserInfo from '@/components/home/user-info';
import { LoadingPage } from '@/components/loading/loadingBase';
import { RootState } from '@/reducers/model';
import { GlobalModalCredits, PaymentModalType } from '@/utils/enum';
import RoutePaths from '@/utils/routes';
import Footer from '../Footer/FooterView';
import Header from '../Header';
import Main from '../Main';
import ModalPurchasePremium from '@/components/custom-modals/modal-purchase-premium';
import { ModalPreviewLandingPage } from '@/components/custom-modals/modal-preview-landing-page';
import RedirectPastOrderModal from '@/components/custom-modals/modal-redirect-past-order';
import ModalCreateLandingPage from '@/components/custom-modals/modal-create-landing-page';
import useCloseModalOnPopstate from '@/hooks/useCloseModalOnPopstate';
import ModalSubscriptionPackage from '@/components/custom-modals/modal-subscription-package';
import HomeLimitModal from '@/components/custom-modals/modal-home-limit';
import CreditsModal from '@/components/custom-modals/modal-credit';
import usePurchaseTemplate from '@/hooks/usePurchaseTemplate';
import AppAction from '@/reducers/app/action';
import ConfirmModal from '@/components/custom-modals/modal-confirm';
import { navigate } from 'gatsby';
import { SettingTabsKey, SmartActionTabsKey } from '@/models';
import ModalConfigureBrokerSubscription from '@/components/custom-modals/modal-configure-broker-subscription';
import HorizontalLayoutViewStyled from './HorizontalLayoutViewStyled';
import AppButtonSwitch from '@/components/AppButtonSwitch';
import useGetListCard from '@/hooks/useGetListCard';
interface LayoutProps {
  isNonFooter?: boolean;
  isNonHeader?: boolean;
  className?: string;
  isHomePage?: boolean;
  staticLayout?: boolean;
  hideSwitchBtn?: boolean;
  removeFixedLayout?: boolean;
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  isNonFooter = false,
  isNonHeader = false,
  className = '',
  isHomePage = false,
  hideSwitchBtn = false,
  removeFixedLayout = false,
}: LayoutProps) => {
  // Stripe cards
  useGetListCard(true);

  // Purchase Template
  const {
    handleSubmit,
    buyManualCredit,
    errorPurchaseTemplate,
    minHome,
    userPaymentCard,
    setErrorPurchaseTemplate,
    mySub,
  } = usePurchaseTemplate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isLoading = useSelector((state: RootState) => state.app.loading);
  const visibleGlobal = useSelector((state: RootState) => state.app.visibleGlobalCredit);
  const visibleGlobalUnsubscribe = useSelector((state: RootState) => state.app.visibleGlobalUnsubscribe);
  useCloseModalOnPopstate();
  const theme = useTheme();
  const isBrowser = typeof window !== 'undefined';

  const iLoading = !!isLoading;

  const showSwitchBtn =
    hideSwitchBtn ||
    (!!pathname && pathname.includes(RoutePaths.MY_REPORT)) ||
    (!!pathname && pathname.includes(RoutePaths.AGENT_REPORT));

  return (
    <HorizontalLayoutViewStyled.Container
      id={theme.layout.layoutId}
      className={className}
      removeFixedLayout={removeFixedLayout}
    >
      {!showSwitchBtn && <AppButtonSwitch />}
      {iLoading && <LoadingPage isLoading={!!isLoading} />}
      {isBrowser && (
        <>
          <ModalWebsite />
          <CookiesConsent />
          <ModalCheckout type={PaymentModalType.CREATE_CAMPAIGN} />
          <ModalAddMailing />
          <ModalSmartTrigger />
          <AddMailingButtonModal />
          <WarningMessageNodataModal />
          <ModalCreditBalance />
          <WarningThresholdLimitModal />
          <ModalPurchasePremium />
          <ModalPreviewLandingPage />
          <RedirectPastOrderModal />
          <ModalCreateLandingPage />
          <ModalSubscriptionPackage />
          <ModalConfigureBrokerSubscription />
          <CreditsModal
            type={PaymentModalType.MANUAL_BUY_CREDIT}
            cardPaymentInfo={userPaymentCard}
            onCancel={() => {
              setErrorPurchaseTemplate('');
              dispatch({
                type: AppAction.VISIBLE_GLOBAL_CREDIT,
                payload: null,
              });
            }}
            onConfirm={(payload) => {
              buyManualCredit(payload);
            }}
            buyCreditError={errorPurchaseTemplate}
          />
          <HomeLimitModal
            title="Upgrade Home Limit"
            label="Current Home Limit"
            initialValue={mySub?.home_balance || 0}
            onSubmit={handleSubmit}
            minHome={minHome}
            visible={!!visibleGlobal && visibleGlobal === GlobalModalCredits.HOME_CREDITS}
            onCancel={() => {
              setErrorPurchaseTemplate('');
              dispatch({
                type: AppAction.VISIBLE_GLOBAL_CREDIT,
                payload: null,
              });
            }}
            buyCreditError={errorPurchaseTemplate}
            isGlobal
          />
          <ConfirmModal
            className="confirm-address"
            title={'You can’t access this until you subscribe!'}
            btnSubmitText="Subscribe here"
            btnCancelText="Go Back"
            visible={visibleGlobalUnsubscribe}
            onCancel={() => {
              dispatch({
                type: AppAction.VISIBLE_GLOBAL_UNSUBSCRIBE,
                payload: false,
              });
              if (
                location?.pathname.includes(RoutePaths.CAMPAIGNS) ||
                location?.search?.includes(SmartActionTabsKey.SMART_ACTIONS)
              )
                return;
              if (location?.search.includes('open_new_tab=true')) {
                window.close();
                return;
              }
              navigate(-1);
            }}
            onSubmit={() => {
              dispatch({
                type: AppAction.VISIBLE_GLOBAL_UNSUBSCRIBE,
                payload: false,
              });
              navigate(`${RoutePaths.SETTINGS}/?tab=${SettingTabsKey.SUBSCRIPTION}`);
            }}
            closable={false}
            maskClosable={false}
            modalWidth={600}
          />
        </>
      )}
      {!isNonHeader ? <Header /> : null}
      {isHomePage ? (
        <Main>
          <UserInfo />

          <HorizontalLayoutViewStyled.SidebarContainer>
            <SideBarMenu />
            <div className="tabs-content">{children}</div>
          </HorizontalLayoutViewStyled.SidebarContainer>
        </Main>
      ) : (
        children
      )}
      {!isNonFooter ? <Footer /> : null}
    </HorizontalLayoutViewStyled.Container>
  );
};

export default Layout;
