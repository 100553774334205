import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseModal from '@/components/Modal';
import MailingAction from '@/reducers/mailing/action';
import { RootState } from '@/reducers/model';
import SmartTriggerForms from './SmartTriggerForms';
import { useStyleModal } from './style';
import AppAction from '@/reducers/app/action';

export interface ModalSmartTriggerProps {
  visible?: boolean;
}

const ModalSmartTrigger: React.FunctionComponent<ModalSmartTriggerProps> = ({ visible }) => {
  const visibleModalSmartTrigger = useSelector((state: RootState) => state.app.visibleModalSmartTrigger);
  const idEditing = useSelector((state: RootState) => state?.mailing?.stateSmartTrigger?.idEditingItemSmartTrigger);
  const { styleModal } = useStyleModal();
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch({
      type: AppAction.VISIBLE_MODAL_SMART_TRIGGER,
      payload: false,
    });
    dispatch({
      type: MailingAction.UPDATE_SMART_TRIGGER_STATE,
      payload: null,
    });
  };
  const isOpen = visibleModalSmartTrigger || !!visible || !!idEditing;
  if (!isOpen) return null;
  return (
    <BaseModal
      // centered
      style={{ top: 0 }}
      visible={isOpen}
      css={styleModal}
      closable={true}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="app-modal-body">
        <SmartTriggerForms visible={isOpen} onCancel={handleCancel} />
      </div>
    </BaseModal>
  );
};

export default ModalSmartTrigger;
