import React, { CSSProperties } from 'react';
import AddressItemSkeletonView from './AddressItemSkeletonView';

export interface AddressItemSkeletonProps {
  style?: CSSProperties;
}

const AddressItemSkeletonContainer: React.FC<AddressItemSkeletonProps> = ({ style }) => {
  return <AddressItemSkeletonView style={style} />;
};

export default AddressItemSkeletonContainer;
