import { AppSizeType } from '@/styles/theme';
import { Milestone } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Select, Tooltip } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';
import React from 'react';
import SelectV2 from '../AppSelectV2';
import { SelectColor } from '../AppSelectV2/AppSelectV2Styled';
import { AppSelectV2Props } from '../AppSelectV2/types';
import { SelectObjType } from '../custom-modals/modal-smart-trigger/useSmTrigger';
const { Option } = Select;
interface AppSelectProps extends Pick<AppSelectV2Props, 'hasError' | 'errorMessage' | 'labelColor'> {
  requiredMark?: boolean;
  label?: string;
  className?: string;
  handleChange?: (value: any) => void;
  handleBlur?: (e: React.FocusEvent) => void;
  handleFocus?: (e: React.FocusEvent) => void;
  value?: string | null | undefined | string[];
  itemSelect?: SelectObjType[] | Milestone[];
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  name: string;
  type?: string;
  searchValue?: string;
  onSearch?: (value: any) => void;
  handlePopupScroll?: (event: React.UIEvent<HTMLDivElement>) => Promise<void>;
  filterOption?: (input: string, option: BaseOptionType | DefaultOptionType | undefined) => boolean;
  allowClear?: boolean;
  parentId?: string;
  tooltipText?: string;
  mode?: 'multiple' | 'tags';
  size?: AppSizeType;
  color?: SelectColor;
}
const MultipleSelect = (props: AppSelectProps) => {
  const {
    requiredMark,
    label,
    className,
    handleChange,
    handleBlur,
    handleFocus,
    value,
    itemSelect,
    placeholder,
    isLoading = false,
    handlePopupScroll,
    tooltipText,
    ...rest
  } = props;

  return (
    <div className={className}>
      <Tooltip title={tooltipText ? <p>{tooltipText}</p> : null}>
        <SelectV2
          loading={isLoading}
          label={label}
          requiredMark={requiredMark}
          showArrow
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          mode="multiple"
          filterOption={handlePopupScroll ? false : true}
          placeholder={placeholder}
          value={value !== '' ? value : null}
          onPopupScroll={handlePopupScroll}
          {...rest}
        >
          {itemSelect?.map((option) => (
            <Option key={option.value} value={option.value} disabled={option.disabled} label={option.label}>
              {option.label}
            </Option>
          ))}
        </SelectV2>
      </Tooltip>
    </div>
  );
};

export default MultipleSelect;
