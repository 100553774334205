import { Image } from 'antd';
import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogoHomemeta from '@/assets/images/homemeta-logo-white.png';
import { useUserRole } from '@/hooks/useUserRole';
import MailingAction from '@/reducers/mailing/action';
import { RootState } from '@/reducers/model';
import { UserType } from '@/utils/enum';
import { formatPhoneNumber } from '@/utils/format';
import RoutePaths from '@/utils/routes';
import HeaderViewStyled from './HeaderViewStyled';
import useDisableBySubscription from '@/hooks/useDisableBySubscription';
import AppButtonV2 from '@/components/AppButtonV2';
import theme from '@/styles/theme';
import { HiOutlineUserCircle } from 'react-icons/hi2';

const HeaderView: React.FC = () => {
  const [toggle, setToggleDrawerNav] = useState(false);
  const [errorAvatar, setErrorAvatar] = useState<boolean>(false);
  const profile = useSelector((state: RootState) => state.auth.myProfile);
  const agentId = useSelector((state: RootState) => state?.home?.userAgent?.id);
  const mailCreditBalance = useSelector((state: RootState) => state.credit.myCredit?.credit_amount || 0);
  const currentBalance = useSelector((state: RootState) => state.homeCredit?.mySub?.current_balance || 0);
  const myProfile = useSelector((state: RootState) => state.auth.myProfile);
  const { isUnlimitedHome } = useDisableBySubscription();
  const pathname = window.location.pathname;
  const isHideInfo = [RoutePaths.PRIVACY_POLICY, RoutePaths.TERMS_AND_CONDITIONS].some((el) => pathname.includes(el));
  const hasMailingList = myProfile?.has_mailing_list;
  const dispatch = useDispatch();
  const { isManager } = useUserRole();

  const changeToggleDrawerNav = () => {
    setToggleDrawerNav(!toggle);
  };

  const renderLinkUrl = () => {
    if (agentId) {
      const newPath = hasMailingList ? `${RoutePaths.MY_REPORT}/?ssid=${agentId}` : RoutePaths.CREATE_MAILING_MANAGER;
      return newPath;
    }
    return RoutePaths.MY_REPORT;
  };

  const renderCurrentBalanceText = () => {
    if (isUnlimitedHome) return 'Unlimit';
    return `${currentBalance || 0} ${currentBalance && currentBalance > 1 ? 'HOME CREDITS' : 'HOME CREDIT'}`;
  };
  const dispatchData = () => {
    dispatch({
      type: MailingAction.RESET_MAILING_LIST_COMBINE,
    });
  };

  return (
    <HeaderViewStyled className="app-header">
      <div className="nav-bar w-nav">
        <a
          href={isManager ? RoutePaths.MANAGER : RoutePaths.MAILING_LISTS}
          aria-current="page"
          className="brand w-inline-block w--current"
        >
          <div className="logo">
            <img src={LogoHomemeta} alt="home page" />
          </div>
        </a>
        <div className="navigation">
          <div className="nav-bar-info">
            {!isHideInfo && (
              <>
                <>
                  <p className="nav-bar-credit">
                    {mailCreditBalance}{' '}
                    {mailCreditBalance && mailCreditBalance > 1 ? 'CAMPAIGN CREDITS' : 'CAMPAIGN CREDIT'}
                  </p>
                  <p className="nav-bar-home">{renderCurrentBalanceText()}</p>
                </>
                <div className="nav-bar-contacts">
                  <div>
                    Email: &nbsp;
                    <a href="mailto:your@email.com?subject=Your%20Subject" className="link-white">
                      {profile?.email || 'dev@firstteam.com'}
                    </a>
                  </div>
                  <div>
                    Phone: &nbsp;{(profile && profile.phone && formatPhoneNumber(profile.phone.slice(2))) || 'N/A'}
                  </div>
                </div>
              </>
            )}
            <AppButtonV2
              size="large"
              color="Outline"
              outlineColor={theme.color.WHITE}
              letter={4}
              fixedLetter
              htmlType="submit"
              width="fit-content"
              fontWeight={400}
              // disabled={profile && profile?.user_type === UserType.MANAGER ? true : false}
              onClick={() => {
                const pathname = window.location.pathname;
                if (!profile) return navigate(RoutePaths.LOGIN);
                if (profile?.user_type === UserType.BROKER) {
                  navigate(RoutePaths.BROKER_MY_AGENTS);
                }
                if (profile?.user_type === UserType.AGENT) {
                  navigate(RoutePaths.AGENT_REPORT);
                }
                if (profile?.user_type === UserType.MANAGER && !pathname.includes(RoutePaths.MANAGER)) {
                  navigate(RoutePaths.MANAGER);
                }
                if (profile?.user_type === UserType.MANAGER && pathname.includes(RoutePaths.MANAGER)) {
                  window.location.href = RoutePaths.MANAGER;
                }
              }}
              style={{ padding: '0 12px' }}
            >
              {profile ? `${profile?.first_name} ${profile?.last_name}` : 'Login'}
              {profile && profile?.avatar && !errorAvatar ? (
                <Image src={profile?.avatar} preview={false} onError={() => setErrorAvatar(true)} />
              ) : (
                <HiOutlineUserCircle size={34} strokeWidth={1.2} />
              )}
            </AppButtonV2>
          </div>
          <div className={classNames('menu-button w-clearfix w-nav-button', { ' w--open': toggle })}>
            <div className="menu-icon" onClick={() => changeToggleDrawerNav()}>
              <div className="menu-icon-line-top" />
              <div className="menu-icon-line-bottom" />
            </div>
          </div>
          <nav role="navigation" className={toggle ? 'nav-menu w-nav-menu w--open' : 'nav-menu w-nav-menu'}>
            <div className="nav-menu-links">
              {profile && profile?.user_type === UserType.AGENT && (
                <>
                  <Link to={RoutePaths.MAILING_LISTS} className="nav-link w-inline-block" activeClassName="active">
                    <div className="nav-link-content">
                      <div>Dashboard</div>
                      <div className="link-highlight nav-link-line" />
                    </div>
                  </Link>
                  <Link
                    to={RoutePaths.CAMPAIGNS}
                    aria-current="page"
                    onClick={changeToggleDrawerNav}
                    className="nav-link w-inline-block w--current"
                    activeClassName="active"
                  >
                    <div className="nav-link-content">
                      <div>Campaigns</div>
                      <div className="link-highlight nav-link-line" />
                    </div>
                  </Link>
                  <Link to={RoutePaths.DRIP_CAMPAIGNS} className="nav-link w-inline-block" activeClassName="active">
                    <div className="nav-link-content">
                      <div>Drip Campaigns</div>
                      <div className="link-highlight nav-link-line" />
                    </div>
                  </Link>
                </>
              )}
              {profile && profile?.user_type === UserType.MANAGER && (
                <Link
                  to={renderLinkUrl()}
                  onClick={() => dispatchData()}
                  className="nav-link w-inline-block"
                  activeClassName="active"
                >
                  <div className="nav-link-content">
                    <div>MY REPORT</div>
                    <div className="link-highlight nav-link-line" />
                  </div>
                </Link>
              )}
              {profile && profile?.user_type === UserType.MANAGER && (
                <Link to={RoutePaths.AGENT_LOOKUP} className="nav-link w-inline-block" activeClassName="active">
                  <div className="nav-link-content">
                    <div>AGENT LOOKUP</div>
                    <div className="link-highlight nav-link-line" />
                  </div>
                </Link>
              )}
              {profile && profile?.user_type === UserType.MANAGER && (
                <Link to={RoutePaths.CAMPAIGNS} className="nav-link w-inline-block" activeClassName="active">
                  <div className="nav-link-content">
                    <div>CAMPAIGNS</div>
                    <div className="link-highlight nav-link-line" />
                  </div>
                </Link>
              )}
              {profile && profile?.user_type === UserType.MANAGER && (
                <Link to={RoutePaths.SETTINGS} className="nav-link w-inline-block" activeClassName="active">
                  <div className="nav-link-content">
                    <div>SETTINGS</div>
                    <div className="link-highlight nav-link-line" />
                  </div>
                </Link>
              )}
              {profile && profile?.user_type === UserType.BROKER && (
                <Link to={RoutePaths.BROKER_MY_AGENTS} className="nav-link w-inline-block" activeClassName="active">
                  <div className="nav-link-content">
                    <div>MY AGENTS</div>
                    <div className="link-highlight nav-link-line" />
                  </div>
                </Link>
              )}
              {profile && profile?.user_type === UserType.AGENT && (
                <Link
                  to={RoutePaths.SMART_ACTIONS}
                  className="nav-link w-inline-block"
                  activeClassName="active"
                  partiallyActive
                >
                  <div className="nav-link-content">
                    <div>Smart Actions</div>
                    <div className="link-highlight nav-link-line" />
                  </div>
                </Link>
              )}
              {profile && (
                <Link to={RoutePaths.LOGOUT} className="nav-link w-inline-block" activeClassName="active">
                  <div className="nav-link-content">
                    <div>Log out</div>
                    <div className="link-highlight nav-link-line" />
                  </div>
                </Link>
              )}

              {!profile && (
                <>
                  <Link to={'/register'} className="nav-link w-inline-block" activeClassName="active">
                    <div className="nav-link-content">
                      <div>Register</div>
                      <div className="link-highlight nav-link-line" />
                    </div>
                  </Link>
                  <Link to={'/login'} className="nav-link w-inline-block" activeClassName="active">
                    <div className="nav-link-content">
                      <div>Login</div>
                      <div className="link-highlight nav-link-line" />
                    </div>
                  </Link>
                </>
              )}
            </div>
          </nav>
        </div>
      </div>
    </HeaderViewStyled>
  );
};

export default HeaderView;
