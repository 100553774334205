import AppSelect from '@/components/select';
import { ValueLabel } from '@/models';
import { VIEW_MODE } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import {
  MailingExtra,
  OwnerAddressType,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { Tooltip } from 'antd';
import { debounce } from 'lodash';
import React, { CSSProperties, Dispatch, FC, memo, SetStateAction } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';
import AddressChecked from '../AddressChecked';
import { AddressSelect } from '../types';
import { AddressItemStyled } from './AddressItemStyled';

interface AddressItemProps {
  style?: CSSProperties;
  filterState?: VIEW_MODE;
  isAllowUpdateOwnerDropdown?: boolean;
  checked: boolean;
  mailing: MailingExtra;
  onToggle: (address: AddressSelect) => void;
  uniqID?: string;
  isDuplicated?: boolean;
  isLoading: boolean;
  dropdownId: string;
  ownerAddress: ValueLabel<OwnerAddressType>[];
  setAddressType: Dispatch<SetStateAction<string>>;
  addressType: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isRealtorFlag: boolean;
  isOwnerOfState: boolean;
}

const AddressItemView: FC<AddressItemProps> = memo((props) => {
  const { isDuplicated, style } = props;
  if (isDuplicated) return null;
  return (
    <div style={style}>
      {isDuplicated ? (
        <Tooltip
          title={<p style={{ textAlign: 'center', fontWeight: 700 }}>Unable to interact with the duplicated house</p>}
          placement="bottom"
        >
          <ItemContent {...props} />
        </Tooltip>
      ) : (
        <ItemContent {...props} />
      )}
    </div>
  );
});

const ItemContent: FC<AddressItemProps> = memo((props) => {
  const {
    addressType,
    checked,
    dropdownId,
    isLoading,
    isOwnerOfState,
    isRealtorFlag,
    mailing,
    onToggle,
    ownerAddress,
    setAddressType,
    setIsLoading,
    filterState,
    isAllowUpdateOwnerDropdown,
    isDuplicated,
    uniqID,
  } = props;
  return (
    <AddressItemStyled.Wrapper
      checked={checked}
      isLoading={isLoading}
      filterState={filterState}
      style={{ cursor: isDuplicated ? 'not-allowed' : 'default' }}
    >
      <div className="info-container">
        <div id={'parent-owner-addresses-type ' + uniqID + dropdownId}>
          {!isAllowUpdateOwnerDropdown ? (
            <Tooltip
              title={
                isDuplicated ? null : (
                  <p>
                    Not able to change if the <strong>Send to</strong> is selected as <strong>Owner/Renter</strong>
                  </p>
                )
              }
            >
              <AppSelect
                className={`address-type ${!isAllowUpdateOwnerDropdown ? 'disabled' : ''} ${
                  isDuplicated ? 'duplicated' : ''
                }`}
                placeholder="Owner"
                type="string"
                itemSelect={ownerAddress.map((v) => v.label)}
                handleChange={(value) => {
                  if (!isAllowUpdateOwnerDropdown) return;

                  setAddressType(value);
                  const item = ownerAddress.find((v) => v.label === value);
                  if (!item) return;
                  onToggle({
                    id: mailing.id,
                    addressType: item.value,
                  });
                }}
                disabled={!isAllowUpdateOwnerDropdown}
                value={addressType}
                isGetContainer
                idParent={'parent-owner-addresses-type ' + uniqID + dropdownId}
              />
            </Tooltip>
          ) : (
            <AppSelect
              className={`address-type  ${isDuplicated ? 'duplicated' : ''}`}
              placeholder="Owner"
              type="string"
              itemSelect={ownerAddress.map((v) => v.label)}
              handleChange={(value) => {
                // Disabled and not allow to update
                if (isDuplicated) return;

                if (!isAllowUpdateOwnerDropdown) return;

                setAddressType(value);
                const item = ownerAddress.find((v) => v.label === value);
                if (!item) return;
                onToggle({
                  id: mailing.id,
                  addressType: item.value,
                });
              }}
              disabled={!isAllowUpdateOwnerDropdown || isDuplicated}
              value={addressType}
              isGetContainer
              idParent={'parent-owner-addresses-type ' + uniqID + dropdownId}
            />
          )}
        </div>
        {addressType === ownerAddress[0].label && (
          <>
            <Tooltip
              placement="bottom"
              title={
                isDuplicated
                  ? null
                  : `${
                      mailing.owner_first_name || mailing.owner_last_name
                        ? `${mailing.owner_first_name || ''} ${mailing.owner_last_name || ''}`
                        : 'Owner: N/A'
                    }`
              }
            >
              <span className="name">{`${
                mailing.owner_first_name || mailing.owner_last_name
                  ? `${mailing.owner_first_name || ''} ${mailing.owner_last_name || ''}`
                  : 'Owner: N/A'
              }`}</span>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title={
                isDuplicated ? null : (
                  <>
                    {mailing?.owner_address ? `${mailing?.owner_address}, ` : null}
                    {mailing?.owner_city ? `${mailing?.owner_city}, ` : null}
                    {mailing?.owner_state ? `${mailing?.owner_state} ` : null}
                    {mailing?.owner_zip_code ? `${mailing?.owner_zip_code}` : null}
                    {!mailing?.owner_address &&
                    !mailing?.owner_city &&
                    !mailing?.owner_state &&
                    !mailing?.owner_zip_code
                      ? 'Owner Address: N/A'
                      : null}
                  </>
                )
              }
            >
              <p className="address">
                {mailing?.owner_address ? `${mailing?.owner_address}, ` : null}
                {mailing?.owner_city ? `${mailing?.owner_city}, ` : null}
                {mailing?.owner_state ? `${mailing?.owner_state} ` : null}
                {mailing?.owner_zip_code ? `${mailing?.owner_zip_code}` : null}
                {!mailing?.owner_address && !mailing?.owner_city && !mailing?.owner_state && !mailing?.owner_zip_code
                  ? 'Owner Address: N/A'
                  : null}
              </p>
            </Tooltip>
          </>
        )}
        {addressType === ownerAddress[1].label && (
          <>
            <Tooltip
              placement="bottom"
              title={
                isDuplicated
                  ? null
                  : `${
                      mailing.first_name && mailing.last_name
                        ? `${mailing.first_name} ${mailing.last_name}`
                        : 'Current Resident'
                    }`
              }
            >
              <span className="name">{`${
                mailing.first_name && mailing.last_name
                  ? `${mailing.first_name} ${mailing.last_name}`
                  : 'Current Resident'
              }`}</span>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title={
                isDuplicated ? null : (
                  <>
                    {mailing?.address ? `${mailing?.address}, ` : null}
                    {mailing?.city ? `${mailing?.city}, ` : null}
                    {mailing?.state ? `${mailing?.state} ` : null}
                    {mailing?.zip_code ? `${mailing?.zip_code}` : null}
                    {!mailing?.address && !mailing?.city && !mailing?.state && !mailing?.zip_code
                      ? 'Address: N/A'
                      : null}
                  </>
                )
              }
            >
              <p className="address">
                {mailing?.address ? `${mailing?.address}, ` : null}
                {mailing?.city ? `${mailing?.city}, ` : null}
                {mailing?.state ? `${mailing?.state} ` : null}
                {mailing?.zip_code ? `${mailing?.zip_code}` : null}
                {!mailing?.address && !mailing?.city && !mailing?.state && !mailing?.zip_code ? 'Address: N/A' : null}
              </p>
            </Tooltip>
          </>
        )}
      </div>

      <AddressChecked
        checked={checked}
        disabled={isDuplicated}
        onChecked={(flag) => {
          // Disabled and not allow to update
          if (isDuplicated) return;

          setIsLoading(true);
          // setChecked(flag);
          const item = ownerAddress.find((v) => v.label === addressType);
          if (!item) return;
          onToggle({
            id: mailing.id,
            selectedToSend: flag,
          });
          debounce(() => setIsLoading(false), 500)();
        }}
      />

      <AddressItemStyled.AddressCheckedStyled>
        {isRealtorFlag && <Label realtor />}
        {isOwnerOfState && <Label />}
        {mailing.mailing_list?.name && (
          <Tooltip
            placement="bottom"
            title={mailing.mailing_list?.name || 'N/A'}
            overlayInnerStyle={{ width: 'max-content' }}
          >
            <span className="mailing-list-name">{mailing.mailing_list?.name || 'N/A'}</span>
          </Tooltip>
        )}
      </AddressItemStyled.AddressCheckedStyled>
    </AddressItemStyled.Wrapper>
  );
});

interface LabelProps {
  realtor?: boolean;
}

const Label: FC<LabelProps> = memo(({ realtor = false }) => (
  <AddressItemStyled.LabelStyled>
    <RiErrorWarningLine width={20} height={20} />
    {realtor ? (
      <Tooltip
        title="Check the boxes on the homes you know are Realtors to eliminate them from your campaigns"
        placement="right"
      >
        <span>Realtor</span>
      </Tooltip>
    ) : (
      <span>
        Owner
        <br /> Out of State
      </span>
    )}
  </AddressItemStyled.LabelStyled>
));

export default AddressItemView;
