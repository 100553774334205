import { color } from '@/styles/theme';
import styled from '@emotion/styled';

export const AddressItemSkeletonStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 185px;
  width: 100%;
  max-width: 372px;
  background-color: ${color.WHITE};
  padding: 15px 19px;
  border: 1px solid ${color.WHITE_4};
  box-shadow: 0 0 25px 0 ${color.BLACK_O30};
  .flex-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  .flex-end {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }
`;
