import { color } from '@/styles/theme';
import styled from '@emotion/styled';
import { ModalBaseStyled } from '../modal-actions/ModalActionsStyled';

const ModalWrapper = styled(ModalBaseStyled)`
  .ant-modal-close {
    right: -400px;
  }
  .personal-information {
    margin-bottom: 12px !important;
  }
  .home-count {
    font-weight: 500;
  }
  .realtor-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }
`;
const SenTo = styled.div`
  width: 145px;
  display: flex;
  align-items: center;
  .filter-dropdown {
    width: 100%;
    .item-field {
      border: 1px solid ${color.WHITE};
      border-radius: 15px;
      .ant-select {
        height: 30px;
        min-height: 30px !important;
        background-color: transparent !important;
        .ant-select-selector {
          height: 32px;
          min-height: 30px !important;
          background-color: transparent !important;
          border: none;
          .ant-select-selection-placeholder {
            color: ${color.WHITE};
            font-weight: 700;
            font-size: 13px;
          }
          .ant-select-selection-item {
            color: ${color.WHITE};
            font-weight: 700;
            font-size: 13px;
            margin-bottom: 3px;
          }
        }
        .ant-select-arrow {
          color: ${color.WHITE};
        }
      }
    }
  }
`;

const FilterSection = styled.div`
  width: 100%;
  .search-wrapper {
    margin-top: 0;
    .search {
      input {
        border-radius: 7px;
        padding-left: 45px;
        font-size: 12px;
      }
    }
  }
`;

const Btn = styled.div`
  display: flex;
  gap: 5px;
  button.ant-btn {
    border-radius: 15px;
    border: solid 1px ${color.WHITE};
    background: transparent;
    color: ${color.WHITE};
    font-size: 13px;
    font-weight: bold;
    min-width: 74px;
    &.active {
      border: solid 1px ${color.PINK};
      background: ${color.PINK};
      color: ${color.WHITE};
    }
  }
  .filter-dropdown {
    .item-field {
      border: 1px solid ${color.WHITE};
      border-radius: 15px;
      .ant-select {
        height: 30px;
        min-height: 30px !important;
        background-color: transparent !important;
        .ant-select-selector {
          min-width: 190px;
          height: 32px;
          min-height: 30px !important;
          background-color: transparent !important;
          border: none;
          .ant-select-selection-placeholder {
            color: ${color.WHITE};
            font-weight: 700;
            font-size: 13px;
          }
          .ant-select-selection-item {
            color: ${color.WHITE};
            font-weight: 700;
            font-size: 13px;
            margin-bottom: 3px;
          }
        }
        .ant-select-arrow {
          color: ${color.WHITE};
        }
      }
    }
    .owner-out-of-state {
      width: 200px;
    }
  }
`;

const BtnSelectAll = styled.div`
  display: flex;
  gap: 14px;
`;

const ModalBody = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn-field {
    width: 100%;
    button {
      font-size: 17px;
      font-weight: 700;
      margin-bottom: 0 !important;
    }
  }
  .error {
    font-weight: 600;
  }
`;

interface FormItemProps {
  isEmptyList: boolean;
}
const FormItem = styled.div<FormItemProps>`
  width: 1332px;
  padding: 45px 50px !important;
  .ant-switch-handle {
    &::before {
      width: 18px;
    }
  }
  .container-content {
    position: relative;
    height: 585px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 15px;
    background-color: ${color.WHITE};
    padding: 0px 25px;
    padding-right: 0;
    border-radius: 5px;
    justify-content: ${({ isEmptyList }) => (isEmptyList ? 'center' : 'flex-start')};
    opacity: 1;
    & .content {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      justify-content: flex-start;
      align-items: flex-start;
      height: fit-content;
      & .ant-empty {
        height: 576px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
  }
  .filter-section {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    justify-content: space-between;
    gap: 25px;
  }
`;

export const MailingAddressSentFormsStyled = {
  ModalWrapper,
  ModalBody,
  FormItem,
  SenTo,
  FilterSection,
  Btn,
  BtnSelectAll,
};
