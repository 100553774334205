import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { AddressItemSkeletonProps } from './AddressItemSkeletonContainer';
import { AddressItemSkeletonStyled } from './AddressItemSkeletonStyled';

const AddressItemSkeletonView: React.FC<AddressItemSkeletonProps> = memo(({ style }) => {
  return (
    <AddressItemSkeletonStyled style={style}>
      <div className="flex-start">
        <Skeleton width={100} height={22} />
        <Skeleton width={100} height={22} />
      </div>

      <Skeleton width={190} height={22} />
      <br />
      <br />
      <div className="flex-end">
        <Skeleton width={220} height={22} />
        <Skeleton width={90} height={22} />
      </div>
    </AddressItemSkeletonStyled>
  );
});

export default AddressItemSkeletonView;
