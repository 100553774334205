import { color } from '@/styles/theme';
import styled from '@emotion/styled';
import { VIEW_MODE } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';

const LabelStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${color.PINK};
  font-size: 10px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  text-align: left;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const Wrapper = styled.div<{ checked: boolean; isLoading: boolean; filterState?: VIEW_MODE }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 372px;
  height: 185px;
  padding: 15px 19px;
  border: ${({ checked }) => (checked ? `1px solid ${color.PINK}` : '1px solid transparent')};
  box-shadow: 0 0 25px 0 ${color.BLACK_O30};
  opacity: ${(props) => {
    const validFilterState =
      props.filterState && [VIEW_MODE.VIEW_MODE_ON, VIEW_MODE.VIEW_MODE_OFF].includes(props.filterState);
    return validFilterState && props.isLoading ? '0' : '1';
  }};
  transition: opacity 0.5s ease-in-out;

  & .info-container {
    max-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 192px;
    color: ${color.BLACK_5};
    font-size: 14px;
    .name {
      opacity: ${(props) => (props.checked ? 1 : 0.5)};
      font-weight: 700;
      height: 25px;
      margin-top: 4px;
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1; /* Maximum number of lines to display */
      overflow: hidden;
    }
    .address {
      text-align: left;
      opacity: ${({ checked }) => (checked ? 1 : 0.5)};
      width: 220px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* Maximum number of lines to display */
      overflow: hidden;
    }
    button {
      border: solid 1px ${color.GRAY_3};
      color: ${color.GRAY_3};
      font-size: 12px;
      font-weight: 700;
    }
    .address-type {
      max-width: 100px;
      & .ant-select {
        height: 32px !important;
        min-height: 32px !important;
        .ant-select-selector {
          background-color: ${color.GRAY_3} !important;
          border: none;
          min-height: 32px !important;
          .ant-select-selection-item {
            font-size: 12px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.92;
            letter-spacing: -0.24px;
            color: ${color.WHITE};
          }
        }
        .ant-select-arrow {
          color: ${color.WHITE};
        }
      }
      &.disabled {
        opacity: 0.75;
      }
      &.duplicated {
        opacity: 0.5;
      }
    }
  }
`;
const AddressCheckedStyled = styled.div`
  position: absolute;
  bottom: 14px;
  right: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  .mailing-list-name {
    color: ${color.PINK};
    font-weight: 600;
    border: 2px solid ${color.PINK};
    border-radius: 50px;
    width: 200px;
    margin-bottom: 4px;
    font-size: 13px;
    padding: 2px 4px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
export const AddressItemStyled = { Wrapper, LabelStyled, AddressCheckedStyled };
