import React, { memo } from 'react';
import { Switch } from 'antd';
import { AddressCheckedStyled } from './AddressCheckedStyled';
import { AddressCheckedProps } from './AddressCheckedContainer';

const AddressCheckedView: React.FC<AddressCheckedProps> = memo(({ checked, disabled, onChecked }) => {
  return (
    <AddressCheckedStyled>
      <span className="toggle-label on">ON</span>
      <Switch disabled={disabled} checked={checked} onChange={onChecked} />
      <span className="toggle-label">OFF</span>
    </AddressCheckedStyled>
  );
});

export default AddressCheckedView;
