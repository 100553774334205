import { Card, Input } from 'antd';
import React, { ChangeEvent, memo } from 'react';
import BrokerLPIntroBottomRight from '@/assets/images/broker_lps_intro_bottom_right.png';
import BrokerLPIntroTopLeft from '@/assets/images/broker_lps_intro_top_left.png';
import BrokerLPIntroBottom from '@/assets/images/broker_lp_intro_bottom.png';
import BrokerLPIntroTop from '@/assets/images/broker_lp_intro_top.png';
import LogoHomeMeta from '@/assets/images/homemeta-logo-white.png';
import BrokerLandingPageBuilderStyled from '@/components/BrokerLandingPageBuilder/BrokerLandingPageBuilderStyled';
import { BrokerLPTemplateFormData } from '@/components/BrokerLandingPageBuilder/BrokerLandingPageTemplateContainer';
import { BrokerLandingPageVariables } from '../custom-html-editor/hooks/useFetchVariablesByType';
import ErrorMessage from '../error-message';
import AppButtonV2 from '../AppButtonV2';

const { TextArea } = Input;

interface BrokerLandingPageTemplateProps {
  isEditMode: boolean;
  background?: string;
  renderTitleHtml: string;
  brokerLPData: BrokerLPTemplateFormData;
  handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleSeeMyHomeForFree?: () => void;
  handleInput: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  renderDescriptionHtml: string;
  errors?: any;
  hasError: (key: string) => boolean;
  handleAutomaticallySendACampaign?: () => void;
  freeButtonLabelText?: string;
  campaignPriceVar?: string;
  autoSendCampaignLabelText?: string;
  disabledAutoSendCampaign?: boolean;
}

const defaultBrokerLPTemplatePlaceholder = {
  titleText: 'Enter title here',
  descriptionText: 'Enter description here',
  freeButtonLabelText: 'Enter The Name of Button 1',
  autoSendCampaignLabelText: 'Enter The Name of Button 2',
};

const BrokerLandingPageTemplateView = (props: BrokerLandingPageTemplateProps) => {
  const {
    isEditMode,
    background,
    renderTitleHtml,
    brokerLPData,
    handleChange,
    handleInput,
    handleSeeMyHomeForFree,
    renderDescriptionHtml,
    hasError,
    errors,
    handleAutomaticallySendACampaign,
    freeButtonLabelText,
    disabledAutoSendCampaign,
    autoSendCampaignLabelText,
    campaignPriceVar,
  } = props;

  return (
    <BrokerLandingPageBuilderStyled isEditMode={isEditMode} background={background}>
      <Card className="broker-content-wrapper">
        {!background && (
          <>
            <img src={BrokerLPIntroTop} className="broker_bg img_BrokerLPIntroTop" alt="" />
            <img src={BrokerLPIntroBottom} className="broker_bg img_BrokerLPIntroBottom" alt="" />
            <img src={BrokerLPIntroBottomRight} className="broker_bg img_BrokerLPIntroBottomRight" alt="" />
            <img src={BrokerLPIntroTopLeft} className="broker_bg img_BrokerLPIntroTopLeft" alt="" />
          </>
        )}
        <div className="broker-form-content">
          <img src={LogoHomeMeta} alt="home page" className="logo" />

          <div className="info-group">
            <div
              className="titleText"
              dangerouslySetInnerHTML={{
                __html: renderTitleHtml,
              }}
            ></div>
            {isEditMode && (
              <>
                <TextArea
                  name="titleText"
                  placeholder={defaultBrokerLPTemplatePlaceholder.titleText}
                  value={brokerLPData?.titleText}
                  rows={2}
                  cols={20}
                  onChange={handleChange}
                  onInput={handleInput}
                  className="text-field titleText"
                  autoFocus
                  status={hasError('titleText') ? 'error' : ''}
                />
                {hasError('titleText') ? <ErrorMessage className="error">{errors.titleText}</ErrorMessage> : null}
              </>
            )}
          </div>

          <div className="info-group">
            <div
              className="descriptionText"
              dangerouslySetInnerHTML={{
                __html: renderDescriptionHtml,
              }}
            ></div>
            {isEditMode && (
              <>
                <TextArea
                  name="descriptionText"
                  placeholder={defaultBrokerLPTemplatePlaceholder.descriptionText}
                  value={brokerLPData?.descriptionText}
                  rows={1}
                  cols={20}
                  onChange={handleChange}
                  onInput={handleInput}
                  className="text-field descriptionText"
                  status={hasError('descriptionText') ? 'error' : ''}
                />
                {hasError('descriptionText') ? (
                  <ErrorMessage className="error">{errors.descriptionText}</ErrorMessage>
                ) : null}
              </>
            )}
          </div>

          <div className="action-list">
            <div className="info-group see-home-free">
              <AppButtonV2
                color="Pin"
                width="100%"
                size="large"
                onClick={() => {
                  if (freeButtonLabelText?.includes(BrokerLandingPageVariables.CampaignPrice)) {
                    handleAutomaticallySendACampaign && handleAutomaticallySendACampaign();
                  } else {
                    handleSeeMyHomeForFree && handleSeeMyHomeForFree();
                  }
                }}
              >
                {brokerLPData?.freeButtonLabelText}
              </AppButtonV2>
              {isEditMode && (
                <>
                  <TextArea
                    name="freeButtonLabelText"
                    placeholder={defaultBrokerLPTemplatePlaceholder.freeButtonLabelText}
                    value={brokerLPData.freeButtonLabelText}
                    rows={1}
                    cols={20}
                    onChange={handleChange}
                    onInput={handleInput}
                    className="text-field action-btn"
                    status={hasError('freeButtonLabelText') ? 'error' : ''}
                  />
                  {hasError('freeButtonLabelText') ? (
                    <ErrorMessage className="error">{errors.freeButtonLabelText}</ErrorMessage>
                  ) : null}
                </>
              )}
            </div>
            <span style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}>OR</span>
            <div className="info-group">
              <AppButtonV2
                width="100%"
                color="Pin"
                size="large"
                disabled={disabledAutoSendCampaign}
                onClick={() => {
                  if (disabledAutoSendCampaign) return;
                  if (autoSendCampaignLabelText?.includes(BrokerLandingPageVariables.CampaignPrice)) {
                    handleAutomaticallySendACampaign && handleAutomaticallySendACampaign();
                  } else {
                    handleSeeMyHomeForFree && handleSeeMyHomeForFree();
                  }
                }}
              >
                {isEditMode
                  ? brokerLPData?.autoSendCampaignLabelText
                  : brokerLPData?.autoSendCampaignLabelText?.replace(
                      BrokerLandingPageVariables.CampaignPrice,
                      campaignPriceVar || 'N/A',
                    )}
              </AppButtonV2>
              {isEditMode && (
                <>
                  <TextArea
                    name="autoSendCampaignLabelText"
                    placeholder={defaultBrokerLPTemplatePlaceholder.autoSendCampaignLabelText}
                    value={brokerLPData.autoSendCampaignLabelText}
                    rows={1}
                    cols={20}
                    onChange={handleChange}
                    onInput={handleInput}
                    className="text-field action-btn"
                    status={hasError('autoSendCampaignLabelText') ? 'error' : ''}
                  />
                  {hasError('autoSendCampaignLabelText') ? (
                    <ErrorMessage className="error">{errors.autoSendCampaignLabelText}</ErrorMessage>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </BrokerLandingPageBuilderStyled>
  );
};
export default memo(BrokerLandingPageTemplateView);
