import styled from '@emotion/styled';
import { ModalBaseStyled } from '../modal-actions/ModalActionsStyled';

export const ModalBrokerConfigurationStyled = styled(ModalBaseStyled)`
  .btn-field {
    button {
      font-size: 17px;
      font-weight: 700;
    }
  }
  .title {
    padding-top: 15px;
  }
  .error {
    font-weight: 600;
  }
  .form-item {
    padding: 25px 25px 25px 35px !important;
  }
`;
