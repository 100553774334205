// FIXME Need to improve
/*
 * merge dataSources and mailings -> 1 array to show in the screen
 */

import { AddressesSearchCampaignParams, AddressesSearchGeneralParams, ShowAddressesMode } from '@/models';
import { AddressesSearchMLSParams } from '@/models/AddressesSearchMLSParams';
import MailingAction from '@/reducers/mailing/action';
import { filterSearch, getDuplicatedHouses } from '@/reducers/mailing/helper';
import { RootState } from '@/reducers/model';
import SmartActionService from '@/services/smart-action';
import { Mailing } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import {
  SEND_ADDRESS,
  VIEW_MODE,
  VIEW_OWNER_OCCUPIED,
  VIEW_OWNER_OUT_OF_STATE,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import {
  MailingExtra,
  OwnerAddressType,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { debounce, isEqual, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MailingAddressSentFormsView from './MailingAddressSentFormsView';
import { AddressSelect } from './types';

interface AddressesFoundCountType {
  count: number;
  updatedCount: number;
  params: AddressesSearchCampaignParams | AddressesSearchMLSParams;
  mailings: Mailing[];
}

interface MailingAddressSentFormsContainerProps {
  mode: ShowAddressesMode;
  uniqID?: string;
  isEditingMLSPopup?: boolean;
  isViewAddresses?: boolean;
  onResetOutsideFilter?: () => void;
  onCancel?: () => void;
}

const MailingAddressSentFormsContainer: React.FunctionComponent<MailingAddressSentFormsContainerProps> = ({
  mode,
  uniqID,
  isEditingMLSPopup,
  isViewAddresses,
  onResetOutsideFilter,
  onCancel,
}) => {
  const isOpenAddressPopup = useSelector((state: RootState) => state?.mailing?.isOpenAddressPopup);
  const addressesFoundCountForCampaign = useSelector(
    (state: RootState) => state?.mailing?.addressesFoundCountForCampaign,
  );
  const addressesFoundCountForMLS = useSelector((state: RootState) => state?.mailing?.addressesFoundCountForMLS);
  const addressesFoundCount =
    mode === ShowAddressesMode.CAMPAIGN_BUILDER ? addressesFoundCountForCampaign : addressesFoundCountForMLS;
  const isCreateMLSCase = !(addressesFoundCount?.params as AddressesSearchMLSParams)?.smart_trigger_action_id;

  const addressesFoundCountLoading = useSelector((state: RootState) => state?.mailing?.addressesFoundCountLoading);

  const dispatch = useDispatch();

  const currentParamsRef = useRef<AddressesSearchCampaignParams | AddressesSearchMLSParams>();

  const addressFoundCountCachedRef = useRef<AddressesFoundCountType>();
  const [dataSources, setDataSources] = useState<MailingExtra[]>([]);
  const [mailings, setMailings] = useState<MailingExtra[]>([]);
  const [filterState, setFilterState] = useState(VIEW_MODE.VIEW_MODE_ALL);
  const [textSearch, setTextSearch] = useState('');
  const [ownerOutOfState, setOwnerOutOfState] = useState<boolean>();
  const [ownerOccupied, setOwnerOccupied] = useState<boolean>();
  const [isRealtor, setIsRealtor] = useState<SEND_ADDRESS>(SEND_ADDRESS.SEND_ADDRESSES_ALL);

  useEffect(() => {
    if (
      !addressesFoundCount?.params ||
      (addressesFoundCount?.params && !Object.keys(addressesFoundCount?.params).length)
    )
      return;
    if (
      typeof addressFoundCountCachedRef.current === 'undefined' ||
      !isEqual(addressFoundCountCachedRef.current, addressesFoundCount)
    ) {
      addressFoundCountCachedRef.current = addressesFoundCount;
    }
    handleSetDefaultFilter(addressesFoundCount);

    const convertedMailings = loadDefaultAddressFoundCount(addressesFoundCount);
    setMailings(convertedMailings);

    currentParamsRef.current = addressesFoundCount.params;
  }, [addressesFoundCount]);

  const debouncedUpdateDataSource = debounce(() => {
    const data = getDataSource(mailings);
    setDataSources(data);
  }, 300);

  useEffect(() => {
    debouncedUpdateDataSource();
  }, [mailings, isRealtor, filterState, ownerOutOfState, ownerOccupied, textSearch]);

  const getDataSource = (mailings: MailingExtra[]) => {
    let data = [...mailings];

    if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_REALTOR) {
      data = data.filter((v) => v.is_realtor);
    } else if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_NOT_REALTOR) {
      data = data.filter((v) => !v.is_realtor);
    }

    if (filterState === VIEW_MODE.VIEW_MODE_ON) {
      data = data.filter((v) => v.selectedToSend);
    } else if (filterState === VIEW_MODE.VIEW_MODE_OFF) {
      data = data.filter((v) => !v.selectedToSend);
    }

    if (ownerOutOfState === true) {
      data = data.filter((v) => v.owner_out_of_state);
    } else if (ownerOutOfState === false) {
      data = data.filter((v) => !v.owner_out_of_state);
    }

    if (ownerOccupied === true) {
      data = data.filter((v) => v.owner_occupied);
    } else if (ownerOccupied === false) {
      data = data.filter((v) => !v.owner_occupied);
    }

    // data = data.filter((v) => !v.isDuplicated);
    if (textSearch?.length) {
      data = filterSearch(data, textSearch);
    }

    if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_OWNER) {
      data = data.map((v) => ({
        ...v,
        addressType: OwnerAddressType.OWNER,
        is_send_owner_address: true,
      }));
    } else if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_RENTER) {
      data = data.map((v) => ({
        ...v,
        addressType: OwnerAddressType.RENTER,
        is_send_owner_address: false,
      }));
    }

    return data;
  };

  const updateMailingsDataViaDataSource = (mailings: MailingExtra[]) => {
    let newMailings: MailingExtra[] = [...mailings];
    if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_OWNER) {
      const ids = dataSources.filter(filterRealtorState).map((v) => v.id);
      newMailings = newMailings.map((v) => {
        if (ids.includes(v.id)) {
          return {
            ...v,
            addressType: OwnerAddressType.OWNER,
            is_send_owner_address: true,
          };
        }
        return {
          ...v,
          selectedToSend: false,
          is_available_for_campaign: false,
        };
      });
    } else if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_RENTER) {
      const ids = dataSources.filter(filterRealtorState).map((v) => v.id);
      newMailings = newMailings.map((v) => {
        if (ids.includes(v.id)) {
          return {
            ...v,
            addressType: OwnerAddressType.RENTER,
            is_send_owner_address: false,
          };
        }
        return {
          ...v,
          selectedToSend: false,
          is_available_for_campaign: false,
        };
      });
    }
    return newMailings;
  };

  const handleCancel = async ({ resetData = false }) => {
    if (resetData) {
      setTimeout(() => resetAddressCountLocalData(), 20);
    }

    setTimeout(
      () =>
        dispatch({
          type: MailingAction.OPEN_MAILING_ADDRESS_POPUP,
          payload: false,
        }),
      30,
    );

    onCancel && onCancel();
  };

  const handleToggleAddress = (address: AddressSelect) => {
    const index = mailings.findIndex((v) => v.id === address.id);
    if (index < 0) return;
    setMailings((prev) => {
      const newCheckedList = prev;
      if (typeof address.selectedToSend !== 'undefined') {
        newCheckedList[index].selectedToSend = address.selectedToSend;
        newCheckedList[index].is_available_for_campaign = address.selectedToSend;
      }
      if (typeof address.addressType !== 'undefined') {
        newCheckedList[index].addressType = address.addressType;
        newCheckedList[index].is_send_owner_address = address.addressType === OwnerAddressType.OWNER;
      }
      return [...newCheckedList];
    });
  };

  const handleSelectAll = () => {
    setMailings((prev) => {
      const duplicates = getDuplicatedHouses(prev);
      const newMailings = prev.map((v) => {
        const realtor =
          isRealtor === SEND_ADDRESS.SEND_ADDRESSES_REALTOR
            ? true
            : isRealtor === SEND_ADDRESS.SEND_ADDRESSES_NOT_REALTOR
            ? false
            : undefined;
        const isDuplicated = duplicates.includes(v.id);
        if ((typeof realtor === 'boolean' && v.is_realtor === realtor) || typeof realtor === 'undefined') {
          return {
            ...v,
            selectedToSend: isDuplicated ? false : true,
            is_available_for_campaign: isDuplicated ? false : true,
          };
        }

        return {
          ...v,
        };
      });
      return newMailings;
    });
  };

  const handleDeselectAll = () => {
    setMailings((prev) => {
      const newMailings = prev.map((v) => {
        const realtor =
          isRealtor === SEND_ADDRESS.SEND_ADDRESSES_REALTOR
            ? true
            : isRealtor === SEND_ADDRESS.SEND_ADDRESSES_NOT_REALTOR
            ? false
            : undefined;

        if ((typeof realtor === 'boolean' && v.is_realtor === realtor) || typeof realtor === 'undefined') {
          return {
            ...v,
            selectedToSend: false,
            is_available_for_campaign: false,
          };
        }

        return {
          ...v,
        };
      });
      return newMailings;
    });
  };

  const handleSetDefaultFilter = (data: AddressesFoundCountType) => {
    // Set realtor
    if (data?.params?.isRealtor === null || data?.params?.isRealtor === undefined) {
      setIsRealtor(SEND_ADDRESS.SEND_ADDRESSES_ALL);
    } else {
      setIsRealtor(data?.params?.isRealtor);
    }

    // Set default filters
    setTextSearch(data?.params?.textSearch || '');
    setFilterState(data?.params?.filterState || VIEW_MODE.VIEW_MODE_ALL);
    setOwnerOutOfState(data?.params?.ownerOutOfState);
    setOwnerOccupied(data?.params?.ownerOccupied);
  };

  const loadDefaultAddressFoundCount = (data: AddressesFoundCountType) => {
    if (!data) return [];

    // Set mailings
    const send_to_owner: string[] = (data?.params as AddressesSearchGeneralParams)?.send_to_owner || [];
    const exclude_mailings: string[] = (data?.params as AddressesSearchGeneralParams)?.exclude_mailings || [];

    const convertedMailings = data.mailings.map((v) => ({
      ...v,
      addressType:
        mode === ShowAddressesMode.CAMPAIGN_BUILDER || isCreateMLSCase
          ? v.is_send_owner_address
            ? OwnerAddressType.OWNER
            : OwnerAddressType.RENTER
          : send_to_owner.length > 0
          ? send_to_owner.includes(v.id)
            ? OwnerAddressType.OWNER
            : OwnerAddressType.RENTER
          : OwnerAddressType.RENTER,
      is_send_owner_address:
        mode === ShowAddressesMode.CAMPAIGN_BUILDER || isCreateMLSCase
          ? v.is_send_owner_address
          : send_to_owner.length > 0
          ? send_to_owner.includes(v.id)
          : false,
      selectedToSend:
        mode === ShowAddressesMode.CAMPAIGN_BUILDER || isCreateMLSCase
          ? v.is_available_for_campaign
          : exclude_mailings.length > 0
          ? !exclude_mailings.includes(v.id)
          : true,
      is_available_for_campaign:
        mode === ShowAddressesMode.CAMPAIGN_BUILDER || isCreateMLSCase
          ? v.is_available_for_campaign
          : exclude_mailings.length > 0
          ? !exclude_mailings.includes(v.id)
          : true,
    })) as MailingExtra[];
    return convertedMailings;
  };

  const resetAddressCountLocalData = () => {
    if (!Object.keys(addressesFoundCount?.params as any).length || !addressFoundCountCachedRef?.current) return;
    if (mode === ShowAddressesMode.CAMPAIGN_BUILDER) {
      handleSetDefaultFilter(addressFoundCountCachedRef?.current);
      const convertedMailings = loadDefaultAddressFoundCount(addressFoundCountCachedRef?.current);
      setMailings(convertedMailings);
      dispatch({
        type: MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN,
        payload: {
          ...addressesFoundCount,
          mailings: convertedMailings,
        },
      });
    } else {
      if (!addressFoundCountCachedRef?.current) return;
      handleSetDefaultFilter(addressFoundCountCachedRef?.current);
      const convertedMailings = loadDefaultAddressFoundCount(addressFoundCountCachedRef?.current);
      setMailings(convertedMailings);
      dispatch({
        type: MailingAction.SET_ADDRESSES_COUNT_MLS,
        payload: {
          ...addressesFoundCount,
          mailings: convertedMailings,
        },
      });
    }
  };

  const updateViewAddressMLS = async ({ sentList, exclude_mailings, send_to_owner }) => {
    if (isEditingMLSPopup) return;
    const send_to = isRealtor;
    const view_mode = filterState;
    const view_owner_out_of_state =
      typeof ownerOutOfState === 'undefined'
        ? VIEW_OWNER_OUT_OF_STATE.OWNER_OUT_OF_STATE_ALL
        : ownerOutOfState
        ? VIEW_OWNER_OUT_OF_STATE.OWNER_OUT_OF_STATE_OUT
        : VIEW_OWNER_OUT_OF_STATE.OWNER_OUT_OF_STATE_IN;
    const view_owner_occupied =
      typeof ownerOccupied === 'undefined'
        ? VIEW_OWNER_OCCUPIED.VIEW_OWNER_OCCUPIED_ALL
        : ownerOccupied
        ? VIEW_OWNER_OCCUPIED.VIEW_OWNER_OCCUPIED
        : VIEW_OWNER_OCCUPIED.VIEW_OWNER_OCCUPIED_NOT;
    const search = textSearch;

    try {
      const params = addressesFoundCount?.params as AddressesSearchMLSParams;
      if (!params.agent_id) {
        return;
      }
      await SmartActionService.updateSmartTriggerAction(
        params.agent_id,
        (addressesFoundCount?.params as AddressesSearchMLSParams)?.smart_trigger_action_id || '',
        {
          exclude_mailings,
          send_to_owner,
          send_to,
          view_mode,
          view_owner_out_of_state,
          view_owner_occupied,
          search,
          send_to_mailings: sentList.map((v) => v.id),
          send_to_mailings_total: homeSelectToSend,
        },
      );
    } catch (error) {
      // Do nothing
    } finally {
      dispatch({
        type: MailingAction.RESET_ADDRESSES_COUNT_MLS,
      });
    }
  };

  const handleUpdateMLSAddresses = async () => {
    const { sentList, convertedMailings } = excludeMailingNotShowInScreen();

    const exclude_mailings = convertedMailings.filter((v) => !v.is_available_for_campaign).map((v) => v.id);
    const send_to_owner = convertedMailings.filter((v) => v.is_send_owner_address).map((v) => v.id);
    const params = {
      ...currentParamsRef.current,
      isRealtor,
      filterState,
      textSearch,
      ownerOutOfState,
      ownerOccupied,
    } as AddressesSearchMLSParams;

    // Only for Create/Edit MLS to save which item that showing on the screen
    params.send_to_mailings = sentList.map((v) => v.id);
    const payload = {
      count: addressesFoundCount?.count,
      updatedCount: sentList.length,
      params,
      mailings: convertedMailings,
    } as AddressesFoundCountType;
    payload.params = {
      ...payload.params,
      exclude_mailings,
      send_to_owner,
    };

    addressFoundCountCachedRef.current = payload;
    dispatch({
      type: MailingAction.SET_ADDRESSES_COUNT_MLS,
      payload,
    });

    // Not save when editing, only save when clicking on View Addresses
    if (isViewAddresses) {
      updateViewAddressMLS({ sentList, exclude_mailings, send_to_owner });
    }
  };

  const filterRealtorState = (v: MailingExtra) => {
    if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_REALTOR) {
      return v.selectedToSend && v.is_realtor;
    }
    if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_NOT_REALTOR) {
      return v.selectedToSend && !v.is_realtor;
    }
    return v.selectedToSend;
  };

  const excludeMailingNotShowInScreen = () => {
    let convertedMailings = updateMailingsDataViaDataSource(uniqBy(mailings, (v) => v.id));

    const sentList = dataSources.filter(filterRealtorState);

    // Excluded all home not show in current screen
    const sendListIds = sentList.map((v) => v.id);
    convertedMailings = convertedMailings.map((v) => {
      let item = { ...v };

      if (!sendListIds.includes(v.id)) {
        item = {
          ...item,
          is_available_for_campaign: false,
          selectedToSend: false,
        };
      }

      if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_OWNER) {
        item = {
          ...item,
          is_send_owner_address: true,
          addressType: OwnerAddressType.OWNER,
        };
      }

      if (isRealtor === SEND_ADDRESS.SEND_ADDRESSES_RENTER) {
        item = {
          ...item,
          is_send_owner_address: false,
          addressType: OwnerAddressType.RENTER,
        };
      }

      return item;
    });
    return {
      convertedMailings,
      sendListIds,
      sentList,
    };
  };

  const handleCreateCampaign = () => {
    const { sentList, convertedMailings } = excludeMailingNotShowInScreen();

    const payload = {
      count: addressesFoundCount?.count,
      updatedCount: sentList.length,
      params: { ...currentParamsRef.current, isRealtor, filterState, textSearch, ownerOutOfState, ownerOccupied },
      mailings: convertedMailings,
    } as AddressesFoundCountType;

    addressFoundCountCachedRef.current = payload;
    dispatch({
      type: MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN,
      payload,
    });
  };

  const handleUpdateAddressWillSend = () => {
    if (onResetOutsideFilter) onResetOutsideFilter();
    if (mode === ShowAddressesMode.CAMPAIGN_BUILDER) {
      handleCreateCampaign();
      handleCancel({});
    } else {
      if ((addressesFoundCount?.params as AddressesSearchMLSParams)?.smart_trigger_action_id) {
        handleUpdateMLSAddresses();
        handleCancel({});
      } else {
        handleCreateMLSAddresses();
        handleCancel({});
      }
    }
  };

  const handleCreateMLSAddresses = () => {
    const { sentList, convertedMailings } = excludeMailingNotShowInScreen();
    const params = {
      ...currentParamsRef.current,
      isRealtor,
      filterState,
      textSearch,
      ownerOutOfState,
      ownerOccupied,
    } as AddressesSearchMLSParams;
    // Only for Create/Edit MLS to save which item that showing on the screen
    params.send_to_mailings = sentList.map((v) => v.id);

    const payload = {
      count: addressesFoundCount?.count,
      updatedCount: sentList.length,
      params,
      mailings: convertedMailings,
    } as AddressesFoundCountType;

    addressFoundCountCachedRef.current = payload;
    dispatch({
      type: MailingAction.SET_ADDRESSES_COUNT_MLS,
      payload,
    });
  };

  const homeSelectToSend = useMemo(() => dataSources.filter((f) => f.selectedToSend).length, [dataSources]);

  const rowCountOnScreen = useMemo(() => Math.ceil(dataSources.length / 3), [dataSources.length]);

  const isAllDataSourceDuplicatedAndOff = useMemo(
    () => filterState === VIEW_MODE.VIEW_MODE_OFF && !dataSources.filter((v) => !v.is_available_for_campaign).length,
    [filterState, dataSources],
  );

  const isEmptyList = useMemo(
    () => isAllDataSourceDuplicatedAndOff || !dataSources.length,
    [isAllDataSourceDuplicatedAndOff, dataSources.length],
  );

  return (
    <MailingAddressSentFormsView
      uniqID={uniqID}
      isOpenAddressPopup={isOpenAddressPopup}
      handleCancel={handleCancel}
      isEmptyList={isEmptyList}
      homeSelectToSend={homeSelectToSend}
      mailings={mailings}
      setIsRealtor={setIsRealtor}
      isRealtor={isRealtor}
      setTextSearch={setTextSearch}
      textSearch={textSearch}
      filterState={filterState}
      setFilterState={setFilterState}
      setOwnerOutOfState={setOwnerOutOfState}
      ownerOutOfState={ownerOutOfState}
      setOwnerOccupied={setOwnerOccupied}
      ownerOccupied={ownerOccupied}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      addressesFoundCountLoading={addressesFoundCountLoading}
      rowCountOnScreen={rowCountOnScreen}
      dataSources={dataSources}
      handleToggleAddress={handleToggleAddress}
      handleUpdateAddressWillSend={handleUpdateAddressWillSend}
    />
  );
};
export default MailingAddressSentFormsContainer;
