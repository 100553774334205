import React, { Dispatch, FC, memo, SetStateAction } from 'react';
import BrokerConfigForm from './broker-config-form';
import { ModalBrokerConfigurationProps } from './ModalBrokerConfigurationContainer';
import { ModalBrokerConfigurationStyled } from './ModalBrokerConfigurationStyled';

interface Props extends ModalBrokerConfigurationProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const ModalBrokerConfigurationView: FC<Props> = (props) => {
  const { children, onUpdateBrokerConfiguration, brokerData, setVisible, visible } = props;
  return (
    <>
      <ModalBrokerConfigurationStyled
        centered
        visible={visible}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        closable={true}
        footer={null}
        width={522}
      >
        <div className="app-modal-body">
          <BrokerConfigForm
            onCancel={() => setVisible(false)}
            {...{ onUpdateBrokerConfiguration, visible, brokerData }}
          />
        </div>
      </ModalBrokerConfigurationStyled>
      <div onClick={() => setVisible(true)}>{children}</div>
    </>
  );
};

export default memo(ModalBrokerConfigurationView);
