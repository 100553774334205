import styled from '@emotion/styled';

const BrokerLandingPageBuilderStyled = styled.div<{ isEditMode: boolean; background?: string }>`
  .broker-content-wrapper {
    height: 1080px;
    width: ${({ isEditMode }) => (isEditMode ? '1920px' : 'initial')};
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: ${({ background }) => (background ? `url(${background})` : 'none')};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  textarea {
    word-break: break-all;
  }

  textarea::placeholder {
    color: #fff; /* Set the desired text color */
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background: transparent;
    &:hover {
      background: transparent;
    }
  }

  .broker_bg {
    position: absolute;
    img {
      user-select: none;
    }

    &.img_BrokerLPIntroTop {
      top: 25px; // 0.5
      right: -53px; // 0.5
      width: 1203.75px; // 0.75
      height: 112.5px; // 0.75
      z-index: 2;
    }
    &.img_BrokerLPIntroBottom {
      bottom: 25px; // 0.5
      left: 0;
      width: 1101px; // 0.75
      height: 103.5px; // 0.75
      z-index: 2;
    }
    &.img_BrokerLPIntroTopLeft {
      top: -100px; // 0.5
      left: -100px; // 0.5
      width: 368px; // 0.5
      height: 311px; // 0.5
      z-index: 2;
    }
    &.img_BrokerLPIntroBottomRight {
      bottom: -100px; // 0.5
      right: -100px; // 0.5
      width: 368px; // 0.5
      height: 311px; // 0.5
      z-index: 2;
    }

    @media only screen and (min-width: 1620px) {
      &.img_BrokerLPIntroTop {
        top: 50px;
        right: -106px;
        width: 1605px;
        height: 150px;
        z-index: 2;
      }
      &.img_BrokerLPIntroBottom {
        bottom: 50px;
        left: 0;
        width: 1468px;
        height: 138px;
        z-index: 2;
      }
      &.img_BrokerLPIntroTopLeft {
        top: -200px;
        left: -200px;
        width: 736px;
        height: 622px;
        z-index: 2;
      }
      &.img_BrokerLPIntroBottomRight {
        bottom: -200px;
        right: -200px;
        width: 736px;
        height: 622px;
        z-index: 2;
      }
    }
  }
  & .broker-form-content {
    position: relative;
    width: 1054px;
    height: 620px;
    background: #2e0249;
    border-radius: 15px;
    margin: 16px;
    padding: 75px 79px;
    padding-top: 100px;
    font-family: FiraSans;
    .error {
      position: absolute;
      bottom: -22px;
      font-size: 16px;
      font-weight: 700;
    }

    img.logo {
      max-width: 282.76px;
      position: absolute;
      right: 52.1px;
      top: 42.5px;
    }
    .pink-text {
      color: ${({ isEditMode }) => (isEditMode ? 'transparent' : '#ff00dd')};
    }

    .titleText {
      font-size: 109px;
      font-weight: 700;
      line-height: 92px;
      letter-spacing: -2.18px;
      text-align: left;
      color: ${({ isEditMode }) => (isEditMode ? 'transparent' : '#fff')};
      text-transform: uppercase;
      margin-top: 30px;
      margin-bottom: 30px;
      height: 208px;
      width: 896px;
    }
    .descriptionText {
      font-size: 52px;
      font-weight: 700;
      line-height: 92px;
      letter-spacing: -2px;
      text-align: left;
      color: ${({ isEditMode }) => (isEditMode ? 'transparent' : '#fff')};
      height: 94px;
      width: 896px;
    }
  }
  & .action-list {
    display: flex;
    align-items: center;
    gap: 35px;
    font-family: Montserrat;
    margin-top: 60px;
    .action-btn {
      height: 54px;
      letter-spacing: initial !important;
      text-transform: uppercase;
      font-weight: 600;
      background-color: #ff00dd;
      padding: 4px 15px !important;
      line-height: 43px;
      width: 100%;
      font-size: 16px;
      font-family: Montserrat !important;
      text-align: center;
    }
  }

  .info-group {
    position: relative;
    width: 100%;
    &.see-home-free {
      width: 100%;
      max-width: 322px;
    }
    .text-field {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      color: #fff !important;
      background: transparent;
      padding: 0;
      font-family: FiraSans;
      resize: none;
      border-color: #505050;
      box-shadow: none;
      z-index: 2;
      overflow: hidden;
      &.action-btn {
        background-color: #ff00dd;
      }
    }
  }
`;
export default BrokerLandingPageBuilderStyled;
