import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import BrokerLandingPageTemplateView from '@/components/BrokerLandingPageBuilder/BrokerLandingPageTemplateView';
import { findDifferenceIndex, removeCharAtIndex } from '@/utils/utils';
import { BrokerLandingPageVariables } from '../custom-html-editor/hooks/useFetchVariablesByType';

export interface BrokerLPTemplateFormData {
  titleText?: string;
  descriptionText?: string;
  freeButtonLabelText?: string;
  autoSendCampaignLabelText?: string;
}

interface BrokerLandingPageTemplateProps {
  isEditMode?: boolean;
  titleText?: string;
  descriptionText?: string;
  freeButtonLabelText?: string;
  autoSendCampaignLabelText?: string;
  background?: string;
  homeCreditVar?: string;
  minHomesVar?: string;
  campaignPriceVar?: string;
  errors?: any;
  touched?: any;
  disabledAutoSendCampaign?: boolean;
  handleSeeMyHomeForFree?: () => void;
  handleAutomaticallySendACampaign?: () => void;
  handleTemplateChangeValue?: (values: BrokerLPTemplateFormData) => void;
}

const BrokerLandingPageTemplate: React.FC<BrokerLandingPageTemplateProps> = memo(
  ({
    isEditMode = false,
    titleText,
    descriptionText,
    freeButtonLabelText,
    autoSendCampaignLabelText,
    background,
    homeCreditVar,
    minHomesVar,
    campaignPriceVar,
    errors,
    touched,
    disabledAutoSendCampaign,
    handleSeeMyHomeForFree,
    handleAutomaticallySendACampaign,
    handleTemplateChangeValue,
  }) => {
    const [brokerLPData, setBrokerLPData] = useState<BrokerLPTemplateFormData>({
      titleText,
      descriptionText,
      freeButtonLabelText,
      autoSendCampaignLabelText,
    });

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setBrokerLPData((prev) => ({
        ...prev,
        [e.target.name]: e.target?.value,
      }));

      if (handleTemplateChangeValue) {
        handleTemplateChangeValue({
          ...brokerLPData,
          [e.target.name]: e.target?.value,
        });
      }
    };
    const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      const inputValue = brokerLPData[e.target.name];
      const index = findDifferenceIndex(newValue, inputValue);
      let i = 0;
      if (index === inputValue?.length) {
        while (e?.target?.clientHeight < e?.target?.scrollHeight) {
          e.target.value = e?.target?.value?.substr(0, e.target.value.length - 1);
        }
      } else {
        while (i < 30 && e?.target?.clientHeight < e?.target?.scrollHeight) {
          e.target.value = removeCharAtIndex(`${newValue || ''}`, index);
          i++;
        }
      }
    };

    const renderTitleHtml = useMemo(() => {
      if (isEditMode || !brokerLPData?.titleText) {
        return brokerLPData?.titleText || '';
      }

      return brokerLPData.titleText
        .replaceAll(BrokerLandingPageVariables.HomeCredit, `<span class="pink-text">${homeCreditVar || 'N/A'} </span>`)
        .replaceAll(BrokerLandingPageVariables.MinHomes, `<span class="pink-text">${minHomesVar || 'N/A'} </span>`)
        .replaceAll(BrokerLandingPageVariables.CampaignPrice, campaignPriceVar || 'N/A');
    }, [isEditMode, brokerLPData?.titleText, homeCreditVar, minHomesVar, campaignPriceVar]);

    const renderDescriptionHtml = useMemo(() => {
      if (isEditMode || !brokerLPData?.descriptionText) {
        return brokerLPData?.descriptionText || '';
      }

      return brokerLPData.descriptionText
        .replaceAll(BrokerLandingPageVariables.MinHomes, `<span class="pink-text">${minHomesVar || 'N/A'} </span>`)
        .replaceAll(BrokerLandingPageVariables.HomeCredit, `<span class="pink-text">${homeCreditVar || 'N/A'} </span>`)
        .replaceAll(BrokerLandingPageVariables.CampaignPrice, campaignPriceVar || 'N/A');
    }, [isEditMode, brokerLPData?.descriptionText, homeCreditVar, minHomesVar, campaignPriceVar]);

    const hasError = (key: string) => {
      return errors && Object.keys(errors).length > 0 && !!errors[key] && touched[key];
    };

    useEffect(() => {
      setBrokerLPData((prev) => ({
        ...prev,
        titleText,
        descriptionText,
        freeButtonLabelText,
        autoSendCampaignLabelText,
      }));
    }, [titleText, descriptionText, freeButtonLabelText, autoSendCampaignLabelText]);

    return (
      <BrokerLandingPageTemplateView
        background={background}
        brokerLPData={brokerLPData}
        freeButtonLabelText={freeButtonLabelText}
        handleChange={handleChange}
        handleInput={handleInput}
        hasError={hasError}
        isEditMode={isEditMode}
        renderDescriptionHtml={renderDescriptionHtml}
        renderTitleHtml={renderTitleHtml}
        autoSendCampaignLabelText={autoSendCampaignLabelText}
        campaignPriceVar={campaignPriceVar}
        disabledAutoSendCampaign={disabledAutoSendCampaign}
        errors={errors}
        handleAutomaticallySendACampaign={handleAutomaticallySendACampaign}
        handleSeeMyHomeForFree={handleSeeMyHomeForFree}
      />
    );
  },
);

export default BrokerLandingPageTemplate;
