import { css } from '@emotion/react';
import styled from '@emotion/styled';

const HorizontalLayoutViewStyled = {
  Container: styled.div<{ removeFixedLayout?: boolean }>`
    width: 100%;
    height: 100%;
    ${({ removeFixedLayout }) =>
      !removeFixedLayout
        ? css`
            position: fixed;
            top: 0;
            left: 0;
            overflow: auto;
          `
        : ''}
  `,
  SidebarContainer: styled.div`
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
    align-items: flex-start;

    @media (max-width: ${({ theme }) => theme.media.desktops}px) {
      flex-direction: column;
    }

    > .tabs-content {
      width: 100%;
      position: relative;
      display: block;
      overflow: hidden;
    }
  `,
};

export default HorizontalLayoutViewStyled;
