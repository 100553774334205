import React from 'react';
import GoogleMap from 'google-map-react';
import globalVariable from '@/config/env';

const MyGoogleMapComponent = ({ handleGoogleMapApi, options = {} }) => {
  const defaultLatLng = { lat: 33.6179896, lng: -117.9406061 }; //Newport Beach lat lng
  const libraries = 'drawing,geometry,places';
  const bootstrapURLKeys = { libraries: libraries, key: globalVariable.GOOGLE_MAP_KEY };

  return (
    <GoogleMap
      defaultCenter={defaultLatLng}
      defaultZoom={12}
      options={options}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={handleGoogleMapApi}
      bootstrapURLKeys={bootstrapURLKeys}
    />
  );
};

export default MyGoogleMapComponent;
