import AppSelect from '@/components/select';
import SearchField from '@/components/setup-manager/search-field';
import { CaretDownOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { SEND_ADDRESS, VIEW_MODE } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { Empty } from 'antd';
import classNames from 'classnames';
import { times } from 'lodash';
import React, { memo } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import AddressItem from './AddressItem';
import AddressItemSkeleton from './AddressItemSkeleton';
import { MailingAddressSentFormsStyled } from './MailingAddressSentFormsStyled';
import { AddressSelect } from './types';
import AppButtonV2 from '@/components/AppButtonV2';
import { color } from '@/styles/theme';

interface MailingAddressSentFormsViewProps {
  uniqID?: string;
  isOpenAddressPopup: boolean | undefined;
  handleCancel: ({ resetData }: { resetData?: boolean | undefined }) => Promise<void>;
  isEmptyList: boolean;
  homeSelectToSend: number;
  mailings: MailingExtra[];
  setIsRealtor: React.Dispatch<React.SetStateAction<SEND_ADDRESS>>;
  isRealtor: SEND_ADDRESS;
  setTextSearch: React.Dispatch<React.SetStateAction<string>>;
  textSearch: string;
  filterState: VIEW_MODE;
  setFilterState: React.Dispatch<React.SetStateAction<VIEW_MODE>>;
  setOwnerOutOfState: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  ownerOutOfState: boolean | undefined;
  setOwnerOccupied: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  ownerOccupied: boolean | undefined;
  handleSelectAll: () => void;
  handleDeselectAll: () => void;
  addressesFoundCountLoading: boolean | undefined;
  rowCountOnScreen: number;
  dataSources: MailingExtra[];
  handleToggleAddress: (address: AddressSelect) => void;
  handleUpdateAddressWillSend: () => void;
}

const realtorsFilterValue = [
  {
    label: 'All',
    value: SEND_ADDRESS.SEND_ADDRESSES_ALL,
  },
  {
    label: 'Realtor',
    value: SEND_ADDRESS.SEND_ADDRESSES_REALTOR,
  },
  {
    label: 'No Realtors',
    value: SEND_ADDRESS.SEND_ADDRESSES_NOT_REALTOR,
  },
  {
    label: 'Owner',
    value: SEND_ADDRESS.SEND_ADDRESSES_OWNER,
  },
  {
    label: 'Renter',
    value: SEND_ADDRESS.SEND_ADDRESSES_RENTER,
  },
];

const GUTTER_SIZE = 15;
const COLUMN_WIDTH = 372;
const ROW_HEIGHT = 185;

const ownerOccupiedFilter = ['All Absentee & Occupied', 'Owners Only', 'Absentee Only'];
const ownerOutOfStateFilter = ['All In & Out of State', 'Owner Out Of State', 'Owner In State'];

const MailingAddressSentFormsView: React.FunctionComponent<MailingAddressSentFormsViewProps> = memo(
  ({
    uniqID,
    isOpenAddressPopup,
    handleCancel,
    isEmptyList,
    homeSelectToSend,
    mailings,
    setIsRealtor,
    isRealtor,
    setTextSearch,
    textSearch,
    filterState,
    setFilterState,
    setOwnerOutOfState,
    ownerOutOfState,
    setOwnerOccupied,
    ownerOccupied,
    handleSelectAll,
    handleDeselectAll,
    addressesFoundCountLoading,
    rowCountOnScreen,
    dataSources,
    handleToggleAddress,
    handleUpdateAddressWillSend,
  }) => {
    return (
      <MailingAddressSentFormsStyled.ModalWrapper
        style={{ top: 0 }}
        visible={!!isOpenAddressPopup}
        closable={true}
        footer={null}
        onCancel={() => {
          handleCancel({ resetData: true });
        }}
      >
        <MailingAddressSentFormsStyled.ModalBody className="app-modal-body">
          <>
            <MailingAddressSentFormsStyled.FormItem isEmptyList={isEmptyList} className="form-item">
              <p className="personal-information">Mailing List</p>
              <p className="home-count">Total Home Count: {homeSelectToSend}</p>
              <div className="realtor-filter">
                <span
                  css={css`
                    font-weight: 700;
                  `}
                >
                  Send to
                </span>
                <MailingAddressSentFormsStyled.SenTo>
                  <div className="filter-dropdown" id={'realtor-owner-addresses ' + uniqID}>
                    <AppSelect
                      className="field item-field fix-w"
                      name="owner_occupied"
                      placeholder="Send to Realtors/No Realtors"
                      type="string"
                      itemSelect={realtorsFilterValue}
                      handleChange={(value) => {
                        mailings.forEach((_v, index) => {
                          if (!mailings[index].isDuplicated) {
                            mailings[index].selectedToSend = true;
                            mailings[index].is_available_for_campaign = true;
                          }
                          if (index === mailings.length - 1) setIsRealtor(value);
                        });
                      }}
                      labelKey="label"
                      valueKey="value"
                      value={isRealtor}
                      hasError={undefined}
                      suffixIcon={<CaretDownOutlined className="icon" />}
                      isGetContainer
                      idParent={'realtor-owner-addresses ' + uniqID}
                    />
                  </div>
                </MailingAddressSentFormsStyled.SenTo>
              </div>
              <div className="filter-section">
                <MailingAddressSentFormsStyled.FilterSection>
                  <SearchField
                    isSearchByInput={true}
                    getSearchValue={setTextSearch}
                    placeholder="Search for Address and Name"
                    searchValue={textSearch}
                  />
                </MailingAddressSentFormsStyled.FilterSection>
                <MailingAddressSentFormsStyled.Btn>
                  <AppButtonV2
                    color={filterState === VIEW_MODE.VIEW_MODE_ALL ? 'Pin' : 'Outline'}
                    outlineColor={color.WHITE}
                    size="small"
                    uppercase={false}
                    className={classNames({
                      active: filterState === VIEW_MODE.VIEW_MODE_ALL,
                    })}
                    onClick={() => setFilterState(VIEW_MODE.VIEW_MODE_ALL)}
                  >
                    View All
                  </AppButtonV2>
                  <AppButtonV2
                    color={filterState === VIEW_MODE.VIEW_MODE_ON ? 'Pin' : 'Outline'}
                    outlineColor={color.WHITE}
                    size="small"
                    uppercase={false}
                    className={classNames({
                      active: filterState === VIEW_MODE.VIEW_MODE_ON,
                    })}
                    onClick={() => setFilterState(VIEW_MODE.VIEW_MODE_ON)}
                  >
                    On
                  </AppButtonV2>
                  <AppButtonV2
                    color={filterState === VIEW_MODE.VIEW_MODE_OFF ? 'Pin' : 'Outline'}
                    outlineColor={color.WHITE}
                    size="small"
                    uppercase={false}
                    className={classNames({
                      active: filterState === VIEW_MODE.VIEW_MODE_OFF,
                    })}
                    onClick={() => setFilterState(VIEW_MODE.VIEW_MODE_OFF)}
                  >
                    Off
                  </AppButtonV2>
                  <div className="filter-dropdown" id={'owner-out-of-state-addresses ' + uniqID}>
                    <AppSelect
                      className="field item-field fix-w"
                      name="owner_out_of_state"
                      placeholder="Owner Out Of State"
                      type="string"
                      itemSelect={ownerOutOfStateFilter}
                      handleChange={(value) => {
                        mailings.forEach((_v, index) => {
                          if (!mailings[index].isDuplicated) {
                            mailings[index].selectedToSend = true;
                            mailings[index].is_available_for_campaign = true;
                          }
                          if (index === mailings.length - 1) {
                            if (value === ownerOutOfStateFilter[0]) {
                              setOwnerOutOfState(undefined);
                            } else if (value === ownerOutOfStateFilter[1]) {
                              setOwnerOutOfState(true);
                            } else {
                              setOwnerOutOfState(false);
                            }
                          }
                        });
                      }}
                      value={
                        typeof ownerOutOfState === 'undefined'
                          ? ownerOutOfStateFilter[0]
                          : ownerOutOfState
                          ? ownerOutOfStateFilter[1]
                          : ownerOutOfStateFilter[2]
                      }
                      hasError={undefined}
                      suffixIcon={<CaretDownOutlined className="icon" />}
                      isGetContainer
                      idParent={'owner-out-of-state-addresses ' + uniqID}
                    />
                  </div>
                  <div className="filter-dropdown" id={'owner-occupied-addresses ' + uniqID}>
                    <AppSelect
                      className="field item-field fix-w"
                      name="owner_occupied"
                      placeholder="Owners Only"
                      type="string"
                      itemSelect={ownerOccupiedFilter}
                      handleChange={(value) => {
                        mailings.forEach((_v, index) => {
                          if (!mailings[index].isDuplicated) {
                            mailings[index].selectedToSend = true;
                            mailings[index].is_available_for_campaign = true;
                          }
                          if (index === mailings.length - 1) {
                            if (value === ownerOccupiedFilter[0]) {
                              setOwnerOccupied(undefined);
                            } else if (value === ownerOccupiedFilter[1]) {
                              setOwnerOccupied(true);
                            } else {
                              setOwnerOccupied(false);
                            }
                          }
                        });
                      }}
                      value={
                        typeof ownerOccupied === 'undefined'
                          ? ownerOccupiedFilter[0]
                          : ownerOccupied
                          ? ownerOccupiedFilter[1]
                          : ownerOccupiedFilter[2]
                      }
                      hasError={undefined}
                      suffixIcon={<CaretDownOutlined className="icon" />}
                      isGetContainer
                      idParent={'owner-occupied-addresses ' + uniqID}
                    />
                  </div>
                </MailingAddressSentFormsStyled.Btn>
                <MailingAddressSentFormsStyled.BtnSelectAll>
                  <AppButtonV2 size="small" color="Pin" width={145} uppercase={false} onClick={handleSelectAll}>
                    Select All
                  </AppButtonV2>
                  <AppButtonV2 size="small" color="White" width={145} uppercase={false} onClick={handleDeselectAll}>
                    Deselect All
                  </AppButtonV2>
                </MailingAddressSentFormsStyled.BtnSelectAll>
              </div>
              <div className="container-content">
                <div className="content">
                  {addressesFoundCountLoading ? (
                    times(12).map((v) => <AddressItemSkeleton key={v} />)
                  ) : isEmptyList ? (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No addresses found"
                      css={css`
                        color: #2b2b2b;
                        font-weight: 700x;
                      `}
                    />
                  ) : (
                    <Grid
                      columnCount={3}
                      columnWidth={COLUMN_WIDTH + GUTTER_SIZE}
                      height={585} // Specify the height of the visible area
                      rowCount={rowCountOnScreen} // Total number of rows based on the number of items
                      rowHeight={ROW_HEIGHT + GUTTER_SIZE}
                      width={1206} // Specify the width of the list
                      css={css`
                        padding-bottom: ${GUTTER_SIZE}px;
                      `}
                    >
                      {({ columnIndex, rowIndex, style }) => {
                        const index = rowIndex * 3 + columnIndex; // Adjust this calculation based on your data structure
                        if (index >= dataSources.length) {
                          return null;
                        }

                        const item = dataSources[index];

                        // Check if item is being loaded or not
                        const isLoading = !item;

                        if (isLoading) {
                          return (
                            <AddressItemSkeleton
                              key={`ml_skeleton_${index}`}
                              style={{
                                height: ROW_HEIGHT - GUTTER_SIZE,
                                width: COLUMN_WIDTH - GUTTER_SIZE,
                              }}
                            />
                          );
                        }
                        return (
                          <AddressItem
                            key={index}
                            uniqID={uniqID}
                            filterState={filterState}
                            isAllowUpdateOwnerDropdown={
                              ![SEND_ADDRESS.SEND_ADDRESSES_OWNER, SEND_ADDRESS.SEND_ADDRESSES_RENTER].includes(
                                isRealtor,
                              )
                            }
                            mailing={item}
                            onToggle={(address: AddressSelect) => handleToggleAddress(address)}
                            style={{
                              ...style,
                              left: style.left + GUTTER_SIZE,
                              top: style.top + GUTTER_SIZE,
                              width: COLUMN_WIDTH - GUTTER_SIZE,
                              height: ROW_HEIGHT - GUTTER_SIZE,
                            }}
                            isDuplicated={item.isDuplicated}
                          />
                        );
                      }}
                    </Grid>
                  )}
                </div>
              </div>
            </MailingAddressSentFormsStyled.FormItem>
            <div className="btn-field">
              <AppButtonV2
                color="Pin"
                width="100%"
                size="large"
                disabled={!homeSelectToSend}
                onClick={handleUpdateAddressWillSend}
              >
                Apply
              </AppButtonV2>
            </div>
          </>
        </MailingAddressSentFormsStyled.ModalBody>
      </MailingAddressSentFormsStyled.ModalWrapper>
    );
  },
);
MailingAddressSentFormsView.displayName = 'MailingAddressSentFormsView';
export default MailingAddressSentFormsView;
