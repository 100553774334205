import { Select } from 'antd';
import React from 'react';
import { Milestone } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';
import uniqBy from 'lodash/uniqBy';
import classNames from 'classnames';
import MyGoogleMapComponent from '@/components/simple-map/MyGoogleMap';
import { SelectObjType } from '@/components/custom-modals/modal-smart-trigger/useSmTrigger';
import SelectMultipleTagsViewStyled from './SelectMultipleTagsViewStyled';
import { isEqual } from 'lodash';
import SelectV2 from '../../AppSelectV2';
import { AppSelectV2Props } from '@/components/AppSelectV2/types';

const { Option } = Select;

export interface AppSelectProps
  extends Pick<AppSelectV2Props, 'hasError' | 'errorMessage' | 'notFoundContent' | 'loadMore'> {
  showArrow?: boolean;
  requiredMark?: boolean;
  label?: string;
  className?: string;
  itemSelect?: SelectObjType[] | Milestone[];
  placeholder?: string;
  suffixIcon?: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  name: string;
  type?: string;
  disableValue?: any;
  isSearchAddress?: boolean;
  isLabelAffected?: boolean;
  placePredictions: any[];
  selectedItemList: SelectObjType[];
  value: string | string[] | null;
  inputRef: React.MutableRefObject<any>;
  noBorder?: boolean;
  filterOption?: (input: string, option: BaseOptionType | DefaultOptionType | undefined) => boolean;
  onInputRefFocus: () => void;
  handleGoogleMapApi: (el: any) => void;
  handleOnSearch: (value: string) => void;
  handleOnChange: (value: any, option: any) => void;
  handleOnBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleOnFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const SelectMultipleTagsView = (props: AppSelectProps) => {
  const {
    requiredMark,
    label,
    className,
    itemSelect,
    placeholder,
    suffixIcon,
    disableValue,
    disabled,
    isSearchAddress,
    isLabelAffected,
    placePredictions,
    selectedItemList,
    value,
    inputRef,
    filterOption,
    onInputRefFocus,
    handleGoogleMapApi,
    handleOnSearch,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    ...rest
  } = props;

  return (
    <SelectMultipleTagsViewStyled.Container
      className={className}
      isLabelAffected={isLabelAffected}
      disabled={disabled}
      onClick={onInputRefFocus}
    >
      {isSearchAddress && <MyGoogleMapComponent handleGoogleMapApi={handleGoogleMapApi} />}
      <SelectV2
        label={label}
        requiredMark={requiredMark}
        showArrow={!isSearchAddress} // must be have override by ...rest
        {...rest}
        suffixIcon={suffixIcon}
        mode="multiple"
        maxTagCount={isSearchAddress ? 'responsive' : 100}
        ref={inputRef}
        showSearch
        placeholder={placeholder}
        value={value}
        dropdownStyle={{ maxHeight: '105px', overflow: 'auto' }}
        disabled={disabled}
        filterOption={filterOption}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onSearch={handleOnSearch}
      >
        {itemSelect?.map((item) => {
          return (
            <Option
              className={classNames('option', {
                'ant-select-item-option-disabled disabled': item.disabled,
              })}
              key={item?.value}
              value={item?.value}
              disabled={disableValue ? Object.values(disableValue).includes(item?.value) : false}
            >
              {item?.label}
            </Option>
          );
        })}
        {isSearchAddress &&
          uniqBy(placePredictions?.concat(selectedItemList), (v: any) => v?.value)?.map((item) => {
            return (
              <Option className="option" key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
      </SelectV2>
    </SelectMultipleTagsViewStyled.Container>
  );
};

export default React.memo(SelectMultipleTagsView, isEqual);
