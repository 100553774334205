import { BrokerConfigFormType } from '@/models';
import React, { FC, useState } from 'react';
import ModalBrokerConfigurationView from './ModalBrokerConfigurationView';

export interface ModalBrokerConfigurationProps {
  onUpdateBrokerConfiguration: (data: BrokerConfigFormType) => void;
  brokerData: BrokerConfigFormType;
}

const ModalBrokerConfiguration: FC<ModalBrokerConfigurationProps> = (props) => {
  const [visible, setVisible] = useState(false);

  return <ModalBrokerConfigurationView {...props} visible={visible} setVisible={setVisible} />;
};

export default ModalBrokerConfiguration;
