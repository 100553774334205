import { BrokerConfigFormType } from '@/models';
import UserService from '@/services/user';
import regex from '@/utils/regularExpression';
import { joinAgentName } from '@/utils/utils';
import validation from '@/utils/validation';
import { User } from '@goldfishcode/first-team-real-estate-sdk/libs/api/user/models';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import BrokerConfigFormView from './BrokerConfigFormView';
interface BrokerConfigFormProps {
  onCancel: () => void;
  onUpdateBrokerConfiguration: (data) => void;
  visible: boolean;
  brokerData: BrokerConfigFormType;
}

export const initialValuesBroker: BrokerConfigFormType = {
  home_credit: undefined,
  max_search_homes: 150,
  minimum_homes: 150,
  select_agents: [],
  exclude_agents: [],
  select_rule: '',
  radius: 0.5,
  automation_broker_landing_page: '',
  is_broker: false,
  is_send_owner_address: true,
};

const BrokerConfigFormContainer: React.FC<BrokerConfigFormProps> = ({
  onCancel,
  visible,
  onUpdateBrokerConfiguration,
  brokerData,
}) => {
  const [listBrokerUser, setListBrokerUser] = React.useState<User[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const validationSchema = React.useRef(() =>
    Yup.object().shape({
      select_agents: Yup.array(),
      exclude_agents: Yup.array(),
      select_rule: Yup.string().required(validation.required),

      home_credit: Yup.number()
        .required(validation.required)
        .typeError(validation.requiredTypeError)
        .integer(validation.requiredTypeError)
        .min(1, validation.requiredTypeError)
        .test('regex', validation.requiredTypeError, (val) => {
          if (!val) return true;
          const regExp = new RegExp(regex.integerNumber);
          return regExp.test(val?.toString());
        }),

      minimum_homes: Yup.number()
        .required(validation.required)
        .typeError(validation.requiredTypeError)
        .integer(validation.requiredTypeError)
        .min(1, validation.requiredTypeError)
        .test('regex', validation.requiredTypeError, (val) => {
          if (!val) return true;
          const regExp = new RegExp(regex.integerNumber);
          return regExp.test(val?.toString());
        })
        .test('is-less-than-max-search-homes', 'Minimum Homes must be less than Max Search Homes', function (value) {
          if (!value) return true;
          const { max_search_homes } = this.parent;
          if (max_search_homes === null || max_search_homes === undefined) return true;
          return value <= max_search_homes;
        }),
      max_search_homes: Yup.number()
        .required(validation.required)
        .typeError(validation.requiredTypeError)
        .positive(validation.requiredTypeError)
        .integer(validation.requiredTypeError)
        .min(1, validation.requiredTypeError)
        .test('regex', validation.requiredTypeError, (val: any) => {
          if (!val) return true;
          const regExp = new RegExp(regex.integerNumber);
          return regExp.test(val?.toString());
        })
        .test('is-greater-than-minimum-homes', 'Max Search Homes must be greater than Minimum Homes', function (value) {
          if (!value) return true;
          const { minimum_homes } = this.parent;
          if (minimum_homes === null || minimum_homes === undefined) return true;
          return value >= minimum_homes;
        }),
      radius: Yup.number()
        .typeError(validation.requiredTypeError)
        .required(validation.required)
        .positive(validation.requiredTypeError)
        .test('regex', validation.requiredTypeError, (val: any) => {
          if (!val) return true;
          const regExp = new RegExp(regex.regexIntegerAndFloat);
          return regExp.test(val?.toString());
        }),
      automation_broker_landing_page: Yup.string().required(validation.required),
    }),
  );

  const formik = useFormik<BrokerConfigFormType>({
    initialValues: { ...initialValuesBroker, ...brokerData },
    validationSchema: validationSchema.current,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      onUpdateBrokerConfiguration({
        ...values,
        home_credit: values?.home_credit ? parseInt(values?.home_credit.toString(), 10) : null,
        minimum_homes: values?.minimum_homes ? parseInt(values?.minimum_homes.toString(), 10) : null,
        max_search_homes: values?.max_search_homes ? parseInt(values?.max_search_homes.toString(), 10) : null,
        radius: values?.radius ? parseFloat(values?.radius.toString()) : null,
        is_broker: true,
      });
      onCancel();
      return;
    },
  });

  const resetForm = () => {
    formik.resetForm();
  };
  useEffect(() => {
    if (visible && !listBrokerUser?.length) {
      const fetchData = async () => {
        const results = await getListBrokerUser();
        setListBrokerUser(results);
      };
      fetchData();
    }
  }, [visible]);

  const getListBrokerUser = async () => {
    let page = 1;
    const limit = 150;
    const allResults: User[] = [];
    let continueLoop = true;
    try {
      setIsLoading(true);
      while (continueLoop) {
        const data = await UserService.listBrokerUser(page, limit);
        if (data.results) {
          allResults.push(...data.results);
        }
        if (data.links && data.links.next) {
          page++;
        } else {
          continueLoop = false;
        }
      }
      return allResults ?? [];
    } catch (error) {
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const generateItemSelectArray = (listBrokerUser) => {
    return listBrokerUser.map((v) => ({
      value: v?.id,
      label: joinAgentName(v),
      disabled: v.disabled,
    }));
  };

  const updateDisableValue = (dataSelected) => {
    return dataSelected.reduce((acc, currentValue) => {
      acc[currentValue] = currentValue?.toString();
      return acc;
    }, {});
  };

  const handleInputChange = (key) => (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/\s/g, '');
    formik.setFieldValue(key, sanitizedValue);
  };

  function checkAgentIds(agentIds) {
    const agentSet = new Set(generateItemSelectArray(listBrokerUser).map((agent) => agent.value));
    return agentIds.some((agentId) => agentSet.has(agentId));
  }

  return (
    <BrokerConfigFormView
      checkAgentIds={checkAgentIds}
      formik={formik}
      generateItemSelectArray={generateItemSelectArray}
      handleInputChange={handleInputChange}
      isLoading={isLoading}
      listBrokerUser={listBrokerUser}
      onCancel={onCancel}
      resetForm={resetForm}
      updateDisableValue={updateDisableValue}
    />
  );
};

export default BrokerConfigFormContainer;
