import React, { memo } from 'react';
import BrokerLandingPageConfigForm from './BrokerLandingPageConfigForm';
import { ModalCreateUpdateBrokerLandingPageProps } from './ModalCreateUpdateBrokerLandingPageContainer';
import { ModalCreateUpdateBrokerLandingPageStyled } from './ModalCreateUpdateBrokerLandingPageStyled';

const ModalCreateUpdateBrokerLandingPageView: React.FC<ModalCreateUpdateBrokerLandingPageProps> = memo(
  ({ visible, onCancel }) => {
    return (
      <ModalCreateUpdateBrokerLandingPageStyled
        centered
        visible={visible}
        destroyOnClose={true}
        onCancel={onCancel}
        closable={false}
        footer={null}
        width={1284}
      >
        <div className="app-modal-body">
          <BrokerLandingPageConfigForm onCancel={onCancel} />
        </div>
      </ModalCreateUpdateBrokerLandingPageStyled>
    );
  },
);

export default ModalCreateUpdateBrokerLandingPageView;
