import DisclaimerTooltip from '@/components/disclaimer-tooltip';
import LandingSelect from '@/components/select/landing-select';
import SelectMultipleTags from '@/components/select/SelectMultipleTags';
import { BrokerConfigFormType } from '@/models';
import { selectedRuleListingFoundBroker } from '@/utils/constant';
import { hasError } from '@/utils/form';
import { CaretDownOutlined } from '@ant-design/icons';
import { LandingPageType } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Col, Radio, Row } from 'antd';
import { FormikProps } from 'formik';
import React, { memo } from 'react';
import { FormStyled, LandingSelectStyled, SwitchStyled } from './BrokerConfigFormStyled';
import AppSelectV2 from '@/components/AppSelectV2';
import AppInputV2 from '@/components/AppInputV2';
import AppButtonV2 from '@/components/AppButtonV2';
interface BrokerConfigFormViewProps {
  onCancel: () => void;
  formik: FormikProps<BrokerConfigFormType>;
  handleInputChange: (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  listBrokerUser: any[];
  isLoading: boolean;
  resetForm: () => void;
  generateItemSelectArray: (list: any[]) => any[];
  updateDisableValue: (value: string[]) => string[];
  checkAgentIds: (value: string[]) => boolean;
}

const BrokerConfigFormView: React.FC<BrokerConfigFormViewProps> = memo((props) => {
  const {
    onCancel,
    formik,
    handleInputChange,
    listBrokerUser,
    isLoading,
    resetForm,
    generateItemSelectArray,
    updateDisableValue,
    checkAgentIds,
  } = props;
  return (
    <FormStyled onSubmit={formik.handleSubmit} autoComplete="off">
      <div className="form-item">
        <p className="title">Automated Broker Configuration</p>
        <SwitchStyled className="switch-group">
          <Radio.Group value={!!formik.values.is_send_owner_address} size="large">
            <Radio.Button
              value={true}
              onChange={(e) => {
                formik.setFieldValue('is_send_owner_address', e.target.value);
              }}
            >
              Owner
            </Radio.Button>
            <Radio.Button
              value={false}
              onChange={(e) => {
                formik.setFieldValue('is_send_owner_address', e.target.value);
              }}
            >
              Renter
            </Radio.Button>
            <DisclaimerTooltip title={`Send to Owner or Renter`} placement="bottom" />
          </Radio.Group>
        </SwitchStyled>
        <div className="container-input">
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <AppInputV2
                className="field name-field"
                label="Home Credit"
                name="home_credit"
                type="text"
                requiredMark
                placeholder="Home Credit"
                size="large"
                onChange={handleInputChange('home_credit')}
                onBlur={formik.handleBlur}
                value={formik.values.home_credit}
                hasError={hasError('home_credit', formik)}
                errorMessage={formik.errors.home_credit}
              />
            </Col>

            <Col className="gutter-row tag-item" span={24} id="tags-id">
              <SelectMultipleTags
                isGetContainer
                label="Exclude Agents"
                className="field base-field"
                name="exclude_agents"
                type="string"
                placeholder="Enter Agents"
                itemSelect={generateItemSelectArray(listBrokerUser)}
                disableValue={updateDisableValue(formik?.values?.select_agents)}
                handleChange={(value) => {
                  formik.setFieldValue('exclude_agents', value);
                }}
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                  option?.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                }
                handleBlur={formik.handleBlur}
                value={
                  isLoading
                    ? null
                    : checkAgentIds(formik?.values?.exclude_agents)
                    ? formik?.values?.exclude_agents
                    : null
                }
                parentId="tags-id"
                hasError={hasError('exclude_agents', formik)}
                errorMessage={formik.errors.exclude_agents}
                heightExpanded
              />
            </Col>

            <Col className="gutter-row tag-item" span={24} id="tags-id">
              <SelectMultipleTags
                isGetContainer
                label="Select Agents"
                className="field base-field"
                name="select_agents"
                type="string"
                placeholder="Enter Agents"
                disableValue={updateDisableValue(formik?.values?.exclude_agents)}
                itemSelect={generateItemSelectArray(listBrokerUser)}
                handleChange={(value) => {
                  formik.setFieldValue('select_agents', value);
                }}
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                  option?.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                }
                handleBlur={formik.handleBlur}
                value={
                  isLoading ? null : checkAgentIds(formik?.values?.select_agents) ? formik?.values?.select_agents : null
                }
                parentId="tags-id"
                errorMessage={formik.errors.select_agents}
                hasError={hasError('select_agents', formik)}
                heightExpanded
              />
            </Col>
            <Col className="gutter-row" span={24} id={'pin'}>
              <AppSelectV2
                className="field item-field"
                label="Select Rule"
                placeholder="Select Rule"
                requiredMark
                options={selectedRuleListingFoundBroker}
                onChange={(value) => formik.setFieldValue('select_rule', value)}
                onBlur={formik.handleBlur}
                value={formik?.values?.select_rule}
                hasError={hasError('select_rule', formik)}
                errorMessage={formik.errors.select_rule}
                suffixIcon={<CaretDownOutlined />}
              />
            </Col>
            <Col className="gutter-row" span={24}>
              <AppInputV2
                className="field name-field"
                label="Radius (mi)"
                name="radius"
                type="text"
                requiredMark
                placeholder="Radius (mi)"
                size="large"
                onChange={handleInputChange('radius')}
                onBlur={formik.handleBlur}
                value={formik.values.radius}
                hasError={hasError('radius', formik)}
                errorMessage={formik.errors.radius}
              />
            </Col>
            <Col className="gutter-row" span={24}>
              <AppInputV2
                className="field name-field"
                label="Max Search Homes"
                name="max_search_homes"
                type="text"
                requiredMark
                placeholder="Max Search Homes"
                size="large"
                onChange={handleInputChange('max_search_homes')}
                onBlur={formik.handleBlur}
                value={formik.values.max_search_homes}
                hasError={hasError('max_search_homes', formik)}
                errorMessage={formik.errors.max_search_homes}
              />
            </Col>
            <Col className="gutter-row" span={24}>
              <AppInputV2
                className="field name-field"
                label="Minimum Homes"
                name="minimum_homes"
                type="text"
                placeholder="Minimum Homes"
                requiredMark
                size="large"
                onChange={handleInputChange('minimum_homes')}
                onBlur={formik.handleBlur}
                value={formik.values.minimum_homes}
                hasError={hasError('minimum_homes', formik)}
                errorMessage={formik.errors.minimum_homes}
              />
            </Col>
            <Col className="gutter-row" span={24}>
              <LandingSelectStyled id="pin2" className="lp">
                <LandingSelect
                  isCreateLandingPageVisible={false}
                  requiredMark
                  className="field base-field landing-field"
                  label="Select Landing Page"
                  name={'automation_broker_landing_page'}
                  placeholder="Select Landing Page"
                  handleChange={(value) => {
                    formik.setFieldValue('automation_broker_landing_page', value);
                  }}
                  value={formik.values.automation_broker_landing_page}
                  hasError={hasError('automation_broker_landing_page', formik)}
                  landingPageType={LandingPageType.BROKER_MLS}
                  notFoundValue={(isNotFound) => {
                    if (isNotFound) formik.setFieldValue('automation_broker_landing_page', '');
                  }}
                  errorMessage={formik.errors.automation_broker_landing_page}
                />
              </LandingSelectStyled>
            </Col>
          </Row>
        </div>
      </div>
      <div className="btn-field">
        <AppButtonV2
          size="large"
          color="Pin"
          width="100%"
          style={{ marginBottom: 16 }}
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => {
            formik.submitForm();
          }}
        >
          SAVE
        </AppButtonV2>
        <AppButtonV2
          size="large"
          color="WhiteTransparent"
          width="100%"
          onClick={() => {
            resetForm();
            onCancel();
          }}
          disabled={formik.isSubmitting}
        >
          CANCEL
        </AppButtonV2>
      </div>
    </FormStyled>
  );
});

export default BrokerConfigFormView;
