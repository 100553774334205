import styled from '@emotion/styled';

const SelectMultipleTagsViewStyled = {
  Container: styled.div<{ disabled?: boolean; isLabelAffected?: boolean }>`
    width: 100%;

    &.base-field {
      height: 100%;
      &.disabled {
        opacity: 0.25;
      }
    }

    &.fix-w {
      max-width: 339.33px;
    }
  `,
};

export default SelectMultipleTagsViewStyled;
