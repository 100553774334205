import { color } from '@/styles/theme';
import styled from '@emotion/styled';

export const AddressCheckedStyled = styled.div`
  width: 102px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  .toggle-label {
    font-size: 10px;
    font-weight: 700;
    color: ${color.GRAY_11};
    &.on {
      color: ${color.PINK};
    }
  }
  .ant-switch {
    background: ${color.WHITE};
    border: solid 1px ${color.GRAY_12};
    &:not(.ant-switch-disabled):active.ant-switch-checked {
      .ant-switch-handle {
        &::before {
          right: -30%;
          left: 0;
        }
      }
    }
    .ant-switch-handle {
      right: 2px;
      left: unset;
      &::before {
        top: 0px;
        right: 2px;
        bottom: 2px;
        left: 0;
        background-color: ${color.GRAY_11};
      }
    }
    &.ant-switch-checked {
      .ant-switch-handle {
        left: 2px;
        right: unset;
        &::before {
          background-color: ${color.PINK};
        }
      }
    }
  }
`;
