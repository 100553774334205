import { color } from '@/styles/theme';
import styled from '@emotion/styled';

export const FormStyled = styled.form`
  .form-item {
    .title {
      font-size: 32px;
      font-weight: 600;
      font-family: FiraSans;
      margin-bottom: 30px;
    }
    .container-input {
      max-height: 680px;
      overflow-y: auto;
      overflow-x: hidden;
      .field,
      .item-field {
        text-align: left !important;
      }
      .base-field {
        max-width: 100%;
      }
      .tag-item {
        .option {
          &.disabled {
            cursor: not-allowed;
            pointer-events: none !important;
          }
        }
        .ant-empty-normal {
          margin: 8px 0px;
        }
        .ant-select-dropdown {
          max-height: 140px !important;
        }

        .textArea-field {
          height: 122px !important;
          padding: 0;
        }
        .ant-select-selection-placeholder {
          left: 17px;
          opacity: 0.85;
          color: ${color.GRAY_9};
        }
        .anticon-close {
          svg {
            border: 1px solid;
            border-radius: 50%;
            padding: 1px;
            display: flex;
            width: 11px;
            height: 11px;
          }
        }
      }
      .ant-checkbox-wrapper {
        color: ${color.WHITE};
        font-weight: 700;
        font-size: 16px;
      }
      .ant-select-dropdown {
        width: 100% !important;
      }
      .select-field {
        .ant-select {
          min-height: 58px;
          .ant-select-selector {
            height: 58px;
          }
        }
      }
    }

    .ant-checkbox-wrapper-disabled {
      opacity: 0.8;
      span {
        color: ${color.WHITE};
      }
    }

    .s-label {
      font-size: 16px;
      opacity: 1;
      font-weight: 700;
      transition-property: top, font-size, opacity;
      transition-duration: 0.1s;
      transition-timing-function: linear;
      margin-bottom: 5px;
      display: inline-block;
      width: 100%;
      text-align: left;
    }
    .error {
      font-weight: 700;
    }
  }
`;

export const LandingSelectStyled = styled.div`
  position: relative;
  .landing-field {
    margin-bottom: 5px !important;
    .ant-select,
    .ant-select-selector {
      max-height: 58px !important;
    }
  }

  .ant-select-arrow {
    font-size: 12px;
    height: 12px !important;
    width: 12px !important;
    .anticon-caret-down {
      font-size: 12px !important;
    }
  }
  .errorMsg {
    font-weight: 600;
    margin: 0px;
  }
`;
export const SwitchStyled = styled.div`
  margin-bottom: 30px;

  .ant-radio-button-wrapper {
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    color: ${color.WHITE} !important;
    border-color: ${color.PINK} !important;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100px;

    &::hover,
    &::focus,
    &::active {
      border-color: ${color.PINK};
      color: ${color.WHITE} !important;
    }
    &::before {
      display: none;
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${color.PINK};

      &::hover,
      &::focus,
      &::active {
        border-color: ${color.PINK};
        color: ${color.PINK} !important;
      }
    }
  }
`;
