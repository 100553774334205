import {
  FieldDataDetail,
  Milestone,
  OperatorEnums,
  SEND_ADDRESS,
  SmartTrigger,
  VIEW_MODE,
  VIEW_OWNER_OCCUPIED,
  VIEW_OWNER_OUT_OF_STATE,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { useFormik } from 'formik';
import { debounce, isArray, isEmpty, isString, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertTextError } from '@/components/alert/SweetAlert';
import CloseIcon from '@/assets/icons/close-ic.svg';
import AppAction from '@/reducers/app/action';
import { RootState } from '@/reducers/model';
import UserAction from '@/reducers/user/action';
import SmartActionService from '@/services/smart-action';
import { EMPTY_DATE_DEFAULT, limit_drip_campaign, limit_smart_trigger } from '@/utils/constant';
import { formatCurrency, formatCurrencySymbol } from '@/utils/currency';
import { KEY_MLS_TRIGGER } from '@/utils/enum';
import { formatDate } from '@/utils/format';
import regex from '@/utils/regularExpression';
import validation from '@/utils/validation';
import * as Yup from 'yup';
import { updateOptionsAudience } from '@/utils/utils';
import { BrokerConfigFormType } from '@/models';
import { useQueryParam } from '@/hooks/useQueryParam';
import { ConstantValue } from '@goldfishcode/first-team-real-estate-sdk/libs/api/settings/models';
import { MailingList, MailingListTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { AddressesSearchMLSParams } from '@/models/AddressesSearchMLSParams';
import useGetConstantValueQuery from '@/queries/settings/useGetConstantValue';

export const dataHouseProperties: {
  [key: string]: {
    name: string;
    sortIndex?: number | undefined;
  };
} = {
  [KEY_MLS_TRIGGER.home_equity_percentage]: { name: 'Equity' },
  [KEY_MLS_TRIGGER.city]: { name: 'City' },
  [KEY_MLS_TRIGGER.zip_code]: { name: 'Zip Code' },
  [KEY_MLS_TRIGGER.property_type]: { name: 'Property Type' },
  [KEY_MLS_TRIGGER.num_of_bedrooms]: { name: 'Number of Bedrooms' },
  [KEY_MLS_TRIGGER.num_of_bathrooms]: { name: 'Number of Bathrooms' },
  [KEY_MLS_TRIGGER.estimated_price]: { name: 'Estimated Price' },
  [KEY_MLS_TRIGGER.street_address]: { name: 'Street Address' },
  [KEY_MLS_TRIGGER.abbreviated_street_name]: { name: 'Abbreviated Street Name' },
  [KEY_MLS_TRIGGER.owner_first_name]: { name: 'Owner’s First Name' },
  [KEY_MLS_TRIGGER.owner_full_name]: { name: 'Owner’s Full Name' },
  [KEY_MLS_TRIGGER.stories]: { name: 'Stories' },
  [KEY_MLS_TRIGGER.turn_over_rate]: { name: 'Turn Over Rate' },
  [KEY_MLS_TRIGGER.features]: { name: 'Features' },
  [KEY_MLS_TRIGGER.last_sold_price]: { name: 'Last Sold Price' },
  [KEY_MLS_TRIGGER.year_since_purchase]: { name: 'Year Since Purchase' },
  [KEY_MLS_TRIGGER.avg_year_owned]: { name: 'Avg Year Owned' },
  [KEY_MLS_TRIGGER.square_feet]: { name: 'Square Feet' },
  [KEY_MLS_TRIGGER.year_built]: { name: 'Year Built' },
  [KEY_MLS_TRIGGER.last_sold_date]: { name: 'Last Sold Date' },
  [KEY_MLS_TRIGGER.years_owned]: { name: 'Years Owned' },
  [KEY_MLS_TRIGGER.avm_value]: { name: 'AVM Value' },
  [KEY_MLS_TRIGGER.interest_rate]: { name: 'Interest Rate' },
  [KEY_MLS_TRIGGER.days_on_market]: { name: 'Days on Market' },
  [KEY_MLS_TRIGGER.price_per_square_foot]: { name: 'Price Per Sqft' },
};

export interface SelectObjType {
  label: string;
  value: string;
  type?: string;
  disabled?: boolean;
}
export interface SmartTriggerFormsProps {
  visible?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}
export interface Dropdown {
  value: string;
  label: string;
}

export enum enumAddType {
  'INCLUDE' = 'include',
  'EXCLUDE' = 'exclude',
}

export enum statusEnum {
  Pending = 'PENDING',
  InProgress = 'IN-PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}
let i = 1;
export interface SmartTriggerTypeDropDown {
  mls_status: Array<Dropdown>;
  age_range: Array<string>;
  operators: Array<Dropdown>;
}
const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const shapeObj = {
  shapeCheckedArray: {
    checked: Yup.boolean(),
    value: Yup.array().when('checked', {
      is: true,
      then: Yup.array(Yup.string().required(validation.required)).min(1, validation.required),
      otherwise: Yup.array().notRequired(),
    }),
  },
  shapeArray: {
    temp_value: Yup.string().trim().required(validation.required),
    [enumAddType.INCLUDE]: Yup.array(),
    [enumAddType.EXCLUDE]: Yup.array(),
  },
  shapeIntegerNumber: {
    operator: Yup.string().required(validation.required),
    temp_value: Yup.number()
      .typeError(validation.requiredTypeError)
      .positive()
      .integer(validation.requiredInteger)
      .min(0, 'Min value 0.')
      .test('regex', validation.requiredNumber, (val: any) => {
        if (!val) return true;
        const regExp = new RegExp(regex.integerNumber);
        return regExp.test(val);
      })
      .required(validation.required),
    [enumAddType.INCLUDE]: Yup.array(),
    [enumAddType.EXCLUDE]: Yup.array(),
  },
  shapeFloatNumber: {
    operator: Yup.string().required(validation.required),
    temp_value: Yup.number()
      .typeError(validation.requiredTypeError)
      .positive()
      .min(0, 'Min value 0.')
      .test('is-decimal', 'The amount should be a decimal with maximum two digits after comma', (val: any) => {
        if (val != undefined) {
          return patternTwoDigisAfterComma.test(val);
        }
        return true;
      })
      .test('regex', 'Please enter numbers only', (val: any) => {
        if (!val) return true;
        const regExp = new RegExp(regex.regexIntegerAndFloat);
        return regExp.test(val);
      })
      .required(validation.required),
    [enumAddType.INCLUDE]: Yup.array(),
    [enumAddType.EXCLUDE]: Yup.array(),
  },
  shapeChecked: {
    checked: Yup.boolean(),
    value: Yup.string().when('checked', {
      is: true,
      then: Yup.string().required(validation.required),
      otherwise: Yup.string().notRequired(),
    }),
  },

  shapeCheckedFloatNumber: {
    checked: Yup.boolean(),
    value: Yup.number().when('checked', {
      is: true,
      then: Yup.number()
        .typeError(validation.requiredTypeError)
        .positive('Please input a value greater than 0')
        // .min(1, 'Please enter a value greater than 0')
        .test('regex', validation.requiredNumber, (val: any) => {
          if (!val) return true;
          const regExp = new RegExp(regex.regexIntegerAndFloat);
          return regExp.test(val);
        }),
    }),
  },
  shapeCheckedIntegerNumber: {
    checked: Yup.boolean(),
    value: Yup.number().when('checked', {
      is: true,
      then: Yup.number()
        .typeError(validation.requiredTypeError)
        .positive('Please enter a value greater than 0')
        .test('regex', validation.requiredInteger, (val: any) => {
          if (!val) return true;
          const regExp = new RegExp(regex.integerNumber);
          return regExp.test(val);
        }),
    }),
  },
};

const premiumFields = [
  KEY_MLS_TRIGGER.notice_of_default,
  KEY_MLS_TRIGGER.divorcees,
  KEY_MLS_TRIGGER.affidavits_of_death,
];

const base_house_properties_obj = {
  temp_value: '',
  [enumAddType.INCLUDE]: [],
  [enumAddType.EXCLUDE]: [],
};

export const useSmartTriggerForm = ({ onCancel }: SmartTriggerFormsProps) => {
  const params = useQueryParam<{ mailingId?: string; smartTriggerId?: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
  const [options_property_type, setPropertyType] = useState<string[]>([]);
  const visibleModalSmartTrigger = useSelector((state: RootState) => state.app.visibleModalSmartTrigger);
  const currentStateSmarTrigger = useSelector((state: RootState) => state.user.currentStateSmartTrigger);
  const { data } = useGetConstantValueQuery();
  const options_rea_properties = useMemo(() => data?.rea_properties, [data?.rea_properties]);
  const options_mls_status = useMemo(() => data?.smart_trigger?.mls_status, [data?.smart_trigger?.mls_status]);
  const options_age_range = useMemo(() => data?.smart_trigger?.age_range, [data?.smart_trigger?.age_range]);
  const operators = useMemo(() => data?.smart_trigger?.operators, [data?.smart_trigger?.operators]);

  const agentID = useSelector((state: RootState) => state?.home?.userAgent?.id);
  const idEditing = useSelector((state: RootState) => state?.mailing?.stateSmartTrigger?.idEditingItemSmartTrigger);
  const isDuplicateItem = useSelector((state: RootState) => state?.mailing?.stateSmartTrigger?.isDuplicateItem);
  const addressesFoundCountForMLS = useSelector((state: RootState) => state?.mailing?.addressesFoundCountForMLS);
  const segmentationTags = useSelector((state: RootState) => state.campaign.listTags);
  //handle loadmore drip
  const [optionsDripCampaign, setOptionsDripCampaign] = useState<SmartTrigger[]>([]);
  const [currentPageDrip, setCurrentPageDrip] = useState(1);
  const [searchValueDrip, setSearchValueDrip] = useState<string>();
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useDispatch();

  const options_operators = (isDate = false) => {
    return (
      operators
        ?.map((v) => v.value)
        .filter((v) => {
          return isDate ? v : v !== 'range';
        }) || []
    );
  };
  const [mailingSelected, setMailingSelected] = useState<SelectObjType[]>([]);
  const [mailingOptions, setMailingOptions] = useState<MailingList[]>([]);
  const [optionsAudience, setOptionsAudience] = useState<SelectObjType[]>([]);
  const [optionsMilestone, setOptionsMilestone] = useState<Milestone[]>([]);
  const [initialName, setInitialName] = useState<string>('');

  const mlsFirstLoadParams = React.useRef<AddressesSearchMLSParams>({} as AddressesSearchMLSParams);
  const debouncedApiExistTriggerName = debounce(SmartActionService.existTriggerName, 300, {
    trailing: true,
  });

  const tagList: SelectObjType[] = useMemo(
    () =>
      segmentationTags
        ?.filter((v) => v.user)
        ?.map((v) => {
          return { label: v.name, value: v.id } as SelectObjType;
        }) || [],
    [segmentationTags],
  );

  const validationSchema = React.useRef(({ agent_id, sm_trigger_id, initialName, mailingOptions }) =>
    Yup.object().shape({
      name: Yup.string()
        .required(validation.smartTriggerName.required)
        .test('alphabet', validation.smartTriggerName.format, (value) => {
          if (value && value.length > 0) {
            return regex.alphabetAndNumber.test(value.trim());
          }
          return false;
        })
        .test('existingName', validation.smartTriggerName.ExistingName, async (value?: string) => {
          try {
            if (!value) return false;
            if (value === initialName) {
              return true;
            }
            const nameExist = await debouncedApiExistTriggerName(agent_id, value, sm_trigger_id);
            return !nameExist?.exists;
          } catch (error) {
            return false;
          }
        }),

      mls_status: Yup.array().test('milestone', validation.required, (value, context) => {
        if (context?.parent?.milestone?.length || context?.parent?.trigger_on_demand || value?.length) return true;
        return false;
      }),
      mls_status_filters: Yup.array().when('is_broker', {
        is: (v) => !v,
        then: Yup.array().min(1, validation.required),
      }),
      milestone: Yup.array().test('mls_status', validation.required, (value, context) => {
        const isSelectMyListing = checkIsSelectMylisting(context?.parent?.mailing_lists, mailingOptions);
        if (
          isSelectMyListing ||
          context?.parent?.mls_status?.length ||
          context?.parent?.trigger_on_demand ||
          value?.length
        )
          return true;
        return false;
      }),
      postalytics_audience_ids: Yup.array().when('trigger_on_demand', {
        is: (v) => !v,
        then: Yup.array().when('mailing_lists', {
          is: (v) => checkIsSelectMylisting(v, mailingOptions),
          then: Yup.array().notRequired(),
          otherwise: Yup.array(Yup.string().required(validation.required)).min(1, validation.required),
        }),
        otherwise: Yup.array(Yup.string().required(validation.required)).min(1, validation.required),
      }),
      mailing_lists: Yup.array().test({
        name: 'is_broker',
        test: function (value: string[] | any) {
          const is_broker = this.parent.is_broker;
          if (is_broker) {
            return true;
          }
          return value.length > 0;
        },
        message: validation.required,
      }),
      is_broker: Yup.boolean(),
      sm_trigger_campaign_id: Yup.string().required(validation.required),
      trigger_on_demand: Yup.boolean(),
      is_only_my_listing: Yup.boolean(),
      is_new_past_client: Yup.boolean(),
      automation_broker_landing_page: Yup.string().when('is_broker', {
        is: true,
        then: Yup.string().nullable().required('Please set up this configuration'),
        otherwise: Yup.string().nullable(),
      }),
      rea_properties: Yup.array(),
      [KEY_MLS_TRIGGER.select_rule]: Yup.string().when('trigger_on_demand', {
        is: (v) => !v,
        then: Yup.string().when('mailing_lists', {
          is: (v) => checkIsSelectMylisting(v, mailingOptions),
          then: Yup.string().required('Please select rule'),
          otherwise: Yup.string().nullable().notRequired(),
        }),
        otherwise: Yup.string().nullable().notRequired(),
      }),
      [KEY_MLS_TRIGGER.max_search_homes]: Yup.number().when('trigger_on_demand', {
        is: (v) => !v,
        then: Yup.number().when('mailing_lists', {
          is: (v) => checkIsSelectMylisting(v, mailingOptions),
          then: Yup.number()
            .typeError(validation.requiredTypeError)
            .positive()
            .integer(validation.requiredInteger)
            .min(0, 'Min value 0.')
            .test('regex', validation.requiredNumber, (val: any) => {
              if (!val) return true;
              const regExp = new RegExp(regex.integerNumber);
              return regExp.test(val);
            })
            .required(validation.required),
          otherwise: Yup.number().nullable().notRequired(),
        }),
        otherwise: Yup.number().nullable().notRequired(),
      }),
    }),
  );

  const validateHouseProperties = React.useRef({
    [KEY_MLS_TRIGGER.home_equity_percentage]: Yup.object().shape(shapeObj['shapeFloatNumber']),
    [KEY_MLS_TRIGGER.city]: Yup.object().shape({
      checked: Yup.boolean(),
      temp_value: Yup.string().trim().required(validation.required),
      [enumAddType.INCLUDE]: Yup.array(),
      [enumAddType.EXCLUDE]: Yup.array(),
    }),
    [KEY_MLS_TRIGGER.zip_code]: Yup.object().shape({
      temp_value: Yup.string()
        .trim()
        .required(validation.zipCode.required)
        .test('alphabet', validation.zipCode.format, (value) => {
          if (value && value.length > 0) {
            return regex.alphabetAndNumber.test(value.trim());
          }
          return false;
        }),
      [enumAddType.INCLUDE]: Yup.array(),
      [enumAddType.EXCLUDE]: Yup.array(),
    }),
    [KEY_MLS_TRIGGER.property_type]: Yup.object().shape(shapeObj['shapeArray']),
    [KEY_MLS_TRIGGER.num_of_bathrooms]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
    [KEY_MLS_TRIGGER.num_of_bedrooms]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
    [KEY_MLS_TRIGGER.estimated_price]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
    [KEY_MLS_TRIGGER.street_address]: Yup.object().shape(shapeObj['shapeArray']),
    [KEY_MLS_TRIGGER.abbreviated_street_name]: Yup.object().shape(shapeObj['shapeArray']),
    [KEY_MLS_TRIGGER.owner_first_name]: Yup.object().shape({
      temp_value: Yup.string()
        .trim()
        .required(validation.ownerFirstName.required)
        .test('alphabet', validation.ownerFirstName.format, (value) => {
          if (value && value.length > 0) {
            return regex.alphabetAndNumber.test(value.trim());
          }
          return false;
        }),
      [enumAddType.INCLUDE]: Yup.array(),
      [enumAddType.EXCLUDE]: Yup.array(),
    }),
    [KEY_MLS_TRIGGER.owner_full_name]: Yup.object().shape({
      temp_value: Yup.string()
        .trim()
        .required(validation.ownerFullName.required)
        .test('alphabet', validation.ownerFullName.format, (value) => {
          if (value && value.length > 0) {
            return regex.alphabetAndNumber.test(value.trim());
          }
          return false;
        }),
      [enumAddType.INCLUDE]: Yup.array(),
      [enumAddType.EXCLUDE]: Yup.array(),
    }),
    [KEY_MLS_TRIGGER.stories]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
    [KEY_MLS_TRIGGER.year_built]: Yup.object().when((values, schema) => {
      if (values.operator === 'range') {
        return schema.shape({
          operator: Yup.string().required(validation.required),
          temp_value: Yup.string(),
          start: Yup.date().typeError('Enter a date'),
          end: Yup.date().typeError('Enter a date').min(Yup.ref('start'), "End date can't be before Start date"),
          [enumAddType.INCLUDE]: Yup.array(),
          [enumAddType.EXCLUDE]: Yup.array(),
        });
      } else {
        return schema.shape({
          operator: Yup.string().required(validation.required),
          temp_value: Yup.date().typeError('Enter a date'),
          start: Yup.string(),
          end: Yup.string(),
          [enumAddType.INCLUDE]: Yup.array(),
          [enumAddType.EXCLUDE]: Yup.array(),
        });
      }
    }),
    [KEY_MLS_TRIGGER.last_sold_date]: Yup.object().when((values, schema) => {
      if (values.operator === 'range') {
        return schema.shape({
          operator: Yup.string().required(validation.required),
          temp_value: Yup.string(),
          start: Yup.date().typeError('Enter a date'),
          end: Yup.date().typeError('Enter a date').min(Yup.ref('start'), "End date can't be before Start date"),
          [enumAddType.INCLUDE]: Yup.array(),
          [enumAddType.EXCLUDE]: Yup.array(),
        });
      } else {
        return schema.shape({
          operator: Yup.string().required(validation.required),
          temp_value: Yup.date().typeError('Enter a date'),
          start: Yup.string(),
          end: Yup.string(),
          [enumAddType.INCLUDE]: Yup.array(),
          [enumAddType.EXCLUDE]: Yup.array(),
        });
      }
    }),
    [KEY_MLS_TRIGGER.features]: Yup.object().shape(shapeObj['shapeArray']),

    [KEY_MLS_TRIGGER.turn_over_rate]: Yup.object().shape({
      temp_value: Yup.number()
        .typeError(validation.requiredTypeError)
        .positive()
        .min(0, 'Min value 0.')
        .test('is-decimal', 'The amount should be a decimal with maximum two digits after comma', (val: any) => {
          if (val != undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        })
        .test('regex', 'Please enter numbers only', (val: any) => {
          if (!val) return true;
          const regExp = new RegExp(regex.regexIntegerAndFloat);
          return regExp.test(val);
        })
        .required(validation.required),
      [enumAddType.INCLUDE]: Yup.array(),
      [enumAddType.EXCLUDE]: Yup.array(),
    }),

    [KEY_MLS_TRIGGER.last_sold_price]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
    [KEY_MLS_TRIGGER.year_since_purchase]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
    [KEY_MLS_TRIGGER.avg_year_owned]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
    [KEY_MLS_TRIGGER.square_feet]: Yup.object().shape(shapeObj['shapeIntegerNumber']),

    [KEY_MLS_TRIGGER.years_owned]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
    [KEY_MLS_TRIGGER.avm_value]: Yup.object().shape(shapeObj['shapeIntegerNumber']),

    [KEY_MLS_TRIGGER.interest_rate]: Yup.object().shape(shapeObj['shapeFloatNumber']),
    [KEY_MLS_TRIGGER.days_on_market]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
    [KEY_MLS_TRIGGER.price_per_square_foot]: Yup.object().shape(shapeObj['shapeIntegerNumber']),
  });

  const getAddressesParams = (forceRunByCreateCase?: boolean) => {
    const isCreateMLSCase = forceRunByCreateCase || !addressesFoundCountForMLS?.params?.smart_trigger_action_id;
    const exclude_mailings = addressesFoundCountForMLS?.mailings
      .filter((v) => {
        if (isCreateMLSCase) {
          return !v.is_available_for_campaign;
        }
        return addressesFoundCountForMLS?.params?.exclude_mailings &&
          addressesFoundCountForMLS?.params?.exclude_mailings?.length > 0
          ? addressesFoundCountForMLS?.params?.exclude_mailings?.includes(v.id)
          : false;
      })
      .map((v) => v.id);
    const send_to_owner = addressesFoundCountForMLS?.mailings
      ?.filter((v) => {
        if (isCreateMLSCase) {
          return v.is_send_owner_address;
        }
        return addressesFoundCountForMLS?.params?.send_to_owner &&
          addressesFoundCountForMLS?.params?.send_to_owner?.length > 0
          ? addressesFoundCountForMLS?.params?.send_to_owner?.includes(v.id)
          : false;
      })
      .map((v) => v.id);

    const send_to = !addressesFoundCountForMLS?.params?.isRealtor
      ? SEND_ADDRESS.SEND_ADDRESSES_ALL
      : addressesFoundCountForMLS?.params?.isRealtor;

    return {
      exclude_mailings,
      send_to_owner,
      send_to,
    };
  };

  const formik: any = useFormik<any>({
    initialValues: {
      agent_id: agentID,
      is_active: false,
      trigger_on_demand: false,
      is_only_my_listing: false,
      is_new_past_client: false,
      name: '',
      mls_status: [],
      rea_properties: [],
      mls_status_filters: [],
      milestone: [],
      postalytics_audience_ids: [],
      mailing_lists: [],
      [KEY_MLS_TRIGGER.home_equity_percentage]: { ...base_house_properties_obj, operator: OperatorEnums.EQUAL },
      [KEY_MLS_TRIGGER.city]: {
        ...base_house_properties_obj,
      },
      [KEY_MLS_TRIGGER.zip_code]: {
        ...base_house_properties_obj,
      },
      [KEY_MLS_TRIGGER.property_type]: {
        ...base_house_properties_obj,
      },
      [KEY_MLS_TRIGGER.num_of_bedrooms]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.num_of_bathrooms]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.estimated_price]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },

      [KEY_MLS_TRIGGER.street_address]: base_house_properties_obj,
      [KEY_MLS_TRIGGER.abbreviated_street_name]: base_house_properties_obj,
      [KEY_MLS_TRIGGER.owner_first_name]: base_house_properties_obj,
      [KEY_MLS_TRIGGER.owner_full_name]: base_house_properties_obj,
      [KEY_MLS_TRIGGER.stories]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.year_built]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
        start: '',
        end: '',
      },
      [KEY_MLS_TRIGGER.last_sold_date]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
        start: '',
        end: '',
      },

      [KEY_MLS_TRIGGER.features]: base_house_properties_obj,
      [KEY_MLS_TRIGGER.turn_over_rate]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.last_sold_price]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.avg_year_owned]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.years_owned]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.avm_value]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.square_feet]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.year_since_purchase]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.interest_rate]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.days_on_market]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },
      [KEY_MLS_TRIGGER.price_per_square_foot]: {
        ...base_house_properties_obj,
        operator: OperatorEnums.EQUAL,
      },

      sm_trigger_campaign_id: '',

      // Premium data

      [KEY_MLS_TRIGGER.notice_of_default]: {
        ...base_house_properties_obj,
        checked: false,
        operator: OperatorEnums.EQUAL,
        value: '',
      },
      [KEY_MLS_TRIGGER.divorcees]: {
        ...base_house_properties_obj,
        checked: false,
        operator: OperatorEnums.EQUAL,
        value: '',
      },

      [KEY_MLS_TRIGGER.affidavits_of_death]: {
        ...base_house_properties_obj,
        checked: false,
        operator: OperatorEnums.EQUAL,
        value: '',
      },
    },
    validationSchema: validationSchema.current({
      sm_trigger_id: !isDuplicateItem ? idEditing : null,
      agent_id: agentID,
      initialName,
      mailingOptions: mailingOptions,
    }),
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (!agentID) return;
        //TODO check
        if (
          !formik.values[KEY_MLS_TRIGGER.mls_status]?.length &&
          !formik.values[KEY_MLS_TRIGGER.milestone]?.length &&
          !formik.values[KEY_MLS_TRIGGER.trigger_on_demand]
        ) {
          formik.setErrors({
            [KEY_MLS_TRIGGER.mls_status]: validation.required,
            [KEY_MLS_TRIGGER.milestone]: validation.required,
          });
          return;
        }
        // Format premium data payload before requesting
        const premiumData = formatDataPremium(values);
        const FormData = {
          ...values,
          ...premiumData,
          mailing_lists: values?.mailing_lists || [],
          neighborhoods: [],
        };

        delete FormData.id;
        delete FormData.sm_trigger_campaign;
        delete FormData.agent;
        setIsLoading(true);

        // Only update at MailingAddresses popup to avoid wrong result
        delete FormData.isRealtor;
        delete FormData.filterState;
        delete FormData.textSearch;
        delete FormData.ownerOutOfState;
        delete FormData.ownerOccupied;
        const send_to = addressesFoundCountForMLS?.params?.isRealtor;
        const view_mode = addressesFoundCountForMLS?.params.filterState;
        const view_owner_out_of_state =
          typeof addressesFoundCountForMLS?.params.ownerOutOfState === 'undefined'
            ? VIEW_OWNER_OUT_OF_STATE.OWNER_OUT_OF_STATE_ALL
            : addressesFoundCountForMLS?.params.ownerOutOfState
            ? VIEW_OWNER_OUT_OF_STATE.OWNER_OUT_OF_STATE_OUT
            : VIEW_OWNER_OUT_OF_STATE.OWNER_OUT_OF_STATE_IN;
        const view_owner_occupied =
          typeof addressesFoundCountForMLS?.params.ownerOccupied === 'undefined'
            ? VIEW_OWNER_OCCUPIED.VIEW_OWNER_OCCUPIED_ALL
            : addressesFoundCountForMLS?.params.ownerOccupied
            ? VIEW_OWNER_OCCUPIED.VIEW_OWNER_OCCUPIED
            : VIEW_OWNER_OCCUPIED.VIEW_OWNER_OCCUPIED_NOT;
        const search = addressesFoundCountForMLS?.params.textSearch;
        const send_to_mailings = addressesFoundCountForMLS?.params.send_to_mailings;
        if (idEditing && !isDuplicateItem) {
          delete FormData.id;
          delete FormData.sm_trigger_campaign;
          delete FormData.agent;

          const { exclude_mailings, send_to_owner } = getAddressesParams();

          FormData.exclude_mailings = exclude_mailings;
          FormData.send_to_owner = send_to_owner;
          FormData.send_to = send_to;
          FormData.view_mode = view_mode;
          FormData.view_owner_out_of_state = view_owner_out_of_state;
          FormData.view_owner_occupied = view_owner_occupied;
          FormData.search = search;
          FormData.send_to_mailings = send_to_mailings;
          FormData.send_to_mailings_total = addressesFoundCountForMLS?.updatedCount;
          SmartActionService.updateSmartTriggerAction(agentID, idEditing, FormData)
            .then(() => {
              dispatch({
                type: AppAction.VISIBLE_TRIGGER_ONDEMAND_MODAL,
                payload: true,
              });
              clearForm();
              getTriggerList();
              onCancel && onCancel();
            })
            .catch((error) => {
              if (error?.message.includes('Drip for Smart Trigger does not exist')) {
                formik.setErrors({
                  [KEY_MLS_TRIGGER.sm_trigger_campaign_id]: 'Drip for Smart Trigger does not exist.',
                });
              }
              AlertTextError('Submission Failed', error?.message);
            })
            .finally(() => {
              setIsLoading(false);
              formik.setSubmitting(false);
            });
        } else {
          const { exclude_mailings, send_to_owner } = getAddressesParams();

          FormData.exclude_mailings = exclude_mailings;
          FormData.send_to_owner = send_to_owner;
          FormData.send_to = send_to;
          FormData.view_mode = view_mode;
          FormData.view_owner_out_of_state = view_owner_out_of_state;
          FormData.view_owner_occupied = view_owner_occupied;
          FormData.search = search;
          FormData.send_to_mailings = send_to_mailings;
          FormData.is_active = false;
          FormData.send_to_mailings_total = addressesFoundCountForMLS?.updatedCount;
          SmartActionService.createSmartTriggerAction(agentID, FormData)
            .then(() => {
              dispatch({
                type: AppAction.VISIBLE_TRIGGER_ONDEMAND_MODAL,
                payload: true,
              });
              clearForm();
              getTriggerList();
              onCancel && onCancel();
            })
            .catch((error) => {
              if (error?.message.includes('Drip for Smart Trigger does not exist')) {
                formik.setErrors({
                  [KEY_MLS_TRIGGER.sm_trigger_campaign_id]: 'Drip for Smart Trigger does not exist.',
                });
              }
              AlertTextError('Submission Failed', error?.message);
            })
            .finally(() => {
              setIsLoading(false);
              formik.setSubmitting(false);
            });
        }
      } catch (error) {
        // Do nothing
      }
    },
  });

  const formatDataPremium = (values) => {
    const value = {};
    const dateKey = [KEY_MLS_TRIGGER.notice_of_default, KEY_MLS_TRIGGER.divorcees, KEY_MLS_TRIGGER.affidavits_of_death];
    premiumFields.forEach((key) => {
      value[key] = { ...values[key] };
      if (values[key].checked) {
        if (values[key].value) {
          value[key].include = [
            {
              value: dateKey.includes(key) ? formatDate(values[key].value) : values[key].value,
              operator: values[key].operator,
            },
          ];
        } else {
          if (
            [
              KEY_MLS_TRIGGER.notice_of_default,
              KEY_MLS_TRIGGER.affidavits_of_death,
              KEY_MLS_TRIGGER.divorcees,
            ].includes(key)
          ) {
            value[key].include = [
              {
                value: formatDate(EMPTY_DATE_DEFAULT),
                operator: OperatorEnums.GREATER_OR_EQUAL,
              },
            ];
          } else {
            value[key].include = [
              {
                value: 0,
                operator: OperatorEnums.GREATER,
              },
            ];
          }
        }
      } else {
        value[key].include = [];
      }
    });

    return value;
  };

  const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm]);

  useEffect(() => {
    debouncedValidate(formik.values);
  }, [formik.values, debouncedValidate]);

  const getTriggerList = async () => {
    try {
      const res = await SmartActionService.listSmartTriggerAction(
        agentID,
        currentStateSmarTrigger.page || 1,
        limit_smart_trigger,
        currentStateSmarTrigger.search,
        Date.now(),
        params.mailingId,
        params.smartTriggerId,
      );
      dispatch({
        type: UserAction.LIST_TRIGGER,
        payload: res,
      });
    } catch (error) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (!idEditing && !visibleModalSmartTrigger) {
      clearForm();
    }
  }, [visibleModalSmartTrigger, idEditing]);

  const clearForm = () => {
    formik.resetForm();
    setSearchValueDrip('');
  };

  const isValidPastClient = useCallback(() => {
    const mailingLists = formik.values['mailing_lists'];
    if (!Array.isArray(mailingLists) || mailingLists.length === 0) {
      return false;
    }
    const hasPastClient =
      mailingLists?.some((targetId) => {
        return mailingSelected.some((item) => item.value === targetId && item.type === MailingListTypeEnum.Past_Client);
      }) ?? false;
    return hasPastClient;
  }, [formik.values['mailing_lists'], mailingSelected]);

  useEffect(() => {
    if (!idEditing && !visibleModalSmartTrigger) return;
    if (!isValidPastClient()) {
      formik.setValues({
        ...formik.values,
        [KEY_MLS_TRIGGER.is_new_past_client]: false,
      });
    }
  }, [formik.values.mailing_lists, visibleModalSmartTrigger, idEditing]);

  useEffect(() => {
    fetchMLSData();
    setSearchValueDrip('');
  }, []);

  useEffect(() => {
    debounceSearchDripCampaign(searchValueDrip);
  }, [searchValueDrip]);

  useEffect(() => {
    if (!agentID) return;
    detailSmTrigger();
  }, [idEditing]);

  const debouncedFetch = useCallback(
    debounce((values) => {
      fetchListPropertyType(values);
    }, 500),
    [],
  );

  useEffect(() => {
    if (formik.values.is_broker) {
      formik.setFieldValue('trigger_on_demand', false);
      formik.setFieldTouched('automation_broker_landing_page', true);
      formik.setFieldValue('mailing_lists', []);
      formik.setFieldValue('mls_status_filters', []);
    }
  }, [formik.values.is_broker]);

  useEffect(() => {
    if (formik.values.trigger_on_demand) {
      formik.setFieldValue('mls_status', []);
    }
  }, [formik.values.trigger_on_demand]);

  useEffect(() => {
    // Trigger the debounced API calls
    if (isLoadingDetail) return;
    if (!formik.values?.mailing_lists?.length) {
      resetOptions();
    } else {
      debouncedFetch(formik.values.mailing_lists);
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [formik.values.mailing_lists, isLoadingDetail]);

  useEffect(() => {
    if (!isLoading) {
      const updateOptions = [...optionsAudience];
      const updatedOptions = updateOptionsAudience(updateOptions, formik.values.postalytics_audience_ids || []);
      setOptionsAudience(updatedOptions);
    }
  }, [formik.values.postalytics_audience_ids, isLoading]);

  const detailSmTrigger = async () => {
    try {
      setIsLoadingDetail(true);
      if (!idEditing) return;
      const detail = await SmartActionService.getSmartTriggerAction(agentID, idEditing);
      if (detail?.past_clients_type) {
        delete detail.past_clients_type;
      }
      const defaultParams = getDefaultAddressToolMLSFilter(detail);
      const premiumParams = getPremiumDataParams(detail);
      const params = {
        ...defaultParams,
        ...premiumParams,
      };
      Object.entries(detail).forEach(([key]: string[]) => {
        const keys: string[] = [
          KEY_MLS_TRIGGER.num_of_bathrooms,
          KEY_MLS_TRIGGER.num_of_bedrooms,
          KEY_MLS_TRIGGER.estimated_price,
          KEY_MLS_TRIGGER.stories,
          KEY_MLS_TRIGGER.year_built,
          KEY_MLS_TRIGGER.last_sold_date,
          KEY_MLS_TRIGGER.last_sold_price,
          KEY_MLS_TRIGGER.year_since_purchase,
          KEY_MLS_TRIGGER.avg_year_owned,
          KEY_MLS_TRIGGER.square_feet,
          KEY_MLS_TRIGGER.years_owned,
          KEY_MLS_TRIGGER.avm_value,
          KEY_MLS_TRIGGER.home_equity_percentage,

          KEY_MLS_TRIGGER.interest_rate,
          KEY_MLS_TRIGGER.days_on_market,
          KEY_MLS_TRIGGER.price_per_square_foot,
        ];

        if (keys.includes(key)) {
          detail[key].operator = options_operators()?.length ? options_operators()[0] : '';
        }
      });
      setSearchValueDrip(detail?.sm_trigger_campaign?.id ? detail?.sm_trigger_campaign?.name : '');
      formik.setValues({
        ...detail,
        ...params,
        mailing_lists: detail.mailing_lists?.map((v) => v?.id) || [],
        sm_trigger_campaign_id: detail?.sm_trigger_campaign?.id ? detail?.sm_trigger_campaign?.id : '',
        agent_id: detail.agent,
        mls_status: detail?.mls_status || [],
        tags: detail?.tags || [],
        mls_status_filters: detail?.mls_status_filters || [],
        rea_properties: detail?.rea_properties || [],
        postalytics_audience_ids: isArray(detail.postalytics_audience_ids)
          ? detail.postalytics_audience_ids.map((v) => v?.id)
          : [],
        milestone: detail.milestone || [],
      });
      setInitialName(detail.name);
    } catch (error) {
      // Do nothing
    } finally {
      setIsLoadingDetail(false);
    }
  };

  const fetchMLSData = async () => {
    try {
      setIsLoading(true);
      await Promise.all([SmartActionService.getMilestones(), SmartActionService.getPostalyticsAudience()])
        .then(([Milestones, Audience]) => {
          setOptionsAudience(convertObj(Audience));
          setOptionsMilestone(convertObj(Milestones, true));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      // Do nothing
    }
  };

  const convertObj = (data, fillName = false) => {
    if (!data?.length) return [];
    return data.map((v) => {
      return {
        value: fillName ? v.name : v.id,
        label: v.name,
        type: v.type,
      };
    });
  };

  const fetchListPropertyType = async (mailing_lists) => {
    try {
      setIsLoading(true);
      const PropertyType = await SmartActionService.listPropertyType(mailing_lists);
      setPropertyType(PropertyType.property_type);
    } catch (error) {
      // Do nothing
    } finally {
      setIsLoading(false);
    }
  };

  const resetOptions = () => {
    setPropertyType(['RSFR', 'RCON']);
  };

  const parseValue = (key, field = 'temp_value') => {
    if (!formik.values?.[key]?.[field]) return '';
    const value = formik.values?.[key]?.[field];
    if (
      key === KEY_MLS_TRIGGER.home_equity_percentage ||
      key === KEY_MLS_TRIGGER.num_of_bedrooms ||
      key === KEY_MLS_TRIGGER.num_of_bathrooms ||
      key === KEY_MLS_TRIGGER.estimated_price ||
      key === KEY_MLS_TRIGGER.avm_value ||
      key === KEY_MLS_TRIGGER.price_per_square_foot
    ) {
      return Number(value?.replaceAll(/ /g, ''));
    }
    if (key === KEY_MLS_TRIGGER.last_sold_date) {
      return formatDate(value);
    }
    // if (key === KEY_MLS_TRIGGER.year_built) {
    //   moment(formatDate(value)).format('YYYY');
    // }
    if (isString(value)) {
      return value.trim();
    }
    return value;
  };

  const handleAddInput = (key, field, TYPE: enumAddType) => {
    validateHouseProperties.current[key]
      .validate(formik?.values[key])
      .then(function () {
        const temp: FieldDataDetail = { value: parseValue(key) };
        if (formik?.values?.[key]?.operator) {
          temp.operator = formik.values?.[key]?.operator;
          if (key === KEY_MLS_TRIGGER.year_built || key === KEY_MLS_TRIGGER.last_sold_date) {
            if (formik?.values?.[key]?.operator === 'range') {
              temp.value = '';
              temp.start =
                key === KEY_MLS_TRIGGER.last_sold_date
                  ? formatDate(parseValue(key, 'start'))
                  : parseValue(key, 'start');
              temp.end =
                key === KEY_MLS_TRIGGER.last_sold_date ? formatDate(parseValue(key, 'end')) : parseValue(key, 'end');
            }
          }
        }
        const isNewType = !formik.values[key]?.[TYPE];
        const obj = { ...formik.values?.[key], [TYPE]: [...(isNewType ? [] : formik.values[key]?.[TYPE]), temp] };
        if (!formik.values?.[key]['sortIndex']) {
          obj.sortIndex = i;
          i++;
        }
        formik.setValues({
          ...formik.values,
          [key]: obj,
        });
        //reset field
        if (key === KEY_MLS_TRIGGER.year_built || key === KEY_MLS_TRIGGER.last_sold_date) {
          [field, 'start', 'end'].forEach((v) => {
            formik.setFieldValue(`${[key] + '.' + [v]}`, '');
          });
        } else {
          formik.setFieldValue(`${[key] + '.' + [field]}`, '');
        }
      })
      .catch(function (err) {
        if (!err.errors) return;
        if (!formik.values?.[key]?.temp_value && formik.values?.[key]?.operator !== 'range') {
          formik.setFieldError([key], { [field]: validation.required });
          return;
        }
        formik.setFieldError([key], { [field]: err.errors.join('') });
      });
  };
  function getValueDataHouseProperties() {
    const el = Object.assign(dataHouseProperties, {});
    Object.keys(el).forEach((key) => {
      el[key][enumAddType.INCLUDE] = formik?.values[key]?.[enumAddType.INCLUDE];
      el[key][enumAddType.EXCLUDE] = formik?.values[key]?.[enumAddType.EXCLUDE];
      el[key]['sortIndex'] = formik?.values[key]?.['sortIndex'] || 0;
    });

    return Object.entries(el)
      .sort(([, a], [, b]) => (a?.sortIndex || 0) - (b?.sortIndex || 0))
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
  }

  const handleRemoveTag = (key, TYPE) => {
    if (!formik?.values[key]?.[TYPE === enumAddType.INCLUDE ? enumAddType.EXCLUDE : enumAddType.INCLUDE]?.length) {
      formik.setValues({
        ...formik.values,
        [key]: { ...formik.values?.[key], [TYPE]: [], ['sortIndex']: null },
      });
      return;
    }
    formik.setValues({
      ...formik.values,
      [key]: { ...formik.values?.[key], [TYPE]: [] },
    });
  };

  const formatValue = (v, key) => {
    const operator = v.operator;
    const end = v.end;
    const start = v.start;
    let value = v.value;
    let suffix = '';

    if (key === KEY_MLS_TRIGGER.year_built || key === KEY_MLS_TRIGGER.last_sold_date) {
      if (operator === 'range') {
        return `${start} - ${end}`;
      } else {
        return `${operator || ''}${value}`;
      }
    }
    if (
      key === KEY_MLS_TRIGGER.last_sold_price ||
      key === KEY_MLS_TRIGGER.estimated_price ||
      key === KEY_MLS_TRIGGER.price_per_square_foot ||
      key === KEY_MLS_TRIGGER.avm_value
    ) {
      value = formatCurrencySymbol(value, 'USD', true);
    }
    if (key === KEY_MLS_TRIGGER.home_equity_percentage || key === KEY_MLS_TRIGGER.interest_rate) {
      value = formatCurrency(value, true);
      suffix = '%';
    }
    if (
      key === KEY_MLS_TRIGGER.num_of_bathrooms ||
      key === KEY_MLS_TRIGGER.num_of_bedrooms ||
      key === KEY_MLS_TRIGGER.stories ||
      key === KEY_MLS_TRIGGER.year_since_purchase ||
      key === KEY_MLS_TRIGGER.turn_over_rate ||
      key === KEY_MLS_TRIGGER.avg_year_owned ||
      key === KEY_MLS_TRIGGER.years_owned ||
      key === KEY_MLS_TRIGGER.square_feet ||
      key === KEY_MLS_TRIGGER.days_on_market
    ) {
      value = formatCurrency(value, true);
      suffix = '';
    }
    return `${operator || ''}${value}${suffix}`;
  };

  const renderListProperties = () => {
    const obj = getValueDataHouseProperties();
    const el: JSX.Element[] = [];
    Object.keys(obj).forEach((key) => {
      let text;
      if (isArray(obj?.[key]?.[enumAddType.INCLUDE]) && obj?.[key]?.[enumAddType.INCLUDE]?.length > 0) {
        text = obj?.[key]?.[enumAddType.INCLUDE]
          .map((v) => {
            return formatValue(v, key);
          })
          ?.join(', ');
        el.push(
          <div className="tag">
            <strong>+ {obj?.[key]?.name}: </strong>
            {text}
            <img
              src={CloseIcon}
              className="close-ic"
              alt="ic"
              onClick={() => handleRemoveTag(key, enumAddType.INCLUDE)}
            />
          </div>,
        );
      }
      if (isArray(obj?.[key]?.[enumAddType.EXCLUDE]) && obj?.[key]?.[enumAddType.EXCLUDE]?.length > 0) {
        text = obj?.[key]?.[enumAddType.EXCLUDE]
          .map((v) => {
            return formatValue(v, key);
          })
          ?.join(', ');
        el.push(
          <div className="tag exclude">
            <strong>- {obj?.[key]?.name}: </strong>
            {text}
            <img
              src={CloseIcon}
              className="close-ic"
              alt="ic"
              onClick={() => handleRemoveTag(key, enumAddType.EXCLUDE)}
            />
          </div>,
        );
      }
    });
    return el;
  };

  const onChangeField = (key, field, e) => {
    let val;
    if (e?.target?.type === 'checkbox') {
      val = e.target.checked;
    } else if (e?.type === 'change' || e?.target?.type === 'input') {
      val = e.target.value;
    } else {
      val = e;
    }

    if (field) {
      formik.setFieldValue(`${[key] + '.' + [field]}`, val);
    } else {
      formik.setFieldValue(key, val);
    }
  };

  const handleChangePremiumField = (field, key, value) => {
    if (key === 'checked') {
      formik.setFieldValue(field, {
        ...formik.values[field],
        checked: value,
      });
    } else if (key === 'value') {
      formik.setFieldValue(field, {
        ...formik.values[field],
        value: value,
      });
    } else if (key === 'operator') {
      formik.setFieldValue(field, {
        ...formik.values[field],
        operator: value,
      });
    }
  };

  const isDisabledMLSTrigger = (field) => {
    if (!formik.values[KEY_MLS_TRIGGER.mls_status]?.length && !formik.values[KEY_MLS_TRIGGER.milestone]?.length) {
      return false;
    }
    if (!formik.values[field]?.length) return true;
    return false;
  };

  const debounceSearchDripCampaign = useCallback(
    debounce(async (value = '') => {
      setLoadingMore(true);
      if (value === '') {
        setHasMoreItems(true);
      }
      // setOptionsDripCampaign([]);
      try {
        const res = await SmartActionService.getSmartTrigger(agentID, 1, limit_drip_campaign, value?.trim());
        setHasMoreItems(res.links?.next ? true : false);
        setOptionsDripCampaign(res?.results || []);
        setCurrentPageDrip(2);
      } catch (error) {
        // Do nothing
      } finally {
        setLoadingMore(false);
      }
    }, 500),
    [],
  );
  const handleInfiniteLoad = async () => {
    if (loadingMore || !hasMoreItems) return;
    setLoadingMore(true);
    try {
      const res = await SmartActionService.getSmartTrigger(agentID, currentPageDrip, limit_drip_campaign);
      setHasMoreItems(res.links?.next ? true : false);
      setOptionsDripCampaign((prev) => [...prev, ...res?.results]);
      setCurrentPageDrip((prev) => prev + 1);
    } catch (error) {
      // Do nothing
    } finally {
      setLoadingMore(false);
    }
  };

  const onUpdateBrokerConfiguration = (newBrokerConfiguration: BrokerConfigFormType) => {
    formik.setValues({
      ...formik.values,
      ...newBrokerConfiguration,
    });
  };

  const checkIsSelectMylisting = (mailing_lists, mailingOptions) => {
    return !!mailingOptions
      .filter((v) => mailing_lists?.some((mailingList) => mailingList === v.id))
      .find((v) => v.type === MailingListTypeEnum.My_Listing);
  };

  const isSelectMyListing = useMemo(
    () => checkIsSelectMylisting(formik.values.mailing_lists, mailingOptions),
    [formik.values.mailing_lists, mailingOptions],
  );

  return {
    isLoading,
    setIsLoading,
    formik,
    options_operators,
    mailingSelected: uniqBy(mailingSelected, 'value'),
    optionsAudience,
    optionsMilestone,
    optionsDripCampaign,
    handleAddInput,
    renderListProperties,
    options_mls_status,
    options_age_range,
    options_property_type,
    idEditing,
    isValidPastClient,
    onChangeField,
    isDisabledMLSTrigger,
    searchValueDrip,
    setSearchValueDrip,
    isLoadingDetail,
    handleInfiniteLoad,
    loadingMore,
    isDuplicateItem,
    mlsFirstLoadParams,
    getAddressesParams,
    setMailingSelected,
    mailingOptions,
    setMailingOptions,
    visibleModalSmartTrigger,
    handleChangePremiumField,
    formatDataPremium,
    onUpdateBrokerConfiguration,
    options_rea_properties,
    tagList,
    debounceSearchDripCampaign,
    checkIsSelectMylisting,
    isSelectMyListing,
  };
};

export const getDefaultAddressToolMLSFilter = (response) => {
  const isRealtor =
    response.send_to === SEND_ADDRESS.SEND_ADDRESSES_ALL
      ? SEND_ADDRESS.SEND_ADDRESSES_ALL
      : response.send_to === SEND_ADDRESS.SEND_ADDRESSES_REALTOR
      ? SEND_ADDRESS.SEND_ADDRESSES_REALTOR
      : response.send_to === SEND_ADDRESS.SEND_ADDRESSES_NOT_REALTOR
      ? SEND_ADDRESS.SEND_ADDRESSES_NOT_REALTOR
      : response.send_to === SEND_ADDRESS.SEND_ADDRESSES_OWNER
      ? SEND_ADDRESS.SEND_ADDRESSES_OWNER
      : SEND_ADDRESS.SEND_ADDRESSES_RENTER;

  const filterState = response.view_mode || VIEW_MODE.VIEW_MODE_ALL;

  const ownerOccupied =
    response.view_owner_occupied === VIEW_OWNER_OCCUPIED.VIEW_OWNER_OCCUPIED_ALL
      ? undefined
      : response.view_owner_occupied === VIEW_OWNER_OCCUPIED.VIEW_OWNER_OCCUPIED
      ? true
      : false;

  const ownerOutOfState =
    response.view_owner_out_of_state === VIEW_OWNER_OUT_OF_STATE.OWNER_OUT_OF_STATE_ALL
      ? undefined
      : response.view_owner_out_of_state === VIEW_OWNER_OUT_OF_STATE.OWNER_OUT_OF_STATE_OUT
      ? true
      : false;

  const textSearch = response.search || '';

  const params = {
    ...response,
    postalytics_audience_ids: response?.postalytics_audience_ids
      ? response?.postalytics_audience_ids?.map((v) => v.id)
      : [],
    agent_id: response.agent,
    mailing_lists: response.mailing_lists?.map((v) => v.id),
    name: response?.name || 'MLS Default Name',
    sm_trigger_campaign_id: response.sm_trigger_campaign.id,

    // This one to fetch all data that not include these filters. All these filter will be applied on locally
    is_realtor: undefined,
    owner_occupied: undefined,
    search: '',
    owner_out_of_state: undefined,

    // Default when request new addresses for local filter in Address Selector tool
    isRealtor: isRealtor,
    filterState: filterState,
    ownerOccupied: ownerOccupied,
    ownerOutOfState: ownerOutOfState,
    textSearch: textSearch,
  } as any;

  return params;
};

export const getPremiumDataParams = (data) => {
  const initialPremiumData = {} as any;

  premiumFields.forEach((key) => {
    if (!isEmpty(data[key].include[0])) {
      if (
        [KEY_MLS_TRIGGER.notice_of_default, KEY_MLS_TRIGGER.divorcees, KEY_MLS_TRIGGER.affidavits_of_death].includes(
          key,
        ) &&
        formatDate(data[key].include[0].value) === formatDate(EMPTY_DATE_DEFAULT)
      ) {
        initialPremiumData[key] = {
          ...data[key],
          checked: true,
          value: '',
          operator: OperatorEnums.EQUAL,
        };
      } else {
        initialPremiumData[key] = {
          ...data[key],
          checked: true,
          value: data[key].include[0].value || '',
          operator: data[key].include[0].operator,
        };
      }
    } else {
      initialPremiumData[key] = {
        ...data[key],
        ...base_house_properties_obj,
        checked: false,
        value: '',
        operator: OperatorEnums.EQUAL,
      };
    }
  });
  return initialPremiumData;
};
