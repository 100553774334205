import theme from '@/styles/theme';
import styled from '@emotion/styled';

const HeaderViewStyled = styled.header`
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1000;
  .nav-bar {
    width: 100%;
    height: 100%;
    display: flex;
    width: 100%;
    height: 112px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: ${theme.color.DARKBLUE};
    color: ${theme.color.WHITE};
    @media only screen and (max-width: ${theme.media.desktops}px) {
      height: 80px;
    }
    @media only screen and (max-width: ${theme.media.tablets}px) {
      height: 64px;
      padding-left: 0px;
    }
    > .brand {
      position: relative;
      z-index: 101;
      display: flex;
      padding: 20px 20px 20px 40px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: letter-spacing 600ms ease;
      transition: letter-spacing 600ms ease;
      color: ${theme.color.WHITE};
      font-weight: 400;
      letter-spacing: 9px;
      text-decoration: none;
      @media only screen and (max-width: ${theme.media.desktops}px) {
        padding-right: 8px;
        padding-left: 24px;
      }
      @media only screen and (max-width: ${theme.media.tablets}px) {
        padding: 10px 20px;
      }
      &:hover {
        letter-spacing: 12px;
      }
      .logo {
        max-width: 240px;
        @media only screen and (max-width: ${theme.media.desktops}px) {
          max-width: 220px;
        }
        @media only screen and (max-width: ${theme.media.tablets}px) {
          width: 140px;
        }
      }
      .logo-text {
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;
        text-transform: uppercase;
        @media only screen and (max-width: ${theme.media.desktops}px) {
          font-size: 16px;
          line-height: 1.2;
          letter-spacing: 8px;
        }
        @media only screen and (max-width: ${theme.media.tablets}px) {
          font-size: 15px;
          font-weight: 600;
          letter-spacing: 4px;
        }
        @media only screen and (max-width: ${theme.media.phones}px) {
          font-size: 14px;
        }
      }
      .logo-description {
        margin-left: 24px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 16px;
        border-left: 1px solid hsla(0, 0%, 100%, 0.3);
        font-size: 9px;
        line-height: 16px;
        letter-spacing: 2.2px;
        text-transform: uppercase;
        @media only screen and (max-width: ${theme.media.desktops}px) {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
        }
        @media only screen and (max-width: ${theme.media.tablets}px) {
          display: none;
        }
      }
    }
    .navigation {
      position: relative;
      z-index: 10;
      display: flex;
      height: 100%;
      padding-right: 24px;
      padding-left: 20px;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      @media only screen and (max-width: ${theme.media.desktops}px) {
        padding-right: 8px;
      }
      @media only screen and (max-width: ${theme.media.tablets}px) {
        padding-right: 0px;
        padding-left: 0px;
      }
      @media only screen and (max-width: ${theme.media.phones}px) {
        padding-left: 0px;
      }
      > .nav-bar-info {
        position: relative;
        z-index: 101;
        display: flex;
        padding-right: 16px;
        padding-left: 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;
        @media only screen and (max-width: ${theme.media.desktops}px) {
          padding-right: 8px;
          padding-left: 8px;
        }
        @media only screen and (max-width: ${theme.media.tablets}px) {
          padding-right: 0px;
          padding-left: 0px;
        }
        @media only screen and (max-width: ${theme.media.phones}px) {
          display: none;
        }
        > .nav-bar-contacts {
          font-size: 11px;
          line-height: 20px;
          text-align: right;
          @media only screen and (max-width: ${theme.media.desktops}px) {
            padding-right: 24px;
            padding-left: 12px;
            flex: 0 0 auto;
          }
          @media only screen and (max-width: ${theme.media.phones}px) {
            padding-right: 16px;
            flex: 0 0 auto;
          }
        }
        .ant-btn {
          color: ${theme.color.WHITE};
          opacity: 1;
        }
        .ant-image {
          overflow: hidden;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
        .nav-bar-credit,
        .nav-bar-home {
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 10px;
          border: 1px solid ${theme.color.PINK};
          color: ${theme.color.PINK};
          font-weight: 500;
          font-size: 16px;
          min-width: 125px;
          text-align: center;
        }
      }
      .w-nav-button {
        position: relative;
        float: right;
        padding: 18px;
        font-size: 24px;
        display: block;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        tap-highlight-color: rgba(0, 0, 0, 0);
        user-select: none;
      }
      > .menu-button {
        z-index: 10;
        width: 56px;
        height: 56px;
        padding: 15px 16px;
        border-radius: 50%;
        color: ${theme.color.WHITE};
        font-weight: 700;
        @media only screen and (max-width: ${theme.media.desktops}px) {
          width: 56px;
          height: 56px;
          padding: 15px 16px;
        }
        &.w-clearfix:before,
        &.w-clearfix:after {
          content: ' ';
          display: table;
          grid-column-start: 1;
          grid-row-start: 1;
          grid-column-end: 2;
          grid-row-end: 2;
        }
        &.w-clearfix:after {
          clear: both;
        }

        > .menu-icon {
          width: 24px;
          padding-top: 8px;
          padding-bottom: 8px;
          float: right;
          flex-direction: column;
          justify-content: center;
          align-items: stretch;
          > .menu-icon-line-top {
            height: 2px;
            margin-bottom: 6px;
            background-color: ${theme.color.WHITE};
          }
          > .menu-icon-line-bottom {
            height: 2px;
            background-color: ${theme.color.WHITE};
          }
        }
        &.w--open {
          background-color: transparent;
          > .menu-icon {
            > .menu-icon-line-top {
              transform: translate3d(0px, 4px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg)
                skew(0deg, 0deg);
              transform-style: preserve-3d;
              transition: all 400ms ease;
            }
            > .menu-icon-line-bottom {
              transform: translate3d(0px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg)
                skew(0deg, 0deg);
              transform-style: preserve-3d;
              transition: all 400ms ease;
            }
          }
        }
      }
    }

    nav.nav-menu {
      position: fixed;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: 0%;
      z-index: 8;
      height: 100vh;
      max-height: 100vh;
      padding-top: 112px;
      padding-right: 40px;
      padding-left: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: ${theme.color.DARKBLUE};
      transform: translateY(-100%);
      transition: transform 800ms cubic-bezier(0.77, 0, 0.175, 1) 0s;
      @media only screen and (max-width: ${theme.media.desktops}px) {
        padding-right: 32px;
        padding-left: 32px;
      }
      @media only screen and (max-width: ${theme.media.tablets}px) {
        padding-right: 16px;
        padding-left: 16px;
      }
      &.w--open {
        display: flex;
        transform: translateY(0px);
        transition: transform 800ms cubic-bezier(0.77, 0, 0.175, 1) 0s;
      }
      .nav-menu-links {
        position: relative;
        display: flex;
        min-height: 100vh;
        padding-bottom: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .nav-link {
          position: relative;
          overflow: hidden;
          margin-right: auto;
          margin-left: auto;
          padding: 16px 0px;
          justify-content: center;
          align-items: center;
          transition: color 600ms ease;
          color: ${theme.color.WHITE_1};
          font-size: 4.5vh;
          line-height: 5.5vh;
          text-align: center;
          letter-spacing: 0.7vh;
          text-decoration: none;
          text-transform: uppercase;
          @media only screen and (max-width: ${theme.media.desktops}px) {
            font-size: 40px;
            line-height: 56px;
          }
          @media only screen and (max-width: ${theme.media.tablets}px) {
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 32px;
            line-height: 48px;
          }
          &.w-inline-block {
            display: inline-block;
          }
          .nav-link-content {
            position: relative;
            display: inline-block;
            padding-right: 4px;
            padding-left: 8px;
            .link-highlight {
              position: absolute;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 1;
              display: block;
              width: 0px;
              height: 3px;
              margin-right: auto;
              margin-left: 0px;
              background-color: ${theme.color.PINK};
              transition: width 0.3s;
            }
            &:hover {
              .link-highlight {
                width: 100%;
              }
            }
          }
        }
        .active {
          color: ${theme.color.PINK};
        }
      }
    }
  }
`;

export default HeaderViewStyled;
