import React, { memo, useCallback, useMemo } from 'react';
import { navigate } from 'gatsby';
import { useLocation, useMatch } from '@reach/router';
import RoutePaths from '@/utils/routes';
import useDisableBySubscription from '@/hooks/useDisableBySubscription';
import AppButtonV2 from '../AppButtonV2';
import isEqual from 'react-fast-compare';

const AppButtonSwitch: React.FC = () => {
  const match = useMatch(`${RoutePaths.MAILING_LISTS}/:id`);
  const location = useLocation();

  const { isDisableBySubscription } = useDisableBySubscription();

  const isDashboard = useMemo(() => !location?.pathname.includes(RoutePaths.AGENT_REPORT), [location?.pathname]);

  const handleNavigate = useCallback(() => {
    if (isDisableBySubscription) return;

    let url = '';
    if (isDashboard) {
      url = match?.id?.length ? `${RoutePaths.AGENT_REPORT}/${match.id}` : RoutePaths.AGENT_REPORT;
    } else {
      url = RoutePaths.DASHBOARD;
    }
    navigate(url);
  }, [isDashboard, match, isDisableBySubscription]);

  return (
    <AppButtonV2
      color="DarkBlue"
      size="large"
      width="200px"
      disabled={isDashboard && isDisableBySubscription}
      htmlType="submit"
      style={{
        position: 'fixed',
        zIndex: 222,
        bottom: 20,
        left: 40,
      }}
      onClick={handleNavigate}
    >
      {!isDashboard ? 'Dashboard' : 'Agent Report'}
    </AppButtonV2>
  );
};

export default memo(AppButtonSwitch, isEqual);
