import React from 'react';
import ModalCreateUpdateBrokerLandingPageView from './ModalCreateUpdateBrokerLandingPageView';

export interface ModalCreateUpdateBrokerLandingPageProps {
  visible: boolean;
  onCancel?: () => void;
}

const ModalCreateUpdateBrokerLandingPage: React.FC<ModalCreateUpdateBrokerLandingPageProps> = (props) => {
  return <ModalCreateUpdateBrokerLandingPageView {...props} />;
};

export default ModalCreateUpdateBrokerLandingPage;
