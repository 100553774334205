import { MailingList, ReportStatusEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { isEmpty, omit } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import UserAvatar from '@/components/Avatar';
import ProgressBar from '@/components/progress-bar';
import globalVariable from '@/config/env';
import { useQueryParam } from '@/hooks/useQueryParam';
import { RootState } from '@/reducers/model';
import { formatCurrencySymbol } from '@/utils/currency';
import { UserType } from '@/utils/enum';
import RoutePaths from '@/utils/routes';
import DisclaimerTooltip from '../disclaimer-tooltip';
import UserInfoStyled from './UserInfoStyled';
import AppButtonV2 from '../AppButtonV2';

interface UserInfoProps {
  className?: string;
}

const UserInfo: React.FC<UserInfoProps> = React.memo(({ className }) => {
  const loggedInAgent = useSelector((state: RootState) => state.home.userAgent);
  const userAgentSSID = useSelector((state: RootState) => state.home.userAgentSSID);

  const { ssid, user } = useQueryParam<{ ssid: string; user: string }>();
  const myProfile = useSelector((state: RootState) => state.auth.myProfile);
  const mailingListDetail = useSelector<RootState>((state) => state.mailing.mailingListDetail) as MailingList;
  const mailingListsCombine = useSelector((state: RootState) => state.mailing.mailingListsCombine);

  const [errorAvatar, setErrorAvatar] = useState<boolean>(false);
  const location = useLocation();
  const isManager = myProfile?.user_type === UserType.MANAGER;

  const getDataAgentByRole = () => {
    const dataAgent = isManager ? userAgentSSID : loggedInAgent;

    // FAKE: sync agent data for manager role or agent role
    const final = {
      ...dataAgent,
      slipstream_submission: {
        ...omit(dataAgent, 'id'),
        agent_id: dataAgent.id,
      },
    };
    return final;
  };

  const dataAgent = getDataAgentByRole();

  const handleRedirect = () => {
    if (isManager) {
      navigate(RoutePaths.MANAGER);
    } else {
      navigate(
        mailingListsCombine?.results[0]
          ? `${RoutePaths.AGENT_REPORT}/${mailingListsCombine?.results[0]?.id}`
          : RoutePaths.AGENT_REPORT,
      );
    }
  };

  const matchOnURLSegment = (): string | null => {
    const pathParts = location.pathname.split('/');
    const id = pathParts[2];
    return id || null;
  };

  const isRoutePath = (routePath) => {
    const pattern = new RegExp(routePath);
    return pattern.test(location.pathname);
  };

  const isValidDownload = () => {
    let isReport = true;
    if (isManager) {
      if (!ssid) return true;
      if (isRoutePath(RoutePaths.MY_REPORT)) {
        isReport = !!myProfile?.has_mailing_list;
      }
      if (isRoutePath(RoutePaths.AGENT_REPORT)) {
        isReport = mailingListDetail?.report_status === ReportStatusEnum.Completed;
      }
    } else {
      isReport = mailingListDetail?.report_status === ReportStatusEnum.Completed;
    }
    if (!matchOnURLSegment() || !isReport) {
      return true;
    }
    return false;
  };

  const getIdManagedSubmission = (myProfile, dataAgent) => {
    let idSubmission;
    if (isManager) {
      if (!ssid) return null;
      if (isRoutePath(RoutePaths.MY_REPORT)) {
        idSubmission = myProfile?.submission_id;
      }
      if (isRoutePath(RoutePaths.AGENT_REPORT)) {
        idSubmission = dataAgent?.slipstream_submission?.user_agent?.submission_id;
      }
    } else {
      idSubmission = myProfile?.submission_id;
    }

    return idSubmission;
  };

  const renderButtonDownload = () => {
    const idManagedSubmission = getIdManagedSubmission(myProfile, dataAgent);
    if (ssid) {
      return (
        <AppButtonV2
          disabled={!idManagedSubmission || isValidDownload()}
          size="small"
          width="145px"
          color="White"
          onClick={() => {
            const url = `${
              globalVariable.API_URL
            }/slipstream/submission/${idManagedSubmission}/report-download/?user_id=${
              myProfile?.id
            }&is_mailing_list=true&mailing_list_id=${matchOnURLSegment()}`;
            window.open(url, '_blank');
          }}
        >
          Download
        </AppButtonV2>
      );
    } else {
      return (
        <AppButtonV2
          disabled={isValidDownload()}
          size="small"
          width="145px"
          color="White"
          onClick={() => {
            const url = `${globalVariable.API_URL}/slipstream/submission/${
              myProfile?.submission_id
            }/report-download/?user_id=${myProfile?.id}&is_mailing_list=true&mailing_list_id=${matchOnURLSegment()}`;
            window.open(url, '_blank');
          }}
        >
          Download
        </AppButtonV2>
      );
    }
  };

  if (isEmpty(dataAgent)) return null;

  return (
    <UserInfoStyled className={className}>
      <div className="user-info__l">
        <UserAvatar
          src={
            myProfile?.user_type === UserType.MANAGER
              ? dataAgent?.slipstream_submission?.user_agent?.avatar
              : errorAvatar
              ? undefined
              : myProfile?.avatar
          }
          name={`${myProfile?.first_name} ${myProfile?.last_name}`}
          color="#2e0249"
          size={180}
          radius={1000}
          className="img-user"
          backgroundColor="#e1e1df"
          handleRedirect={handleRedirect}
          handleError={() => setErrorAvatar(true)}
        />

        <div className="agent hidden-desktop">
          <h2 className="agent-name">
            {myProfile?.first_name} {myProfile?.last_name}
          </h2>
          <h2 className="agent-des">Home Meta Agent</h2>
        </div>
      </div>
      <div className="user-info__r">
        <div className="user-wrapper">
          <div>
            <h2 className="agent-name hidden-mobile">{dataAgent?.name}</h2>
            <h4>
              DRE # <span className="bold-text">{dataAgent?.slipstream_submission?.license}</span> | 12 Month Volume:
              <span className="bold-text">
                {' '}
                {formatCurrencySymbol(dataAgent?.slipstream_submission?.last_12_month_volume, 'USD', true)}
              </span>{' '}
              | Units:
              <span className="bold-text"> {dataAgent?.slipstream_submission?.last_12_month_units} </span> | User:
              <span className="bold-text">
                {' '}
                {dataAgent?.slipstream_submission?.user_agent?.first_name}{' '}
                {dataAgent?.slipstream_submission?.user_agent?.last_name}
              </span>
            </h4>
          </div>

          <div className="user-info-button">
            <DisclaimerTooltip
              title={
                !mailingListDetail?.last_crawling_date
                  ? `No latest updates.`
                  : `The ${mailingListDetail?.name} list is updated monthly. The next update is scheduled for ${moment(
                      mailingListDetail?.last_crawling_date,
                    )
                      .clone()
                      .add(1, 'months')
                      .format('MMMM D, YYYY')}`
              }
              placement="bottom"
            />
            {renderButtonDownload()}
          </div>
        </div>
        <div className="div-bottom">
          <div className="level-progress-bar">
            <strong>Level:</strong>
            <ProgressBar data={dataAgent?.slipstream_submission?.levels_breakdown} />
          </div>
          {isManager && !user && (
            <AppButtonV2
              size="small"
              width="240px"
              color="Pin"
              onClick={() => {
                navigate(RoutePaths.CREATE_MAILING_MANAGER);
              }}
            >
              Create a New Mailing List
            </AppButtonV2>
          )}
        </div>
      </div>
    </UserInfoStyled>
  );
}, isEqual);

export default UserInfo;
