import React, { memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import ModalCreateUpdateBrokerLandingPage from '../custom-modals/modal-create-update-broker-landing-page';
import AppButtonV2 from '../AppButtonV2';

interface BrokerLandingPageBuilderButtonProps {
  label?: string;
}

const BrokerLandingPageBuilderButton: React.FC<BrokerLandingPageBuilderButtonProps> = memo(
  ({ label = 'Create / Update Broker Landing Page' }) => {
    const [visible, setVisible] = useState(false);
    return (
      <div className="create_update_broker-landing-page-wrapper">
        <AppButtonV2
          width="384px"
          size="large"
          color="Pin"
          fontSize={14}
          uppercase={false}
          style={{ marginTop: 30 }}
          onClick={() => setVisible(true)}
        >
          {label}
        </AppButtonV2>
        <ModalCreateUpdateBrokerLandingPage visible={visible} onCancel={() => setVisible(false)} />
      </div>
    );
  },
  isEqual,
);

export default BrokerLandingPageBuilderButton;
