import styled from '@emotion/styled';

const UserInfoStyled = styled.section`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  @media (max-width: ${({ theme }) => theme.media.tablets}px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .bold-text {
    color: #333;
    font-weight: 800;
  }
  .hidden-mobile {
    @media (max-width: ${({ theme }) => theme.media.tablets}px) {
      display: none;
    }
  }
  .hidden-desktop {
    display: none;
    @media (max-width: ${({ theme }) => theme.media.tablets}px) {
      display: flex;
    }
  }
  h2.agent-name {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 36px;
    font-weight: 700;
    font-family: FiraSans, sans-serif;
    @media (max-width: ${({ theme }) => theme.media.tablets}px) {
      font-size: 24px;
    }
    @media (max-width: ${({ theme }) => theme.media.phones}px) {
      border-bottom: 1px solid #f1f1f1;
    }
  }
  .user-info {
    &__l {
      margin-right: 40px;
      display: flex;
      @media (max-width: ${({ theme }) => theme.media.tablets}px) {
        align-items: center;
        margin-right: 0px;
        margin-bottom: 20px;
      }
      @media (max-width: ${({ theme }) => theme.media.phones}px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .img-user {
        width: 180px;
        border-radius: 50%;
        box-shadow: none;
        -o-object-fit: cover;
        object-fit: cover;
        font-size: 5rem !important;
        cursor: pointer;
        @media (max-width: ${({ theme }) => theme.media.tablets}px) {
          width: 120px !important;
          margin-right: 30px !important;
        }
        @media (max-width: ${({ theme }) => theme.media.phones}px) {
          margin-right: 0px !important;
          width: 180px !important;
          margin-bottom: 10px !important;
        }
      }
      .agent {
        flex-direction: column;
        > .agent-name {
          margin: 0px;
        }
        > .agent-des {
          font-weight: 300;
        }
      }
    }
    &__r {
      flex: 1;
      h4 {
        justify-content: flex-start;
        align-items: center;
        color: #666;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        @media (max-width: ${({ theme }) => theme.media.desktops}px) {
          font-weight: 600;
        }
        @media (max-width: ${({ theme }) => theme.media.tablets}px) {
          font-size: 16px;
          line-height: 1.2;
        }
        @media (max-width: ${({ theme }) => theme.media.phones}px) {
          text-align: center;
        }
      }
      .user-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media (max-width: ${({ theme }) => theme.media.phones}px) {
          flex-direction: column;
        }
        .user-info-button {
          display: flex;
          button:first-of-type {
            margin-bottom: 5px;
          }
          svg {
            width: 30px;
            height: 30px;
            margin: 0 10px 0 0;
          }
          @media (max-width: ${({ theme }) => theme.media.XLdesktops}px) {
            flex-direction: column;
          }
          @media (max-width: ${({ theme }) => theme.media.phones}px) {
            flex-direction: row;
            margin: 0 auto;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .div-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    padding-top: 10px;
    .level-progress-bar {
      display: flex;
      align-items: center;
      color: #333;
      flex: 1;
      @media (max-width: ${({ theme }) => theme.media.phones}px) {
        border: none;
      }
      strong {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        text-transform: capitalize;
        margin-right: 20px;
        font-family: FiraSans, sans-serif;
        @media (max-width: ${({ theme }) => theme.media.phones}px) {
          display: none;
        }
      }
    }
  }
`;

export default UserInfoStyled;
