import icBlackClose from '@/assets/icons/black-close-icon.svg';
import icWhiteClose from '@/assets/images/white-close-icon.svg';
import { AlertTextError } from '@/components/alert/SweetAlert';
import useGetConstantValueQuery from '@/queries/settings/useGetConstantValue';
import { AppSizeType, color } from '@/styles/theme';
import RoutePaths from '@/utils/routes';
import { splitString } from '@/utils/utils';
import { css } from '@emotion/react';
import { Skeleton } from 'antd';
import { navigate } from 'gatsby';
import { get } from 'lodash';
import Papa from 'papaparse';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AppButtonV2 from '../AppButtonV2';
import AppInputSearch from '../AppInputSearch';
import { ButtonColor } from '../AppButtonV2/types';

interface SearchFieldProps {
  isSearchByInput?: boolean;
  isSearchByFile?: boolean;
  getSearchValue?: (v: string) => void;
  getSearchFile?: (file: any) => void;
  setVisible?: (v: boolean) => void;
  visible?: boolean;
  isManagerPage?: boolean;
  searchValue?: string;
  isLoading?: boolean;
  isCampaigns?: boolean;
  isReadOnly?: boolean;
  isProspect?: boolean;
  placeholder?: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement>;
  allowClear?: boolean;
  searchDisabled?: boolean;
  className?: string;
  acceptFiles?: string;
  isError?: boolean;
  isOnlyCsvFile?: boolean;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  size?: AppSizeType;
  fileButtonColor?: ButtonColor;
}

const SearchField: React.FC<SearchFieldProps> = ({
  isSearchByFile,
  isSearchByInput,
  getSearchValue,
  getSearchFile,
  setVisible,
  visible,
  isManagerPage,
  searchValue,
  isLoading,
  isCampaigns,
  isReadOnly,
  isProspect,
  placeholder,
  handleBlur,
  allowClear,
  searchDisabled,
  className,
  acceptFiles = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  isOnlyCsvFile = false,
  isError,
  onPressEnter,
  size,
  fileButtonColor,
}) => {
  const { data } = useGetConstantValueQuery();
  const importMaxRows = useMemo(() => data?.import_max_rows, [data?.import_max_rows]);
  // const importMaxRows = useSelector((state: RootState) => state?.user?.userAssets?.import_max_rows);

  const [selectedFileName, setSelectedFileName] = useState<any>(null);
  const inputFileRef = useRef<any>(null);

  const cbError = (message: string) => {
    AlertTextError('Upload Error', message);
  };

  const handleSelectedFile = async (event) => {
    if (!event.target.value) return;
    if (isOnlyCsvFile) {
      const regex = new RegExp('(.*?).(csv)$');
      if (!regex.test(event.target.value.toLowerCase())) {
        AlertTextError('Upload Error', 'CSV Invalid, please check again.');
        event.target.value = '';
        return;
      }
    }
    Papa.parse(event.target.files[0], {
      header: true,
      dynamicTyping: true,
      complete: function (results) {
        if (results?.data?.length <= (importMaxRows || 1000)) {
          getSearchFile && getSearchFile(event.target.files[0]);
          setSelectedFileName(splitString(get(event, 'target.files[0].name', '')));
        } else {
          const message =
            'Your file took too long to process and timed out. Please try again and consider uploading a smaller file.';
          cbError(message);
        }
        event.target.value = '';
      },
    });
  };

  const onBtnClick = () => {
    if (!inputFileRef?.current) return;
    inputFileRef?.current?.click();
  };

  useEffect(() => {
    if (isError) {
      setSelectedFileName(null);
    }
  }, [isError]);

  return (
    <div
      id="search"
      className={className ? `search-wrapper ${className}` : 'search-wrapper'}
      css={css`
        margin-top: 30px;
        .search-input {
          position: relative;
        }
        .ant-image {
          position: absolute;
          top: 12px;
          left: 20px;
          z-index: 10;
        }
        .search {
          input {
            border-radius: 27px;
            background-color: #fff;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            padding-right: 22px;
          }
          input:read-only {
            cursor: not-allowed;
          }
          .ant-input-affix-wrapper {
            height: fit-content;
            padding: ${allowClear ? (!isCampaigns ? '0 10px 0 50px' : '0 10px 0 0') : '0 10px 0 0'};
            border-radius: 5px;
            border-color: ${color.DARKBLUE} !important;
            &:focus,
            &.ant-input-affix-wrapper-focused {
              box-shadow: none !important;
            }
            input {
              padding-right: 0;
              border: ${allowClear ? 'none !important' : 'initial'};
              &:focus {
                border: none;
              }
            }
            .ant-input-clear-icon {
              margin-right: ${!isCampaigns ? '0' : '14px'};
            }
          }
        }
        .search-file {
          h3 {
            color: ${isManagerPage ? '#2e0249' : '#fff'};
            font-size: 17px;
            font-weight: 700;
            span {
              font-weight: 400;
            }
          }
          .upload-file {
            display: flex;
            justify-content: space-between;
            .selected-file {
              display: flex;
              justify-content: center;
              align-items: center;
              h3 {
                margin: 0;
              }
              p {
                margin-top: 5px;
              }
              img {
                margin-left: 20px;
                cursor: pointer;
              }
              .ant-skeleton-active {
                .ant-skeleton-content {
                  .ant-skeleton-title {
                    background: linear-gradient(
                      90deg,
                      rgba(190, 190, 190, 0.9) 25%,
                      #2196f3 37%,
                      rgba(190, 190, 190, 0.9) 63%
                    );
                    background-size: 400% 100%;
                    -webkit-animation: ant-skeleton-loading 2s ease infinite;
                    animation: ant-skeleton-loading 2s ease infinite;
                    height: 5px;
                  }
                }
              }
            }
          }
          .left-button {
            margin-left: 0;
          }
        }
      `}
    >
      {isSearchByInput && (
        <div className="search-input">
          <AppInputSearch
            label=""
            type="string"
            placeholder={
              placeholder
                ? placeholder
                : isReadOnly
                ? 'Enter New Card'
                : isCampaigns
                ? 'Search and select an agent...'
                : 'Search for Agent Name or Email'
            }
            onChange={(e) => {
              getSearchValue && getSearchValue(e.target.value);
            }}
            value={searchValue}
            onInput={() => setVisible && setVisible(true)}
            onClick={(e) => {
              if (visible && isManagerPage) {
                e.stopPropagation();
              } else if (!visible && isManagerPage) {
                e.preventDefault();
              }
            }}
            readOnly={isReadOnly}
            onBlur={handleBlur}
            allowClear={allowClear}
            disabled={searchDisabled}
            onPressEnter={onPressEnter}
            size={size}
          />
        </div>
      )}
      {isSearchByFile && (
        <div className="search-file">
          <h3>Upload File</h3>
          <div className="upload-file">
            <div className="l">
              <input
                type="file"
                name="file"
                onChange={handleSelectedFile}
                style={{ display: 'none' }}
                ref={inputFileRef}
                accept={acceptFiles}
              />
              <AppButtonV2
                color={fileButtonColor || 'DarkBlue'}
                width="183px"
                letter="none"
                className="btn"
                onClick={onBtnClick}
              >
                Select File
              </AppButtonV2>
            </div>
            {selectedFileName ? (
              <div className="selected-file">
                <div>
                  <h3>Selected file:</h3>
                  <p>{selectedFileName}</p>
                  <Skeleton active paragraph={false} loading={isLoading} />
                </div>
                <img
                  src={isManagerPage ? icBlackClose : icWhiteClose}
                  alt="close"
                  onClick={() => {
                    setSelectedFileName(null);
                  }}
                  className="ic-close"
                />
              </div>
            ) : isProspect ? (
              <AppButtonV2
                width="210px"
                className="prospect-lookup"
                letter="small"
                onClick={() => {
                  navigate(RoutePaths.PROSPECT_LOOKUP);
                }}
              >
                Prospect Lookup
              </AppButtonV2>
            ) : null}
          </div>
          {selectedFileName && isProspect && (
            <div style={{ marginTop: '15px' }}>
              <AppButtonV2
                letter="small"
                width="210px"
                className="prospect-lookup left-button"
                onClick={() => {
                  navigate(RoutePaths.PROSPECT_LOOKUP);
                }}
              >
                Prospect Lookup
              </AppButtonV2>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default SearchField;
