import { color } from '@/styles/theme';
import React from 'react';
import { BiSearch } from 'react-icons/bi';
import AppInputV2 from '../AppInputV2';
import { AppInputV2Props } from '../AppInputV2/AppInputV2View';

interface AppInputSearchProps extends AppInputV2Props {
  iconSize?: number;
}
const AppInputSearch = (props: AppInputSearchProps) => {
  const { iconSize = 25, ...rest } = props;
  return <AppInputV2 prefix={<BiSearch size={iconSize} color={color.GRAY_9} />} {...rest} />;
};

export default AppInputSearch;
