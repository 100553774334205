import { css, useTheme } from '@emotion/react';
import React, { useState, useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
import classNames from 'classnames';
import { findLastIndex } from '@/utils/utils';
import { AgentLevel } from '@goldfishcode/first-team-real-estate-sdk/libs/api/slipstream/models';
import { useOnClickOutside } from '@/hooks/useClickOutSide';
interface ProgressBarProps {
  className?: string;
  data: Array<AgentLevel>;
}
const target = 100;

const ProgressBar: React.FC<ProgressBarProps> = React.memo(({ className, data }) => {
  const [level, setLevel] = useState(null);
  const [dataLevel, setDataLevel] = useState<Array<AgentLevel>>([]);
  const theme = useTheme();
  const onChangeLevel = (params) => {
    setLevel(params);
  };

  useEffect(() => {
    setDataLevel(
      data?.filter((v) => {
        return v.level !== 0;
      }),
    );
  }, [data]);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(wrapperRef, () => onChangeLevel(null));

  const currentLevel = () => {
    return findLastIndex(dataLevel, 'completed', target);
  };

  const renderText = (index, level) => {
    if (currentLevel() === index) {
      return 'Current Level: ' + level;
    }
    if (currentLevel() + 1 === index) {
      return 'Next Level: ' + level;
    }
    return 'Level: ' + level;
  };

  const getPercent = () => {
    try {
      if (!dataLevel?.length) return 0;
      const lengthTotal = dataLevel.length;
      const lengthcompleted = dataLevel.filter((v) => {
        return v.completed === target;
      })?.length;
      return (lengthcompleted * 100) / lengthTotal;
    } catch (error) {
      return 0;
    }
  };

  if (!dataLevel?.length) return null;
  return (
    <section
      className={className}
      ref={wrapperRef}
      css={css`
        position: relative;
        width: 100%;
        margin-right: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex: 0 auto;
        .w-dropdown-btn,
        .w-dropdown-toggle,
        .w-dropdown-link {
          position: relative;
          vertical-align: top;
          text-decoration: none;
          color: #222222;
          padding: 20px;
          text-align: left;
          margin-left: auto;
          margin-right: auto;
          white-space: nowrap;
        }
        max-width: 460px;
        @media (max-width: ${theme.media.desktops}px) {
          max-width: 70%;
        }
        @media (max-width: ${theme.media.phones}px) {
          max-width: 100%;
        }
        .dropdown-top-level {
          &:first-of-type {
            margin-left: 0px !important;
          }
          &:nth-of-type(${dataLevel?.length}) {
            margin-right: 0px !important;
          }
          nav.dropdown-list {
            padding: 3px;
            &.w-dropdown-list {
              position: absolute;
              background: #dddddd;
              display: none;
              min-width: 100%;
            }
            &.w--open {
              left: -68px;
              top: 55px;
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -webkit-flex-direction: column;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -webkit-align-items: center;
              -ms-flex-align: center;
              align-items: center;
              background-color: #2e0249;
              color: #fff;
              text-align: center;
              padding: 10px 14px 20px;
              border-radius: 5px;
              @media (max-width: ${theme.media.phones}px) {
                left: ${level === dataLevel[0]?.level
                  ? '0px'
                  : level === dataLevel[dataLevel?.length - 1].level
                  ? '-140px'
                  : '-68px'};
              }
            }

            .top-level {
              top: -6px;
              background-color: #2e0249;
            }
            .card-pointer {
              position: absolute;
              top: -12px;
              z-index: -1;
              width: 30px;
              height: 30px;
              background-color: #f1f1f1;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              @media (max-width: ${theme.media.phones}px) {
                width: 12px;
                height: 12px;
              }
              &.top-level {
                top: -6px;
                background-color: #2e0249;
                @media (max-width: ${theme.media.phones}px) {
                  left: ${level === dataLevel[0]?.level
                    ? '10px'
                    : level === dataLevel[dataLevel?.length - 1].level
                    ? '151px'
                    : '80px'};
                }
              }
            }
            .next-level-heading {
              display: inline-block;
              width: 150px;
              color: #fff;
              margin-top: 0px;
              font-size: 18px;
              line-height: 24px;
              font-weight: 700;
              text-transform: capitalize;
              font-family: FiraSans, sans-serif;
            }
            > div {
              font-weight: 300;
            }
          }
          &.w-dropdown {
            display: inline-block;
            position: relative;
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            z-index: 900;
          }
          .level-button {
            display: flex;
            width: 40px;
            height: 40px;
            padding: 6px;
            justify-content: center;
            align-items: center;
            border: 2px solid #2e0249;
            border-radius: 50px;
            background-color: #fff;
            box-shadow: none;
            transition: all 400ms ease;
            font-family: Montserrat, sans-serif;
            font-weight: 600;
            cursor: pointer;
            @media (max-width: ${theme.media.tablets}px) {
              width: 34px;
              height: 34px;
            }
            &:hover {
              background-color: #2e0249;
              box-shadow: 0 1px 8px 1px rgb(0 0 0 / 35%);
              color: #fff;
            }
            &.current {
              border-color: #2e0249;
              background-color: #2e0249;
              box-shadow: 0 1px 8px 1px rgb(0 0 0 / 40%);
              color: #fff;
            }
          }
        }
        .wrapper {
          position: relative;
          font-weight: 300;
          height: 4px;
          background-color: #ddd;
          position: absolute;
          top: 20px;
          width: 100%;
          overflow: hidden;
          @media (max-width: ${theme.media.tablets}px) {
            top: auto;
            bottom: 14px;
            width: 100%;
            background-color: #ddd;
          }
          .progress-bar {
            position: absolute;
            left: 0px;
            top: 0px;
            height: 4px;
            right: 0px;
            bottom: 0px;
            width: ${getPercent()}%;
            background-color: #2e0249;
          }
        }
      `}
    >
      {dataLevel.map((item: AgentLevel, index) => {
        return (
          <div className="dropdown-top-level w-dropdown" key={index}>
            <div
              className={classNames(
                'level-button w-dropdown-toggle',
                { 'w--open': level === item.level },
                { current: target === item.completed },
              )}
              onClick={() => onChangeLevel(item.level)}
            >
              <div className="icon text-centered">{item.level}</div>
            </div>
            <nav className={classNames('dropdown-list top w-dropdown-list', { 'w--open': level === item.level })}>
              <div className="card-pointer top-level fc"></div>
              <h4 className="next-level-heading">{renderText(index, item.level)}</h4>
              <div>{item.units} Units</div>
              <div>{item.completed}% Completed</div>
            </nav>
          </div>
        );
      })}

      <div className="wrapper progress-bar top">
        <div className="progress-bar active top"></div>
      </div>
    </section>
  );
}, isEqual);

export default ProgressBar;
