import { ValueLabel } from '@/models';
import { VIEW_MODE } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import {
  MailingExtra,
  OwnerAddressType,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import React, { CSSProperties, useEffect, useState } from 'react';
import { AddressSelect } from '../types';
import AddressItemView from './AddressItemView';

interface AddressItemProps {
  style?: CSSProperties;
  filterState?: VIEW_MODE;
  isAllowUpdateOwnerDropdown?: boolean;
  checked?: boolean;
  mailing: MailingExtra;
  onToggle: (address: AddressSelect) => void;
  uniqID?: string;
  isDuplicated?: boolean;
}

const AddressItem: React.FC<AddressItemProps> = ({
  filterState,
  mailing,
  uniqID,
  style,
  isAllowUpdateOwnerDropdown,
  onToggle,
  isDuplicated,
}) => {
  const ownerAddress: ValueLabel<OwnerAddressType>[] = [
    { label: 'Owner', value: OwnerAddressType.OWNER },
    { label: 'Renter', value: OwnerAddressType.RENTER },
  ];
  const addressTypeInitial = mailing.is_send_owner_address ? ownerAddress[0].label : ownerAddress[1].label;
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(mailing?.selectedToSend || false);
  const [addressType, setAddressType] = useState(addressTypeInitial);

  useEffect(() => {
    setChecked(mailing?.selectedToSend || false);
  }, [mailing.selectedToSend]);
  useEffect(() => {
    const addressTypeInitial = mailing.is_send_owner_address ? ownerAddress[0].label : ownerAddress[1].label;
    setAddressType(addressTypeInitial);
  }, [mailing.is_send_owner_address]);

  const isRealtorFlag = !!mailing.is_realtor;
  const isOwnerOfState = !!mailing.owner_out_of_state;

  const dropdownId = mailing.id;

  return (
    <AddressItemView
      filterState={filterState}
      mailing={mailing}
      uniqID={uniqID}
      style={style}
      isAllowUpdateOwnerDropdown={isAllowUpdateOwnerDropdown}
      onToggle={onToggle}
      isDuplicated={isDuplicated}
      checked={checked}
      isLoading={isLoading}
      dropdownId={dropdownId}
      ownerAddress={ownerAddress}
      setAddressType={setAddressType}
      addressType={addressType}
      setIsLoading={setIsLoading}
      isRealtorFlag={isRealtorFlag}
      isOwnerOfState={isOwnerOfState}
    />
  );
};

export default AddressItem;
