import { AppSelectV2Props } from '@/components/AppSelectV2/types';
import { SelectObjType } from '@/components/custom-modals/modal-smart-trigger/useSmTrigger';
import { Milestone } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Empty } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import SelectMultipleTagsView from './SelectMultipleTagsView';

interface AppSelectProps extends Pick<AppSelectV2Props, 'hasError' | 'errorMessage' | 'heightExpanded'> {
  requiredMark?: boolean;
  label?: string;
  className?: string;
  isForceFocus?: boolean;
  value?: string | null | undefined | string[];
  itemSelect?: SelectObjType[] | Milestone[];
  placeholder?: string;
  isGetContainer?: boolean;
  suffixIcon?: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  name: string;
  type?: string;
  searchValue?: string;
  loadingMoreMailing?: boolean;
  disableValue?: any;
  parentId?: string;
  isSearchAddress?: boolean;
  selectedItemListProps?: SelectObjType[];
  noBorder?: boolean;
  showArrow?: boolean;
  onSearch?: (value: string, isFounded: boolean) => void;
  filterOption?: (input: string, option: BaseOptionType | DefaultOptionType | undefined) => boolean;
  handleChange?: (value: string | string[]) => void;
  handleBlur?: (e: React.FocusEvent) => void;
  handleFocus?: (e: React.FocusEvent) => void;
}

const SelectMultipleTagsContainer: React.FC<AppSelectProps> = (props) => {
  const {
    handleChange,
    handleBlur,
    handleFocus,
    value,
    itemSelect,
    loadingMoreMailing,
    onSearch,
    isSearchAddress,
    selectedItemListProps,
    isForceFocus,
  } = props;
  const googleRef = useRef<any>();
  const inputRef = React.useRef<any>(null);
  const autoCompleteRef = useRef<any>();
  const [isFocusing, setIsFocusing] = useState(!!value);
  const [isEmpty, setIsEmpty] = useState(!value);
  const [placePredictions, setPlacePredictions] = React.useState<any[]>([]);

  const [selectedItems, setSelectedItems] = useState<string[]>([]); //array city selected
  const [selectedItemList, setSelectedItemList] = useState<SelectObjType[]>(selectedItemListProps || []); //array object city selected

  const customSelectValue = useMemo(
    () => (value ? (value !== '' ? value : null) : selectedItems),
    [value, selectedItems],
  );
  const handleOnFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      handleFocus && handleFocus(e);
      setIsFocusing(true);
    },
    [handleFocus, setIsFocusing],
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      handleBlur && handleBlur(e);
      setIsFocusing(false);
      onSearch && onSearch('', true);
    },
    [handleBlur, setIsFocusing, onSearch],
  );

  const handleOnChange = useCallback(
    (value, option) => {
      handleChange && handleChange(option?.map((v) => v.key));
      setSelectedItems(value);
      setSelectedItemList(
        option?.map((v) => ({
          label: v.children,
          value: v.value,
        })),
      );
      setPlacePredictions([]);
      if (value && +value > 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    },
    [handleChange, setSelectedItems, setSelectedItemList, setPlacePredictions, setIsEmpty],
  );

  const onInputRefFocus = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const handleSuggestions = (predictions, status) => {
    if (!googleRef.current) return;
    if (status != googleRef.current.maps.places.PlacesServiceStatus.OK) {
      setPlacePredictions([]);
      return;
    }

    setPlacePredictions(
      predictions?.map((v) => ({
        label: v?.description,
        value: v?.structured_formatting?.main_text,
      })),
    );
  };

  const debounceGetPlacePredictions = useCallback(
    debounce((address) => {
      if (autoCompleteRef.current) {
        autoCompleteRef.current.getPlacePredictions(
          {
            input: address?.trim(),
            componentRestrictions: { country: ['us', 'ca'] },
            types: ['(cities)'],
          },
          handleSuggestions,
        );
      }
    }, 500),
    [],
  );

  const handleGoogleMapApi = useCallback(
    (el) => {
      googleRef.current = el;
      autoCompleteRef.current = new googleRef.current.maps.places.AutocompleteService();
    },
    [autoCompleteRef.current],
  );
  const notFoundContent = useMemo(
    () =>
      loadingMoreMailing ? (
        'Loading...'
      ) : isSearchAddress ? null : (
        <Empty className="empty-data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ),
    [loadingMoreMailing, isSearchAddress],
  );
  const handleOnSearch = useCallback(
    (value: string) => {
      if (!isSearchAddress) {
        const isFoundValue =
          (itemSelect &&
            itemSelect?.some((v) => {
              return v.label?.includes(value?.toLocaleLowerCase());
            })) ||
          false;
        onSearch && onSearch(value, isFoundValue);
      } else {
        debounceGetPlacePredictions(value);
      }
    },
    [onSearch, debounceGetPlacePredictions, isSearchAddress, itemSelect],
  );

  return (
    <SelectMultipleTagsView
      {...props}
      isLabelAffected={!isEmpty || isForceFocus || isFocusing}
      notFoundContent={notFoundContent}
      placePredictions={placePredictions}
      selectedItemList={selectedItemList}
      value={customSelectValue}
      inputRef={inputRef}
      loadMore={loadingMoreMailing}
      onInputRefFocus={onInputRefFocus}
      handleGoogleMapApi={handleGoogleMapApi}
      handleOnSearch={handleOnSearch}
      handleOnChange={handleOnChange}
      handleOnBlur={handleOnBlur}
      handleOnFocus={handleOnFocus}
    />
  );
};

export default SelectMultipleTagsContainer;
