import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  BrokerLandingPage,
  DigitalAdTypes,
  LandingPageType,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { AlertTextError, AlertTextSuccess } from '@/components/alert/SweetAlert';
import BrokerLandingPageTemplate, { BrokerLPTemplateFormData } from '@/components/BrokerLandingPageBuilder';
import ImageCrop from '@/components/image-crop';
import LandingSelect from '@/components/select/landing-select';
import {
  BrokerLandingPageVariables,
  VariableType,
} from '@/components/custom-html-editor/hooks/useFetchVariablesByType';
import MessageVariable from '@/components/custom-html-editor/components/message-variable';
import UploadDigitalMedia, { SelectedFileNameDigitalMediaType, UploadMediaType } from '@/components/UploadDigitalMedia';
import { RootState } from '@/reducers/model';
import LandingService from '@/services/landing';
import { LandingPage } from '@/utils/enum';
import { getFileUrlWithAuthentication } from '@/utils/upload';
import validation from '@/utils/validation';
import AppInputV2 from '@/components/AppInputV2';
import { hasError } from '@/utils/form';
import AppButtonV2 from '@/components/AppButtonV2';

const defaultBrokerLPTemplate = {
  name: '',
  titleText: `Uncover ${BrokerLandingPageVariables.HomeCredit}  opportunities`,
  descriptionText: `with ${BrokerLandingPageVariables.MinHomes} Surrounding Neighbors`,
  freeButtonLabelText: 'See my homes for free',
  autoSendCampaignLabelText: `Automatically send a campaign (${BrokerLandingPageVariables.CampaignPrice})`,
  backgroundUrl: '',
  backgroundFile: null,
};

export interface BrokerLandingPageFormData {
  landing_page?: string;
  name?: string;
  titleText?: string;
  descriptionText?: string;
  freeButtonLabelText?: string;
  autoSendCampaignLabelText?: string;
  backgroundUrl?: string;
  backgroundFile?: SelectedFileNameDigitalMediaType | null;
}

interface BrokerLandingPageConfigFormProps {
  onCancel?: () => void;
}

const BrokerLandingPageConfigForm: React.FC<BrokerLandingPageConfigFormProps> = ({ onCancel }) => {
  const dataAgent = useSelector((state: RootState) => state.home.userAgent);

  const brokerLPOptionsRef = React.useRef<BrokerLandingPage[]>([]);
  const validationSchema = React.useRef(() =>
    Yup.object().shape({
      name: Yup.string().required(validation.landingPage.name.required),
      titleText: Yup.string().trim().required(validation.common.required),
      descriptionText: Yup.string().trim().required(validation.common.required),
      freeButtonLabelText: Yup.string().trim().required(validation.common.required),
      autoSendCampaignLabelText: Yup.string().trim().required(validation.common.required),
    }),
  );
  const formik = useFormik<BrokerLandingPageFormData>({
    initialValues: {
      name: defaultBrokerLPTemplate.name,
      landing_page: LandingPage.CREATE_LANDING_PAGE,
      titleText: defaultBrokerLPTemplate.titleText,
      descriptionText: defaultBrokerLPTemplate.descriptionText,
      freeButtonLabelText: defaultBrokerLPTemplate.freeButtonLabelText,
      autoSendCampaignLabelText: defaultBrokerLPTemplate.autoSendCampaignLabelText,
      backgroundUrl: defaultBrokerLPTemplate.backgroundUrl,
      backgroundFile: defaultBrokerLPTemplate.backgroundFile,
    },
    validationSchema: validationSchema.current,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    onSubmit: async (values) => {
      const bgUrl = await handleUploadBackground();
      if (bgUrl) values.backgroundUrl = bgUrl;
      if (values.landing_page === LandingPage.CREATE_LANDING_PAGE) {
        handleCreateLandingPage(values);
      } else {
        handleUpdateLandingPage(values);
      }
    },
  });

  const bg = useMemo(() => {
    const { backgroundFile, backgroundUrl } = formik.values;
    const selectedFile = backgroundFile?.selectedFile;
    if (selectedFile && 'name' in selectedFile && selectedFile?.name) {
      return URL.createObjectURL(selectedFile);
    }

    return backgroundUrl || undefined;
  }, [formik.values.backgroundFile?.selectedFile, formik.values.backgroundUrl]);

  const handleUploadBackground = async () => {
    const agentId = dataAgent.user_agent.id || undefined;

    const backgroundFile = formik.values.backgroundFile;
    if (backgroundFile && backgroundFile.selectedFile) {
      const mediaUrl = await getFileUrlWithAuthentication(
        backgroundFile.selectedFile as File,
        agentId ? `BROKER${dataAgent.id}` : 'BROKER',
        (e) => AlertTextError('Upload Failed', e?.message),
        true,
      );
      if (!mediaUrl) return;
      return mediaUrl.url;
    }
  };

  const handleCreateLandingPage = async ({
    name,
    titleText = '',
    descriptionText = '',
    freeButtonLabelText,
    autoSendCampaignLabelText,
    backgroundUrl,
  }: BrokerLandingPageFormData) => {
    try {
      await LandingService.createBrokerLandingPageVariable({
        name,
        title_text: titleText,
        description_text: descriptionText,
        free_button_label: freeButtonLabelText,
        auto_send_campaign_label: autoSendCampaignLabelText,
        background: backgroundUrl,
      });
      AlertTextSuccess('Create Successfully', 'Your Landing Page created successfully');
      onCancel && onCancel();
    } catch (error) {
      AlertTextError('Action Failed', (error as Error).message);
    }
  };

  const handleUpdateLandingPage = async ({
    landing_page,
    titleText = '',
    descriptionText = '',
    freeButtonLabelText,
    autoSendCampaignLabelText,
    name,
    backgroundUrl,
  }: BrokerLandingPageFormData) => {
    try {
      if (!landing_page || landing_page === LandingPage.CREATE_LANDING_PAGE) return;
      await LandingService.updateBrokerLandingPageVariable(landing_page, {
        name,
        title_text: titleText,
        description_text: descriptionText,
        free_button_label: freeButtonLabelText,
        auto_send_campaign_label: autoSendCampaignLabelText,
        background: backgroundUrl,
      });
      AlertTextSuccess('Update Successfully', 'Your Landing Page updated successfully');
      onCancel && onCancel();
    } catch (error) {
      AlertTextError('Action Failed', (error as Error).message);
    }
  };

  const resetForm = () => {
    formik.resetForm();
  };

  const handleCancel = () => {
    resetForm();
    onCancel && onCancel();
  };

  const handleTemplateChangeValue = (values: BrokerLPTemplateFormData) => {
    const { titleText, descriptionText, freeButtonLabelText, autoSendCampaignLabelText } = values;
    formik.setValues({
      ...formik.values,
      titleText,
      descriptionText,
      freeButtonLabelText: freeButtonLabelText || '',
      autoSendCampaignLabelText: autoSendCampaignLabelText || '',
    });
  };

  const onSelectBackground = (file: SelectedFileNameDigitalMediaType | null) => {
    formik.setValues({ ...formik.values, backgroundFile: file });
  };

  return (
    <FormStyled onSubmit={formik.handleSubmit} autoComplete="off">
      <div className="form-item">
        <p className="title">Create / Update Landing Page</p>
        <div className="btn-field">
          <AppButtonV2 size="large" color="White" width={150} onClick={handleCancel} disabled={formik.isSubmitting}>
            Cancel
          </AppButtonV2>
          <AppButtonV2
            size="large"
            color="Pin"
            width={150}
            disabled={formik.isSubmitting}
            onClick={() => formik.submitForm()}
          >
            Confirm
          </AppButtonV2>
        </div>
        <div className="container-input">
          <Row gutter={[16, 12]}>
            <Col span={9}>
              <div id="landing-page">
                <LandingSelect
                  className="field base-field landing-field"
                  label="Select Landing Page"
                  name="landing_page"
                  placeholder="Select Landing Page"
                  handleChange={(value) => {
                    if (value === LandingPage.CREATE_LANDING_PAGE) {
                      formik.setValues(
                        {
                          ...defaultBrokerLPTemplate,
                          landing_page: value,
                        },
                        true,
                      );
                    } else {
                      const item = brokerLPOptionsRef.current.find((v) => v.id === value);
                      if (item) {
                        formik.setValues(
                          {
                            autoSendCampaignLabelText: item.auto_send_campaign_label,
                            freeButtonLabelText: item.free_button_label,
                            titleText: item.title_text,
                            descriptionText: item.description_text,
                            name: item.name,
                            landing_page: value,
                            backgroundUrl: item.background,
                          },
                          true,
                        );
                      }
                    }
                  }}
                  value={formik.values.landing_page}
                  hasError={hasError('landing_page', formik)}
                  errorMessage={formik.errors.landing_page}
                  requiredMark
                  landingPageType={LandingPageType.BROKER_MLS}
                  onOptionsLoaded={(options) => (brokerLPOptionsRef.current = options)}
                  allowClear={false}
                />
              </div>
            </Col>

            <Col span={15}>
              <AppInputV2
                className="field sm-field"
                label="Name of Landing Page"
                name="name"
                type="text"
                placeholder="Enter Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                size="large"
                hasError={hasError('name', formik)}
                requiredMark
                disabled={formik.isSubmitting}
                errorMessage={formik.errors.name}
              />
            </Col>

            <Col span={9} className="variables-section">
              <p className="s-label">Copy Variables</p>
              <MessageVariable variableType={VariableType.BROKER_LP} />
            </Col>

            <Col span={9} className="bg-upload-section">
              <ImageCrop
                aspect={16 / 9}
                showGrid
                modalTitle="Edit background"
                // aspectSelector={[
                //   { label: '1/1', value: 1 / 1 },
                //   { label: '4/3', value: 4 / 3 },
                //   { label: '16/9', value: 16 / 9 },
                // ]}
                // customAspect
              >
                <UploadDigitalMedia
                  title="Background Upload"
                  value={formik.values.backgroundFile || {}}
                  buttonColor="pink"
                  onSelectedFile={onSelectBackground}
                  mode={DigitalAdTypes.DIGITAL_AD_IMAGE}
                  uploadType={UploadMediaType.NORMAL_MEDIA}
                />
              </ImageCrop>
            </Col>

            <Col span={24} style={{ height: 576 }}>
              <div
                css={css`
                  transform: scale(0.66875);
                  transform-origin: top left;
                `}
              >
                <BrokerLandingPageTemplate
                  titleText={formik.values.titleText}
                  descriptionText={formik.values.descriptionText}
                  freeButtonLabelText={formik.values.freeButtonLabelText}
                  autoSendCampaignLabelText={formik.values.autoSendCampaignLabelText}
                  background={bg}
                  isEditMode
                  errors={formik.errors}
                  touched={formik.touched}
                  handleTemplateChangeValue={handleTemplateChangeValue}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </FormStyled>
  );
};

export default BrokerLandingPageConfigForm;

const FormStyled = styled.form`
  .form-item {
    position: relative;
    .title {
      font-size: 24px;
      font-weight: 600;
      font-family: FiraSans;
      margin-top: 25px;
      margin-bottom: 30px;
      text-align: center;
    }
    .container-input {
      overflow-y: auto;
      overflow-x: hidden;

      .variables-section {
        .variable-list {
          text-align: left;
        }
      }

      .ant-checkbox-wrapper {
        color: #fff;
        font-weight: 700;
        font-size: 16px;
      }
      .landing-field {
        .ant-select {
          max-height: unset;
          .ant-select-selector {
            max-height: unset;
          }
          .ant-select-arrow {
            width: 16px;
            height: 12px;
            .anticon-caret-down {
              font-size: 16px;
            }
          }
        }
      }
    }

    .ant-checkbox-wrapper-disabled {
      opacity: 0.8;
      span {
        color: #fff;
      }
    }

    .s-label {
      font-size: 16px;
      opacity: 1;
      font-weight: 700;
      transition-property: top, font-size, opacity;
      transition-duration: 0.1s;
      transition-timing-function: linear;
      margin-bottom: 5px;
      display: inline-block;
      width: 100%;
      text-align: left;
    }
    .error {
      font-weight: 700;
    }
  }
  .btn-field {
    display: flex;
    position: absolute;
    top: 38px;
    right: 25px;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 0 !important;
    padding: 0 !important;
    button {
      width: 150px !important;
    }
  }
`;
