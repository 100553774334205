import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Select } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import React, { FC, useMemo, useRef, useState } from 'react';

import theme from '@/styles/theme';
import { OrderSortby, typeSelect } from '@/utils/enum';
import { InnerLoading } from '../loading/loadingBase';

const { Option } = Select;
const baseStyle = (isFocusing: boolean, isEmpty: boolean, hasError?: boolean) => {
  let isLabelAffected = isFocusing;
  if (!isEmpty) {
    isLabelAffected = true;
  }
  return css`
    position: relative;
    z-index: 1;
    width: 100%;
    .ant-select-arrow .anticon:not(.ant-select-suffix) {
      pointer-events: none;
    }
    .s-label {
      top: ${isLabelAffected ? '7px' : '-30px'};
      font-size: ${isLabelAffected ? '16px' : '16px'};
      opacity: 1;
      font-weight: 700;
      transition-property: top, font-size, opacity;
      transition-duration: 0.1s;
      transition-timing-function: linear;
      margin-bottom: 5px;
      display: inline-block;
    }
    .eyes {
      position: absolute;
      bottom: 13px;
      right: 15px;
      .anticon {
        font-size: 24px;
      }
    }
    .ant-select,
    .ant-select div {
      border-radius: 0;
      font-size: 14px;
      min-height: 48px;
      align-items: center;
      background-color: #f3f3f3;
      border-color: ${hasError ? theme.text.errorColor : theme.text.blackColor} !important;
      /* font-weight: 500; */
      border-radius: 3px;
      width: 100%;
      &:hover {
        border-color: ${theme.text.blackColor};
      }
      &:focus {
        border: 1px solid ${theme.text.blueColor};
        box-shadow: none;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:active,
      &:-webkit-autofill:focus {
        -webkit-background-clip: text !important;
      }
    }
    .forgot-pwd-input-base,
    .bg-input-base {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        border: 1px solid rgba(255, 255, 255, 0.2) !important;
        -webkit-text-fill-color: #fff !important;
        -webkit-box-shadow: 0 0 0px 1000px #2e0249 inset;
      }
    }

    .status {
      position: absolute;
      z-index: 2;
      top: 16px;
      right: 15px;
      width: 19px;
      height: 19px;
    }
    .tip {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: #fff;
      opacity: 0.7;
    }

    &.show-field {
      display: inline-block;
      width: auto;
      min-height: 46px;
      margin-bottom: 0px;
      border-radius: 3px;
      transition: all 300ms ease;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
      color: #fff;
      div {
        background-color: #2e0249 !important;
        color: #fff;
      }
      label {
        color: #fff;
      }
      .ant-select-arrow {
        color: #fff;
      }
    }
    &.setting-field,
    .base-field {
      div {
        border-radius: 0;
        font-size: 14px;
        min-height: 58px;
        align-items: center;
        background-color: #fff !important;
        border-color: ${theme.text.grayColor} !important;
        height: 38px;
        font-weight: 500;
        border-radius: 3px;
        width: 100%;
        color: #333333;
        &:hover {
          border-color: ${theme.text.grayColor} !important;
        }
        &:focus {
          border: 1px solid ${theme.text.grayColor};
          box-shadow: none;
        }
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #cccccc;
      }
    }
    &.base-field {
      .ant-select-arrow {
        color: #2e0249 !important;
        width: 19px;
        height: 19px;
        .anticon-caret-down {
          font-size: 19px;
        }
      }

      .ant-select-selector {
        color: #2e0249;
        font-weight: 400;
        font-family: Montserrat;
        border-radius: 5px;
        .ant-select-selection-placeholder {
          color: #2e0249;
        }
        background-color: #fff !important;
        border-color: ${theme.text.blackColor} !important;
        &:hover {
          border-color: ${theme.text.blackColor} !important;
        }
      }
    }
    .ant-select-arrow {
      color: #000;
    }
    .up-down-arrow {
      background-color: transparent !important;
      height: auto;
      width: auto;
    }
  `;
};

const CustomSelect = styled(Select)`
  height: 20px;
`;

interface AppSelectProps extends BaseOptionType {
  requiredMark?: boolean;
  label?: string;
  hasError?: boolean;
  className?: string;
  isForceFocus?: boolean;
  handleChange: (value: any) => void;
  handleBlur?: (e: React.FocusEvent) => void;
  handleFocus?: (e: React.FocusEvent) => void;
  value: any;
  itemSelect: any[];
  isSelect?: typeSelect;
  placeholder?: string;
  isGetContainer?: boolean;
  idParent?: string;
  overlappedLoading?: boolean;
  loading?: boolean;
  isValueIndex?: boolean;
  labelKey?: string;
  valueKey?: string;
  allowClear?: boolean;
  isShowSortArrow?: boolean;
  sortByType?: string;
}
const AppSelect: FC<AppSelectProps> = (props) => {
  const {
    requiredMark,
    label,
    hasError,
    className,
    isForceFocus,
    handleChange,
    handleBlur,
    handleFocus,
    value,
    itemSelect,
    isSelect,
    placeholder,
    isGetContainer,
    idParent = 'market',
    overlappedLoading = false,
    loading,
    isValueIndex = false,
    labelKey,
    valueKey,
    allowClear,
    isShowSortArrow = false,
    sortByType = OrderSortby.DESC,
    ...rest
  } = props;

  const inputRef = useRef<any>(null);
  const [isFocusing, setIsFocusing] = useState(!!value);
  const [isEmpty, setIsEmpty] = useState(!value);
  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    handleFocus && handleFocus(e);
    setIsFocusing(true);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur && handleBlur(e);
    setIsFocusing(false);
  };

  const onChange = (value: any) => {
    const valueWithPrefix = isShowSortArrow && value ? sortByType + value : value;
    handleChange && handleChange(valueWithPrefix);
    if (value && +value > 0) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  };

  const onInputRefFocus = () => {
    inputRef.current?.focus();
  };
  const renderSuffixIcon = () => {
    return (
      <div
        className="up-down-arrow"
        css={css`
          position: absolute;
          top: 0;
          right: 5px;
          z-index: 9;
          span {
            display: block;
            height: 12px;
            svg {
              font-size: 16px !important;
            }
          }
          .opacity-5 {
            opacity: 0.5;
          }
        `}
      >
        <CaretUpOutlined
          className={`${sortByType === OrderSortby.ASC ? 'opacity-5' : ''}`}
          onClick={() => {
            if (sortByType === OrderSortby.DESC) return;
            const valueWithPrefix = OrderSortby.DESC + value;
            handleChange && handleChange(valueWithPrefix);
          }}
        />
        <CaretDownOutlined
          className={`${sortByType === OrderSortby.DESC ? 'opacity-5' : ''}`}
          onClick={() => {
            if (sortByType === OrderSortby.ASC) return;
            const valueWithPrefix = OrderSortby.ASC + value;
            handleChange && handleChange(valueWithPrefix);
          }}
        />
      </div>
    );
  };

  // Handle Select allow show label with value not show value
  const valueHandle = useMemo((): string | null => {
    if (value === '') return null;
    if (!valueKey || !Array.isArray(itemSelect)) return value;
    const isSomeValue = (itemSelect || []).some((s) => s[valueKey] === value);
    return isSomeValue ? value : null;
  }, [valueKey, itemSelect, value]);

  return (
    <div className={className} css={[baseStyle(isForceFocus || isFocusing, isEmpty, hasError)]}>
      {label && (
        <label className="s-label" onClick={onInputRefFocus}>
          {label}
          {requiredMark ? <span className="mark">*</span> : ''}
        </label>
      )}
      <InnerLoading
        className="select-inner-loading"
        spinning={overlappedLoading}
        iconStyle={{ color: '#2e0249', fontSize: '18px' }}
      >
        <CustomSelect
          ref={inputRef}
          onChange={onChange}
          value={valueHandle}
          suffixIcon={isShowSortArrow ? null : <CaretDownOutlined className="icon ant-select-suffix" />}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          loading={loading}
          getPopupContainer={
            isGetContainer ? () => document.getElementById(idParent) as HTMLElement : () => document.body
          }
          allowClear={allowClear}
          dropdownRender={(menu) => (
            <div
              className="field select-field"
              css={css`
                background-color: ${isSelect === typeSelect.SELECT_ANALYTICS ? '#2e0249' : '#f3f3f3 !important'};

                .option {
                  color: ${isSelect === typeSelect.SELECT_ANALYTICS ? '#fff' : '#2e0249 !important'};
                  &:hover {
                    background-color: #1c87c6 !important;
                    color: ${isSelect === typeSelect.SELECT_ANALYTICS ? '#fff' : '#2e0249 !important'};
                  }
                }
              `}
            >
              {menu}
            </div>
          )}
          {...rest}
        >
          {itemSelect?.map((item, index) => {
            return (
              <Option
                className="option"
                key={index}
                value={isValueIndex ? index : valueKey ? item[valueKey] : item}
                css={css`
                  &.option {
                    .ant-select-item-option-content {
                      max-width: 100%;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                `}
              >
                {labelKey ? item[labelKey] : item}
              </Option>
            );
          })}
        </CustomSelect>
      </InnerLoading>
      {isShowSortArrow && renderSuffixIcon()}
    </div>
  );
};

export default AppSelect;
