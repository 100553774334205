import { css } from '@emotion/react';
import React from 'react';
import { KEY_MLS_TRIGGER } from '@/utils/enum';
import { enumAddType } from '../custom-modals/modal-smart-trigger/useSmTrigger';
import AppButtonV2 from '../AppButtonV2';

const ButtonAdd: React.FC<{ handleIncludeBtn; handleExcludeBtn; disabled?: boolean }> = ({
  handleIncludeBtn,
  handleExcludeBtn,
  disabled,
}) => {
  return (
    <div
      css={css`
        display: flex;
        gap: 12px;
        margin-top: 12px;
      `}
    >
      <AppButtonV2
        color="Pin"
        size="small"
        width={110}
        className="btn-add"
        uppercase={false}
        disabled={disabled}
        onClick={() => handleIncludeBtn(KEY_MLS_TRIGGER.avg_year_owned, 'temp_value', enumAddType.INCLUDE)}
      >
        Add
      </AppButtonV2>
      <AppButtonV2
        color="White"
        size="small"
        width={110}
        className="btn-exclude"
        uppercase={false}
        disabled={disabled}
        onClick={() => handleExcludeBtn(KEY_MLS_TRIGGER.avg_year_owned, 'temp_value', enumAddType.EXCLUDE)}
      >
        Exclude
      </AppButtonV2>
    </div>
  );
};

export default ButtonAdd;
