import { css, useTheme } from '@emotion/react';
import React from 'react';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import Link from 'gatsby-link';
import IconTwitter from '@/assets/images/twitter-icon-white.svg';
import IconLinkedin from '@/assets/images/linkedin-xxl.png';
import LogoHomemeta from '@/assets/images/homemeta-logo-white.png';
import RoutePaths from '@/utils/routes';
import { useSelector } from 'react-redux';
import { RootState } from '@/reducers/model';
import { UserType } from '@/utils/enum';
import { useUserRole } from '@/hooks/useUserRole';

const Footer: React.FC = React.memo(() => {
  const theme = useTheme();
  const profile = useSelector((state: RootState) => state.auth.myProfile);
  const { isManager } = useUserRole();

  return (
    <footer
      className="app-footer"
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 48px 24px 0px 24px;
        background-color: #2e0249;
        color: #fff;
        @media only screen and (max-width: ${theme.media.desktops}px) {
          padding-top: 48px;
          padding-bottom: 0px;
        }
        .footer-container {
          max-width: ${theme.layout.maxWidthSM}px;
          width: 100%;
          font-weight: 300;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          > .footer-logo {
            padding-right: 0px;
            flex-direction: column;
            align-items: flex-start;
            @media only screen and (max-width: ${theme.media.desktops}px) {
              display: flex;
              width: 100%;
              padding-right: 8px;
              padding-bottom: 32px;
              padding-left: 8px;
              justify-content: center;
              align-items: center;
            }
            @media only screen and (max-width: ${theme.media.tablets}px) {
              padding-bottom: 24px;
            }
            .logo.footer {
              max-width: 240px;
              @media only screen and (max-width: ${theme.media.desktops}px) {
                max-width: 220px;
              }
              @media only screen and (max-width: ${theme.media.tablets}px) {
                width: 140px;
              }
            }
          }
          > .footer-v3-nav {
            display: flex;
            padding-right: 15px;
            justify-content: center;
            align-items: center;
            flex: 1;
            @media only screen and (max-width: ${theme.media.desktops}px) {
              width: 22%;
              padding-right: 8px;
              padding-left: 8px;
              flex-wrap: wrap;
            }
            @media only screen and (max-width: ${theme.media.tablets}px) {
              width: 100%;
              margin-bottom: 24px;
              flex: 0 auto;
            }
          }
          > .social-icons {
            display: flex;
            align-items: center;
            padding-right: 12px;
            @media only screen and (max-width: ${theme.media.tablets}px) {
              width: 100%;
              padding-left: 5px;
              justify-content: center;
            }
            > .social-link {
              display: flex;
              width: 24px;
              height: 24px;
              margin: 3px;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              background-color: #a7adc0;
              transition: background-color 300ms ease;
              &.w-inline-block {
                max-width: 100%;
              }
              &.dark-bg {
                background-color: hsla(0, 0%, 100%, 0.15);
              }
              > .social-icon-small {
                width: 12px;
              }
              > img {
                max-width: 100%;
                vertical-align: middle;
                display: inline-block;
              }
            }
          }
          > .footer-bottom {
            display: flex;
            width: 100%;
            margin-top: 40px;
            padding-top: 24px;
            padding-bottom: 24px;
            justify-content: space-between;
            border-top: 1px solid rgba(230, 232, 239, 0.24);
            color: #7c8499;
            font-size: 12px;
            line-height: 16px;
            @media only screen and (max-width: ${theme.media.desktops}px) {
              margin-right: 8px;
              margin-left: 8px;
            }
            @media only screen and (max-width: ${theme.media.tablets}px) {
              padding-top: 16px;
              padding-bottom: 16px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            > .footer-bottom-copy {
              display: flex;
              padding-top: 8px;
              padding-bottom: 8px;
              justify-content: center;
              flex-wrap: wrap;
              align-items: center;
              flex: 1;
              color: #fff;
              @media only screen and (max-width: ${theme.media.phones}px) {
                text-align: center;
              }
            }
          }
        }
        .footer-brand {
          display: flex;
          padding-right: 15px;
          padding-left: 0px;
          justify-content: flex-start;
          align-items: center;
          transition: color 200ms ease;
          color: #fff;
          font-size: 19px;
          line-height: 28px;
          font-weight: 700;
          &:hover {
            color: #ff00dd;
          }
        }
        .footer-link {
          margin-right: 32px;
          padding-top: 4px;
          padding-bottom: 4px;
          color: #161a25;
          font-size: 13px;
          line-height: 20px;
          text-decoration: none;
          &.link-white {
            color: #fff;
            @media only screen and (max-width: ${theme.media.desktops}px) {
              margin-right: 24px;
            }
            @media only screen and (max-width: ${theme.media.tablets}px) {
              margin-right: 20px;
            }
          }
        }
      `}
    >
      <div className="footer-container">
        <div className="footer-logo">
          <a
            href={profile?.user_type === UserType.MANAGER ? RoutePaths.MANAGER : RoutePaths.MAILING_LISTS}
            className="footer-brand w-nav-brand"
          >
            <img src={LogoHomemeta} alt="logo" className="logo footer" />
          </a>
        </div>
        <div className="footer-v3-nav">
          <Link to={isManager ? RoutePaths.MANAGER : RoutePaths.MAILING_LISTS} className="footer-link link-white">
            Dashboard
          </Link>
          {/* <Link to={RoutePaths.HOME_GRAPH} className="footer-link link-white w--current">
            Home Graph
          </Link>
          <Link to={RoutePaths.MANAGER} className="footer-link link-white">
            Manager
          </Link> */}
          <a href={RoutePaths.TERMS_AND_CONDITIONS} target="_blank" className="footer-link link-white" rel="noreferrer">
            Terms &amp; Conditions
          </a>
          <a href={RoutePaths.PRIVACY_POLICY} target="_blank" className="footer-link link-white" rel="noreferrer">
            Privacy Policy
          </a>
          <Link to={RoutePaths.CAMPAIGNS} className="footer-link link-white">
            Campaign Template
          </Link>
        </div>
        <div className="social-icons">
          <a
            href="https://twitter.com"
            target="_blank"
            className="social-link dark-bg twitter w-inline-block"
            rel="noreferrer"
          >
            <img src={IconTwitter} alt="" className="social-icon-small" />
          </a>
          <a
            href="https://facebook.com"
            target="_blank"
            className="social-link dark-bg facebook w-inline-block"
            rel="noreferrer"
          >
            <img
              src="https://uploads-ssl.webflow.com/607dbc04ad3a909488b4ca23/607dbc04ad3a900eeeb4ca6c_facebook-icon-white.svg"
              alt=""
              className="social-icon-small"
            />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            className="social-link dark-bg instagram w-inline-block"
            rel="noreferrer"
          >
            <img
              src="https://uploads-ssl.webflow.com/607dbc04ad3a909488b4ca23/607dbc04ad3a902a4cb4ca69_instagram-icon-white.svg"
              alt=""
              className="social-icon-small"
            />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            className="social-link dark-bg dribbble w-inline-block"
            rel="noreferrer"
          >
            <img src={IconLinkedin} alt="" className="social-icon-small" />
          </a>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom-copy">
            <div className="footer-text-left">© {moment().year()} Home Meta | All rights reserved | Fair Housing</div>
          </div>
        </div>
      </div>
    </footer>
  );
}, isEqual);

export default Footer;
