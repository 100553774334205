import { MailingListComparingRootCompetitor } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import {
  Milestone,
  PastClientTypeEnums,
  PostalyticsAudience,
  SmartTrigger,
  SmartTriggerAction,
  SmartTriggerActionParams,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { apiIns } from '@/config/apiClient';
class SmartActionService {
  static listSmartTriggerAction(
    agent_id: string,
    page?: number,
    limit?: number,
    name?: string,
    cachebuster?: number,
    mailing_list_id?: string,
    sm_trigger_campaign_id?: string,
  ): Promise<Pagination<SmartTriggerAction>> {
    return apiIns.postalytics.listSmartTriggerAction(
      agent_id,
      page,
      limit,
      name,
      cachebuster,
      mailing_list_id,
      sm_trigger_campaign_id,
    );
  }
  static deleteSmartTriggerAction(agent_id: string, sm_trigger_action_id: string): Promise<any> {
    return apiIns.postalytics.deleteSmartTriggerAction(agent_id, sm_trigger_action_id);
  }
  static updateSmartTriggerAction(
    agent_id: string,
    sm_trigger_action_id: string,
    UpdateData: SmartTriggerActionParams,
  ): Promise<SmartTriggerAction> {
    return apiIns.postalytics.updateSmartTriggerAction(agent_id, sm_trigger_action_id, UpdateData);
  }
  static getPostalyticsAudience(): Promise<PostalyticsAudience[]> {
    return apiIns.postalytics.getPostalyticsAudience();
  }
  static getMilestones(): Promise<Milestone[]> {
    return apiIns.postalytics.getMilestones();
  }
  static createSmartTriggerAction(
    agent_id: string,
    SmartTriggerParams: SmartTriggerActionParams,
  ): Promise<SmartTriggerAction> {
    return apiIns.postalytics.createSmartTriggerAction(agent_id, SmartTriggerParams);
  }
  static getSmartTriggerAction(agent_id: string, sm_trigger_action_id: string): Promise<SmartTriggerAction> {
    return apiIns.postalytics.getSmartTriggerAction(agent_id, sm_trigger_action_id);
  }
  static getSmartTrigger(
    agent_id: string,
    page?: number,
    limit?: number,
    name?: string,
  ): Promise<Pagination<SmartTrigger>> {
    return apiIns.postalytics.getSmartTrigger(agent_id, page, limit, name);
  }
  static existTriggerName(agent_id: string, name: string, sm_trigger_id: string): Promise<any> {
    return apiIns.postalytics.existTriggerName(agent_id, name, sm_trigger_id);
  }
  static requestCreateMilestone(name: string): Promise<{
    success: boolean;
  }> {
    return apiIns.postalytics.requestCreateMilestone(name);
  }
  static readNotification(mailing_id: string): Promise<any> {
    return apiIns.mailingList.ReadNotification(mailing_id);
  }
  static listPropertyType(
    mailing_list_id: string[],
    neighborhood_id?: string[],
    past_client_type?: PastClientTypeEnums[],
  ): Promise<any> {
    return apiIns.postalytics.listPropertyType(mailing_list_id, neighborhood_id, past_client_type);
  }
  static listCompetitors(mailingList_id: string, agent_id?: string): Promise<MailingListComparingRootCompetitor> {
    return apiIns.mailingList.mailingListComparingRootCompetitor(mailingList_id, agent_id);
  }
}

export default SmartActionService;
